import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CerereTypeAddPayload } from './cerere-type.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CerereTypeService {
	private token!: string;
	private basePath = environment.interop.basePath;
	private httpOptions: any;

	private getCerereTypesUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cerereType.getCerereTypes;
	private getRequestTypesDTUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cerereType.getCerereTypesDT;
	private storeRequestTypeUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cerereType.storeCerereType;
	private updateRequestTypeUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cerereType.putCerereType;
	private deleteRequestTypeUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cerereType.deleteCerereType;
	private getRequestTypesActiveUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cerereType.getCerereTypesActive;
	private getRequestTypesPublicUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cerereType
			.getCerereTypesActivePublic;

	constructor(private http: HttpClient) { }

	getRequestTypesDT() {
		return this.http.get(this.getRequestTypesDTUrl, this.httpOptions);
	}

	addRequestType(data: CerereTypeAddPayload) {
		return this.http.post(this.storeRequestTypeUrl, data, this.httpOptions);
	}

	updateRequestType(data: CerereTypeAddPayload) {
		return this.http.put(this.updateRequestTypeUrl, data, this.httpOptions);
	}

	deleteRequestType(id: number) {
		return this.http.delete(this.deleteRequestTypeUrl + id);
	}

	getActiveRequestTypes() {
		return this.http.get(this.getRequestTypesActiveUrl, this.httpOptions);
	}

	getPublicRequestTypes() {
		return this.http.get(this.getRequestTypesPublicUrl, this.httpOptions);
	}

	getRequestTypes() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getCerereTypesUrl
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({
						id: item.id,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get request types:', error);
					return throwError(error);
				})
			);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}
}
