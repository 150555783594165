import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { AddClientContactPayload, EditClientContactPayload } from './client-contacts.interface';

@Injectable({
    providedIn: 'root',
})
export class ClientContactsService {
    private httpOptions: any;

    private basePath = environment.interop.basePath;
    private getContactsDTUrl = this.basePath + environment.interop.clients.contacts.getContactsDT;
    private storeContactUrl = this.basePath + environment.interop.clients.contacts.storeContact;
    private updateContactUrl = this.basePath + environment.interop.clients.contacts.updateContact;
    private deleteContactUrl = this.basePath + environment.interop.clients.contacts.deleteContact;

    private getContactByIdUrl = this.basePath + environment.interop.clients.contacts.getContactById;
    private getContactsPublicUrl = this.basePath + environment.interop.clients.contacts.getContactsPublic;
    private getContactsActiveUrl = this.basePath + environment.interop.clients.contacts.getContactsActive;

    private changeStatusActiveUrl = this.basePath + environment.interop.clients.contacts.changeStatusContactActive;
    private changeStatusPublicUrl = this.basePath + environment.interop.clients.contacts.changeStatusContactPublic;

    constructor(private http: HttpClient) { }

    getClientContactsDT() {
        return this.http.get(this.getContactsDTUrl);
    }

    getContactById(contactId: number) {
        return this.http.get(`${this.getContactByIdUrl}${contactId}`);
    }

    addClientContact(data: AddClientContactPayload) {
        return this.http.post(this.storeContactUrl, data);
    }

    updateClientContact(data: EditClientContactPayload) {
        return this.http.put(this.updateContactUrl, data);
    }

    deleteClientContact(id: number) {
        return this.http.delete(this.deleteContactUrl + id);
    }

    getClientContactsActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getContactsActiveUrl
            )
            .pipe(
                map((response: any) =>
                    response.data.map((item: any) => ({ id: item.id, name: item.name }))
                ),
                catchError((error: any) => {
                    console.error('Error in getClientContactsActive:', error);
                    return throwError(error);
                })
            );
    }

    getClientContactsPublic() {
        return this.http.get(this.getContactsPublicUrl);
    }

    changeStatuseActive(id: number) {
        return this.http.put(this.changeStatusActiveUrl + id, {});
    }

    changeStatusePublic(id: number) {
        return this.http.put(this.changeStatusPublicUrl + id, {});
    }
}
