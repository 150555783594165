import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StoreService {

	private _user: BehaviorSubject<any> = new BehaviorSubject<any>(Object.create({}));
	user$: Observable<any> = this._user.asObservable();

	constructor() {
		const loggedInUser = sessionStorage.getItem('loggedInUser');
		if (loggedInUser) this.setUser(JSON.parse(loggedInUser));
	}

	getUser() {
		return this._user.getValue();
	}

	setUser(user: any) {
		this._user.next({ ...this.getUser(), ...user });
		sessionStorage.setItem('loggedInUser', JSON.stringify(user));
	}
}
