<div class="g-0">
    <div class="col responsive-box-info">
        <div class="box-wrapper box-actiuni-cerere">
            <div class="box-content-header">
                <div class="row p-1">
                    <div class="col">
                        <mat-card-title>
                            <i class="fa-regular fa-pen-to-square"></i>
                            <span> Actualizare solicitare</span>
                        </mat-card-title>
                    </div>
                </div>
            </div>
            <div class="box-content-body">
                <div class="row p-1">
                    <div class="col d-flex justify-content-start">
                        <div>
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                (change)="onActionChange($event)" value="status">
                                <mat-button-toggle value="status">Status</mat-button-toggle>
                                <mat-button-toggle value="escaladare">Escaladare</mat-button-toggle>
                                <mat-button-toggle value="prioritate">Prioritate</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col" *ngIf="selectedActionToggle == 'status'">
                        <form [formGroup]="sendUpdateStatusForm" (ngSubmit)="submitUpdateStatusMessage()">
                            <div class="row p-1">
                                <div class="col">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>Status</mat-label>
                                        <mat-select formControlName="current_status">
                                            <mat-option *ngFor="let status of requestStatuses" [value]="status.id">
                                                {{ status.name }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>Selectați un status</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col">
                                    <div class="fw-bold">Detalii</div>
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="col d-flex justify-content-end">
                                        <button [disabled]="sendUpdateStatusForm.get('current_status')?.value === '-1'"
                                            *ngIf="permissionsService.hasPermission('solicitari.view.actualizare-status')"
                                            class="btn btn-light upload-btn m-3" type="submit">
                                            <span class="upload__text">TRIMITE</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col" *ngIf="selectedActionToggle == 'prioritate'">
                        <form [formGroup]="sendUpdatePriorityForm" (ngSubmit)="submitUpdatePriorityMessage()">
                            <div class="row p-1">
                                <div class="col">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>Prioritate</mat-label>
                                        <mat-select formControlName="id_cerere_priority">
                                            <mat-option *ngFor="let row of requestPriorities" [value]="row.id">
                                                {{ row.name }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>Selectați o prioritate</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col">
                                    <div class="fw-bold">Detalii</div>
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col">
                                    <div class="col d-flex justify-content-end">
                                        <button [disabled]="sendUpdatePriorityForm.get('id_cerere_priority')?.value === '-1'"
                                            *ngIf="permissionsService.hasPermission('solicitari.view.actualizare-prioritate')"
                                            class="btn btn-light upload-btn m-3" type="submit">
                                            <span class="upload__text">TRIMITE</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col" *ngIf="selectedActionToggle == 'escaladare'">
                        <form [formGroup]="sendUpdateAssigneeForm" (ngSubmit)="submitUpdateAssigneeMessage()">
                            <div class="row p-1">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Direcție/Instituție</mat-label>
                                        <mat-select formControlName="directie_id"
                                            (selectionChange)="onDirectieChange($event.value)">
                                            <mat-option *ngFor="let directie of data.directii" [value]="directie.id">
                                                {{ directie.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Compartiment/Birou/Serviciu</mat-label>
                                        <mat-select formControlName="compartiment_id"
                                            (selectionChange)="onDirectieCompartimentChange($event.value)">
                                            <mat-option *ngFor="let compartiment of compartimenteActive"
                                                [value]="compartiment.id">
                                                {{ compartiment.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Operator</mat-label>
                                        <mat-select formControlName="user_id">
                                            <mat-option *ngFor="let user of usersActive" [value]="user.id">
                                                {{ user.name }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>Selectați un operator</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col">
                                    <div class="fw-bold">Detalii</div>
                                    <angular-editor formControlName="content" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col">
                                    <div class="col d-flex justify-content-end">
                                        <button [disabled]="sendUpdateAssigneeForm.get('user_id')?.value === '-1'"
                                            *ngIf="permissionsService.hasPermission('solicitari.view.escaladare')"
                                            class="btn btn-light upload-btn m-3" type="submit">
                                            <span class="upload__text">TRIMITE</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section-spinner position-fixed" *ngIf="isLoading">
    <div class="spinner position-absolute top-50 start-50 translate-middle">
        <mat-spinner></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>