<div class="add-container">
	<h1 class="add-title">Actualizare prioritate cereri în masă</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="add-field">
            <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="priority_id">
                    <mat-option *ngFor="let priority of requestPriorities$ | async" [value]="priority.id">
                        {{ priority.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!form.controls['priority_id'].valid && form.controls['priority_id'].touched">
					Câmp obligatoriu
				</mat-error>
            </mat-form-field>
		</div>

		<div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Mesaj schimbare prioritate</mat-label>
				<input type="text" matInput formControlName="message" />
				<mat-error *ngIf="!form.controls['message'].valid && form.controls['message'].touched">
					Câmp obligatoriu
				</mat-error>
			</mat-form-field>
		</div>

		<div class="add-action-btns">
			<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
                {{ helper.dialogConfig.buttonLabels.close }}
			</button>
			<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
                {{ helper.dialogConfig.buttonLabels.save }}
			</button>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>