import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { CerereSeriesService } from '../cerere-series.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-edit-nr-curent',
    templateUrl: './edit-nr-curent.component.html',
    styleUrls: ['./edit-nr-curent.component.scss']
})
export class EditNrCurentComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        current_number: new FormControl(this.data.current_number, [Validators.required]),
    })

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<EditNrCurentComponent>,
        private cerereSeriesService: CerereSeriesService,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.cerereSeriesService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { current_number } = this.form.value;

        this.cerereSeriesService
            .updateNrCurent(this.data.id, current_number)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let msg;
            try {
                // try to catch the message from server
                msg = res.error.errors.message[0];
            } catch (error) {
                // controll output message
                msg = helper.dialogConfig.generalMessages.error;
            }
            this.notificationService.warningSwal(
                this.errorTitle,
                msg,
                this.errorIcon
            );
        }
    }
}
