import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RapoarteComponent } from './rapoarte.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    declarations: [RapoarteComponent],
    imports: [
        CommonModule,
        MaterialModule,
    ],
    exports: [RapoarteComponent],
})
export class RapoarteModule { }
