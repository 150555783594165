import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { DirectiiComponent } from './directii.component';
import { AddDirectieComponent } from './add-directie/add-directie.component';
import { EditDirectieComponent } from './edit-directie/edit-directie.component';

@NgModule({
  declarations: [
    DirectiiComponent,
    AddDirectieComponent,
    EditDirectieComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [DirectiiComponent],
})
export class DirectiiModule {}
