import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsSidenavComponent } from './settings-sidenav.component';
import { MaterialModule } from 'src/app/material.module';
import { FunctiiModule } from './functii/functii.module';
import { UserSettingsModule } from './users-settings/user-settings.module';
import { DirectiiModule } from './directii/directii.module';
import { PlatformSectionModule } from './platform-section/platform-section.module';
import { PlatformFaqModule } from './platform-faq/platform-faq.module';
import { PlatformContentModule } from './platform-content/platform-content.module';
import { PlatformAnnouncesModule } from './platform-anunturi/platform-announces.module';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CompartimenteModule } from './compartimente/compartimente.module';
import { JudeteModule } from './judete/judete.module';
import { CerereTypeModule } from './cerere-type/cerere-type.module';
import { CerereStatusModule } from './cerere-status/cerere-status.module';
import { CererePriorityModule } from './cerere-priority/cerere-priority.module';
import { CerereSabloaneModule } from './cerere-sabloane/cerere-sabloane.module';
import { TipuriDocumenteModule } from './tipuri-documente/tipuri-documente.module';
import { CerereSeriesModule } from './cerere-series/cerere-series.module';
import { TipuriBeneficiarModule } from './tipuri-beneficiar/tipuri-beneficiar.module';

@NgModule({
    declarations: [
        SettingsSidenavComponent,
    ],
    imports: [
        CommonModule,
        CompartimenteModule,
        CerereTypeModule,
        CerereStatusModule,
        CererePriorityModule,
        CerereSeriesModule,
        JudeteModule,
        MaterialModule,
        FunctiiModule,
        UserSettingsModule,
        DirectiiModule,
        PlatformSectionModule,
        PlatformFaqModule,
        PlatformContentModule,
        PlatformAnnouncesModule,
        FormsModule,
        ReactiveFormsModule,
        CerereSabloaneModule,
        TipuriDocumenteModule,
        TipuriBeneficiarModule,
    ],
    exports: [SettingsSidenavComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsSidenavModule { }
