import { SidenavModule } from '../components/sidenav/sidenav.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NavbarModule,
    SidenavModule,
  ],
  exports: [RouterModule],
})
export class LayoutModule { }
