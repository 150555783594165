<div class="title p-2 background-white">
    <div class="row">
        <div class="col d-flex justify-content-start align-items-center">
            <h1 class="mx-1 d-flex align-items-center">
                <span>Info solicitare {{cerere?.reg_number ? cerere?.reg_number : 'N/A' }} / {{ cerere?.created_at | date:
                    'dd.MM.yyyy' }}</span>
                <span class="text-secondary m-2">#{{ cerere?.id }}</span>
                <button type="button" class="btn btn-small btn-primary me-3" (click)="getRequestInfo()">
                    <i class="fa-solid fa-arrows-rotate"></i>
                    <span class="ms-2">Reîncarcă informații</span>
                </button>
                <ng-container *ngIf="cerere?.validated_date && !cerere?.cancel_date">
                    <h4 style="border-radius: 10px; padding: 4px 8px; color: white; background: green; margin: 0;"><i class="fa-solid fa-flag" style="color: white;"></i> Eligibil</h4>
                </ng-container>
                <ng-container *ngIf="cerere?.cancel_date && cerere?.cancel_type === 1">
                    <h4 style="border-radius: 10px; padding: 4px 8px; color: white; background: orange; margin: 0;"><i class="fa-solid fa-flag" style="color: white;"></i> NE-Eligibil</h4>
                </ng-container>
                <ng-container *ngIf="cerere?.cancel_date && cerere?.cancel_type === 2">
                    <h4 style="border-radius: 10px; padding: 4px 8px; color: white; background: red; margin: 0;"><i class="fa-solid fa-flag" style="color: white;"></i> NE-Eligibil</h4>
                </ng-container>
            </h1>
        </div>
        <div class="col d-flex justify-content-end">
            <button type="button" class="action-btn" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="row p-3 responsive-column background-white">
    <div class="col-8 responsive-box-info">
        <div class="g-0">
            <div class="row">
                <div class="col ">
                    <div class="box-wrapper box-mesagerie">
                        <div class="box-content-header">
                            <div class="row p-1">
                                <div class="col">
                                    <mat-card-title>
                                        <i class="fa-solid fa-book"></i>
                                        <span>Conținut solicitare</span>
                                    </mat-card-title>
                                </div>
                            </div>
                        </div>
                        <div class="box-content-body">
                            <div class="col">
                                <div *ngIf="cerere?.type_slug === 'depunere_proiect'" class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div class="fw-bold">Denumire UAT</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">COD SIRUTA UAT</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Tip UAT</div>
                                    </div>
                                </div>
                                <div *ngIf="cerere?.type_slug === 'depunere_proiect'" class="row py-1 mx-1">
                                    <div class="col">
                                        <div>{{cerere?.denumire_uat || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.cif_uat || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.tip_beneficiar}}</div>
                                    </div>
                                </div>

                                <div class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div *ngIf="cerere?.type_slug === 'depunere_proiect'" class="fw-bold">Denumire responsabil UAT</div>
                                        <div *ngIf="cerere?.type_slug === 'suport_tehnic'" class="fw-bold">Denumire responsabil</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Email</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Telefon</div>
                                    </div>
                                </div>
                                <div class="row py-1 mx-1">
                                    <div class="col">
                                        <div>{{cerere?.nume_reprezentant}}</div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center gap-2">
                                            <span>
                                                {{cerere?.email || 'n/a'}}
                                            </span>
                                            <span class="d-flex align-items-center gap-2">
                                                <i *ngIf="cerere?.type_slug === 'depunere_proiect' && permissionsService.hasPermission('solicitari.view.actualizare-informatii')" 
                                                    style="cursor: pointer;" class="fa fa-pencil text-warning" (click)="openEditDialog(cerere?.email, 1)">
                                                </i>
                                                <i *ngIf="cerere?.type_slug === 'depunere_proiect' && permissionsService.hasPermission('solicitari.view.resend-notification')" 
                                                    style="cursor: pointer;" class="fa-solid fa-share text-info" title="Retrimitere notificare email" (click)="resendEmailNotification(cerere?.id)">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center gap-2">
                                            <span>
                                                {{cerere?.telefon || 'n/a'}} 
                                            </span>
                                            <i *ngIf="cerere?.type_slug === 'depunere_proiect' && permissionsService.hasPermission('solicitari.view.actualizare-informatii')" 
                                                style="cursor: pointer;" class="fa fa-pencil text-warning" (click)="openEditDialog(cerere?.telefon, 2)">
                                            </i>
                                            <i *ngIf="cerere?.type_slug === 'depunere_proiect' && permissionsService.hasPermission('solicitari.view.resend-notification')" 
                                                style="cursor: pointer;" class="fa-solid fa-share text-info" title="Retrimitere notificare SMS" (click)="resendSmsNotification()">
                                            </i>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="cerere?.type_slug === 'suport_tehnic'" class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div class="fw-bold">Subiect</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Mesaj</div>
                                    </div>
                                    <div class="col"></div>
                                </div>
                                <div *ngIf="cerere?.type_slug === 'suport_tehnic'" class="row py-1 mx-1">
                                    <div class="col">
                                        <div>{{cerere?.subject || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.description || 'n/a'}}</div>
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="cerere?.type_slug === 'depunere_proiect'" class="g-0">
            <div class="row">
                <div class="col ">
                    <div class="box-wrapper box-mesagerie">
                        <div class="box-content-header">
                            <div class="row p-1">
                                <div class="col">
                                    <mat-card-title>
                                        <i class="fa-solid fa-hashtag"></i>
                                        <span>Token</span>
                                    </mat-card-title>
                                </div>
                            </div>
                        </div>
                        <div class="box-content-body">
                            <div class="col">
                                <div class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div class="fw-bold">Crt.</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Token</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Trimis</div>
                                    </div>
                                </div>
                                <div class="row py-1 mx-1">
                                    <div class="col">
                                        <div>{{cerere?.code_index || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.code || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.code_time}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-0" *ngIf="documenteCetatean.length > 0">
            <div class="col">
                <div class="box-wrapper box-documents">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title>
                                    <i class="fa-solid fa-folder-tree"></i>
                                    <span>Documente</span>
                                </mat-card-title>
                            </div>
                        </div>
                    </div>

                    <div class="box-content-body">
                        <div *ngFor="let document of documenteCetatean; let i = index">
                            <div class="row align-items-center pe-2">
                                <div class="col-auto">
                                    <img src="assets/icons/doc-icon.svg" alt="Document">
                                </div>
                                <div class="col">
                                    <div class="cerere-title">{{ document.doc_type }}</div>
                                    <p>Denumire document: {{ document.old_name }}</p>
                                </div>
                                <div class="col-auto ms-auto">
                                    <button type="button" class="btn btn-light open-btn" (click)="downloadDocument(document)">
                                        <img src="/assets/icons/view.svg" alt="VIZUALIZAȚI" />
                                        <span class="view-btn-text">VIZUALIZAȚI</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-0">
            <div class="col">
                <div class="box-wrapper box-mesagerie">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title>
                                    <i class="fa-brands fa-rocketchat"></i>
                                    <span>Mesagerie</span>
                                </mat-card-title>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <div>
                                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onMessagingChange($event)" value="internalMsg">
                                        <mat-button-toggle value="toCetatean">Către UAT</mat-button-toggle>
                                        <mat-button-toggle value="internalMsg">Mesaj Intern</mat-button-toggle>
                                        <mat-button-toggle value="nota">Notă</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-content-body">
                        <div class="row py-2" *ngIf="selectedMessagingToggle !== 'toCetatean'">
                            <div class="col">
                                <div class="fw-bold p-1">Adăugați un nou mesaj</div>
                            </div>
                        </div>
                        <div class="row py-2" *ngIf="selectedMessagingToggle == 'toCetatean'">
                            <div class="col">
                                <div class="fw-bold p-1">Transmiteți către UAT un mesaj</div>
                            </div>
                        </div>

                        <form [formGroup]="sendMessageCetateanForm" (ngSubmit)="submitMessage('cetatean')" *ngIf="selectedMessagingToggle == 'toCetatean'">
                            <div class="row p-1">
                                <div class="col">
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>

                            <div class="solicitare-card__upload mx-2 mt-3">
                                <div class="solicitare-card__upload-header">
                                    <p class="upload-text">Fotografii/Documente</p>
                                    <button class="upload-btn" (click)="fileInputCetatean.click()" type="button">
                                        <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                        ÎNCARCĂ
                                    </button>
                                </div>
                                <div class="solicitare-card__upload-files" *ngIf="sendMessageCetateanForm.value.files.length > 0 && !uploading.file">
                                    <div class="solicitare-card__upload-file" *ngFor="let uploadedFile of sendMessageCetateanForm.value.files; let i = index">
                                        <span class="solicitare-card__upload-file-name">
                                            {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) + '..' : (uploadedFile.original_name)
                                            }}
                                        </span>
                                        <div>
                                            <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFileCetatean(i)">
                                                <img class="btn-img" src="../../../assets/icons/delete.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file"></mat-spinner>

                                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileInputCetatean
                                    name="fileInputCetatean" (change)="addFileCetatean($event, 'file')" multiple style="display: none">
                            </div>


                            <div class="row my-3">
                                <div class="col d-flex align-items-center">
                                    <mat-checkbox style="margin-right: 30px" formControlName="notify" color="primary">Notificare răspuns
                                    </mat-checkbox>
                                    <form [formGroup]="notifyAnswer" style="width: 30%">
                                        <ng-container *ngIf="sendMessageCetateanForm.get('notify')?.value">
                                            <mat-form-field class="mt-3">
                                                <mat-label>Număr zile</mat-label>
                                                <mat-select formControlName="notify_date_expire">
                                                    <mat-option *ngFor="let response of responseTimes" [value]="response.value">
                                                        {{ response.key }}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="
                                                    notifyAnswer.get('notify_date_expire')?.touched &&
                                                    notifyAnswer.get('notify_date_expire')?.hasError('required')
                                                    ">
                                                    Câmp obligatoriu
                                                </mat-error>
                                            </mat-form-field>
                                        </ng-container>
                                    </form>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col d-flex justify-content-between">
                                    <div class="p-1">
                                        <button class="btn btn-light upload-btn" type="button" (click)="openSabloane()">
                                            <span class="upload__text">Șabloane</span>
                                        </button>
                                    </div>

                                    <div class="p-1">
                                        <button [disabled]="!sendMessageCetateanForm.valid" class="btn btn-light upload-btn" type="submit">
                                            <span class="upload__text">TRIMITE</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form [formGroup]="sendMessageInternForm" (ngSubmit)="submitMessage('intern')" *ngIf="selectedMessagingToggle == 'internalMsg'">
                            <div class="row p-1">
                                <div class="col">
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>

                            <div class="row p-1">
                                <mat-form-field class="w-100">
                                    <mat-label>Adaugă coleg</mat-label>
                                    <mat-chip-grid #chipGrid aria-label="Selectare colegi">
                                        <mat-chip-row *ngFor="let coleague of coleagues" (removed)="removeColeague(coleague)">
                                            {{coleague.name}}
                                            <button matChipRemove [attr.aria-label]="'remove ' + coleague.name">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                    </mat-chip-grid>
                                    <input placeholder="Coleg nou..." #coleagueInput [formControl]="coleaguesCtrl" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addColeague($event)"
                                    />
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedColeagues($event)">
                                        <mat-option *ngFor="let coleague of filteredColeagues | async" [value]="coleague">
                                            {{coleague.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="solicitare-card__upload mx-2">
                                <div class="solicitare-card__upload-header">
                                    <p class="upload-text">Fotografii/Documente</p>
                                    <button class="upload-btn" (click)="fileInputIntern.click()" type="button">
                                        <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                        ÎNCARCĂ
                                    </button>
                                </div>
                                <div class="solicitare-card__upload-files" *ngIf="sendMessageInternForm.value.files.length > 0 && !uploading.file">
                                    <div class="solicitare-card__upload-file" *ngFor="let uploadedFile of sendMessageInternForm.value.files; let i = index">
                                        <span class="solicitare-card__upload-file-name">
                                            {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) + '..' : (uploadedFile.original_name)
                                            }}
                                        </span>
                                        <div>
                                            <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFileIntern(i)">
                                                <img class="btn-img" src="../../../assets/icons/delete.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file"></mat-spinner>

                                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileInputIntern
                                    name="fileInputIntern" (change)="addFileIntern($event, 'file')" multiple style="display: none">
                            </div>

                            <div class="col d-flex justify-content-end">
                                <button [disabled]="!sendMessageInternForm.valid" class="btn btn-light upload-btn m-3" type="submit">
                                    <span class="upload__text">TRIMITE</span>
                                </button>
                            </div>

                        </form>

                        <form [formGroup]="sendMessageNotitaForm" (ngSubmit)="submitMessage('nota')" *ngIf="selectedMessagingToggle == 'nota'">
                            <div class="row p-1">
                                <div class="col">
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>

                            <div class="solicitare-card__upload mx-2 mt-3">
                                <div class="solicitare-card__upload-header">
                                    <p class="upload-text">Fotografii/Documente</p>
                                    <button class="upload-btn" (click)="fileInputNotita.click()" type="button">
                                        <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                        ÎNCARCĂ

                                    </button>
                                </div>
                                <div class="solicitare-card__upload-files" *ngIf="sendMessageNotitaForm.value.files.length > 0 && !uploading.file">
                                    <div class="solicitare-card__upload-file" *ngFor="let uploadedFile of sendMessageNotitaForm.value.files; let i = index">
                                        <span class="solicitare-card__upload-file-name">
                                            {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) + '..' : (uploadedFile.original_name)
                                            }}
                                        </span>
                                        <div>
                                            <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFileNota(i)">
                                                <img class="btn-img" src="../../../assets/icons/delete.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file"></mat-spinner>

                                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileInputNotita
                                    name="fileInputNotita" (change)="addFileNotita($event, 'file')" multiple style="display: none">

                            </div>
                            <div class="col d-flex justify-content-end">
                                <button [disabled]="!sendMessageNotitaForm.valid" class="btn btn-light upload-btn m-3" type="submit">
                                    <span class="upload__text">TRIMITE</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <app-cereri-istoric [user]="user" [istoric]="istoric" [id_cerere]="cerere?.id" [notifyIds]="cerere?.historyNotify"
            (requestInfoUpdated)="getRequestInfo()"></app-cereri-istoric>

    </div>
    <div class="col-4 ">
        <div class="g-0">
            <div class="col responsive-box-info">
                <div class="box-wrapper box-info">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title>
                                    <i class="fa-solid fa-circle-info"></i>
                                    <span>Info solicitare</span>
                                </mat-card-title>
                            </div>
                        </div>
                    </div>
                    <div class="box-content-body">
                        <table class="table table-condensed table-wrapper-box">
                            <tbody>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Tip</strong>
                                    </td>
                                    <td class="text-left">{{cerere?.type_name}}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Prioritate</strong>
                                    </td>
                                    <td class="text-left">
                                        <span class="badge" [ngStyle]="{
                                                'background-color': cerere?.priority_bg_color,
                                                color: '#ffffff'
                                              }">
                                            {{ cerere?.priority_name }}
                                        </span>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Status</strong>
                                    </td>
                                    <td class="text-left">
                                        <span class="badge" [ngStyle]="{
                                                'background-color': cerere?.status_bg_color,
                                                color: '#ffffff'
                                              }">
                                            {{ cerere?.status_name }}
                                        </span>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Sla</strong>
                                    </td>
                                    <td class="text-left">
                                        <div [innerHTML]="safeHtml"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Instituție/Direcție</strong>
                                    </td>
                                    <td class="text-left">{{ cerere?.directie || 'n/a' }}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Serviciu/Birou</strong>
                                    </td>
                                    <td class="text-left">{{ cerere?.compartiment || 'n/a' }}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Operator asignat</strong>
                                    </td>
                                    <td class="text-left">{{ cerere?.user_assigned }}</td>
                                </tr>
                                <tr *ngIf="cerere?.check_uat_inregistrat !== undefined">
                                    <td width="40%" class="text-center">
                                        <strong>Solicitare depusă</strong>
                                    </td>
                                    <td *ngIf="cerere?.check_uat_inregistrat" class="text-left"><i class="fa-regular fa-square-check"></i></td>
                                    <td *ngIf="!cerere?.check_uat_inregistrat" class="text-left"><i class="fa-regular fa-square"></i></td>
                                </tr>
                                <tr *ngIf="cerere?.check_documente_incarcate !== undefined">
                                    <td width="40%" class="text-center">
                                        <strong>Documente depuse</strong>
                                    </td>
                                    <td *ngIf="cerere?.check_documente_incarcate" class="text-left"><i class="fa-regular fa-square-check"></i></td>
                                    <td *ngIf="!cerere?.check_documente_incarcate" class="text-left"><i class="fa-regular fa-square"></i></td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Dată solicitare</strong>
                                    </td>
                                    <td class="text-left">{{cerere?.created_at | date: 'dd.MM.yyyy HH:mm'}}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Ultima actualizare</strong>
                                    </td>
                                    <td class="text-left">{{cerere?.updated_at | date: 'dd.MM.yyyy HH:mm'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-0">
            <div class="col">
                <div class="box-wrapper box-actiuni-cerere responsive-box-info">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title>
                                    <i class="fa-solid fa-shuffle"></i>
                                    <span>Acțiuni solicitare</span>
                                </mat-card-title>
                            </div>
                        </div>
                    </div>
                    <div class="box-content-body">
                        <div class="row p-2 d-flex">
                            <div class="w-auto" *ngIf="
                                      cerere?.user_assigned_id == null ||
                                      cerere?.user_assigned_id !== user.id_user
                                    ">
                                <button class="btn btn-light upload-btn" type="button" (click)="confirmPreluarePersonala()">
                                    <span class="upload__text">PRELUARE PERSONALĂ</span>
                                </button>
                            </div>
                        </div>
                        <div class="row p-2 d-flex box-content-body-responsive ">
                            <div class="col" [class.hidden]="(cerere?.cancel_type == 1 && cerere?.cancel_date !== null) || cerere?.type_slug === 'suport_tehnic'">
                                <actiuni-cerere-card title="Status validat" description="Nevalidat" iconName="check" [cerere]="cerere" actionText="Validează"
                                    actionType="validate" 
                                    (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                            </div>
                            <ng-container>
                                <div class="col" *ngIf="cerere?.type_slug === 'depunere_proiect' && cerere?.validated_date === null && (
                                    (cerere?.cancel_type == null && cerere?.finalize_date == null) ||
                                    (cerere?.cancel_type == 1))">
                                    <actiuni-cerere-card title="Invalidare Cerere" description="Solictiare încă valabilă de validare. Nu validați decât dacă este cazul!" iconName="report_problem"
                                        actionText="Invalidează" [cerere]="cerere" actionType="invalidate"
                                        (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                                </div>
                                <div class="col" *ngIf="cerere?.type_slug === 'depunere_proiect' && (cerere?.validated_date !== null && cerere?.cancel_type == null && cerere?.finalize_date == null) ||
                                    cerere?.cancel_type == 2">
                                    <actiuni-cerere-card title="Clasare" description="Clasare" iconName="report_problem" actionText="Clasare" [cerere]="cerere"
                                        actionType="anulare" 
                                        (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="cerere?.cancel_type !== null || cerere?.validated_date !== null || cerere?.type_slug === 'suport_tehnic'">
                                <div class="col">
                                    <actiuni-cerere-card title="Status Închidere Lucrare" description="Închidere lucrare doar în momentul în care a fost soluționată solicitirea."
                                        iconName="check" actionText="Închidere lucrare" [cerere]="cerere" actionType="finalizare"
                                        (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-actualizare-cerere [data]="data" (cerereChanged)="onCerereChanged($event)" (istoricChanged)="onIstoricChanged($event)"></app-actualizare-cerere>
    </div>
</div>
<div class="section-spinner position-fixed" *ngIf="isLoading">
    <div class="spinner position-absolute top-50 start-50 translate-middle">
        <img src="assets/images/newAppLogo.svg" height="50%" width="50%" alt="Guvernul României" />
        <mat-spinner class="mt-1"></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>