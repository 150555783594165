import { Component } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AlertDialogComponent } from 'src/app/utils/alert-dialog/alert-dialog.component';
import { DialogService } from 'src/app/utils/services/dialog.service';

import { AuthService } from 'src/app/auth-module/auth.service';
import Swal from 'sweetalert2';

import { StorageService } from 'src/app/utils/storage/storage.service';
import { environment } from 'src/environments/environment';

import { helper } from '../.././../environments/helper';

import { atLeastOneFieldValidator } from '../../utils/common/validators';

import { ChangePasswordForceComponent } from './change-password-force/change-password-force.component';
import { IChangePasswordForceUserPayload } from './change-password-force.interface';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    isLoading = false;
    hide = true;
    form: FormGroup = new FormGroup({
        email: new FormControl(''),
        username: new FormControl(''),
        password: new FormControl(''),
    });

    constructor(
        private formsBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private storageService: StorageService
    ) { }

    ngOnInit(): void {
        this.initLoginForm();

        const isTrue = localStorage.getItem(environment.config.storageKey + 'saveme');

        if (isTrue) {
            this.form.patchValue({ email: localStorage.getItem(environment.config.storageKey + 'email') });
            this.form.patchValue({ username: localStorage.getItem(environment.config.storageKey + 'username') });
            this.form.patchValue({ password: localStorage.getItem(environment.config.storageKey + 'password') });
            this.form.patchValue({ saveme: true });
        }
    }

    initLoginForm() {
        this.form = this.formsBuilder.group(
            {
                email: ['', [Validators.required, Validators.email]],
                username: ['', [Validators.required]],
                password: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(6),
                        Validators.maxLength(40),
                    ],
                ],
                saveme: new FormControl(false),
            },
            { validators: atLeastOneFieldValidator('email', 'username') }
        );
    }

    submitForm(form: FormGroup) {
        if (this.isLoading) return;

        this.isLoading = true;

        this.checkSaveMe();

        this.authService
            .login(this.form.value)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response: any) => {
                    if (
                        typeof response.data.force_password_change !== 'undefined' &&
                        response.data.force_password_change == true
                    ) {
                        // we need to force password change as a must
                        this.openChangeForcePassword(response.data);

                        // return false
                    } else {
                        // This sets the user data inside our session store
                        const user = response.data.user;
                        const userPromise = this.storageService.setObject(
                            environment.config.userKey,
                            user
                        );

                        // This sets users' token in session storage
                        const token = response.data.authorization.token;
                        const tokenPromise = this.storageService.setString(
                            environment.config.tokenKey,
                            token
                        );

                        Promise.all([userPromise, tokenPromise])
                            .then(() => {
                                this.router.navigate(['/requests']);
                            })
                            .catch((error: any) => {
                                console.error('Error:', error);
                            });
                    }
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }

                    const dialogRef = this.dialog.open(
                        AlertDialogComponent,
                        this.dialogService.getDialogConfig(
                            helper.dialogConfig.headers.error,
                            msg
                        )
                    );
                    Object.assign(dialogRef.componentInstance, { dialogRef });
                },
            });
    }

    openChangeForcePassword(data: IChangePasswordForceUserPayload) {
        // please add missing fields to payload
        data.email = this.form.value.email;
        data.username = this.form.value.username;

        const dialogRef = this.dialog.open(ChangePasswordForceComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                this.isLoading = false;
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Parola actualizată cu succes. Vă rugăm să vă autentificați cu noua parolă. Atenție! Trebuie să completați parola nouă completată de dumneavoastră.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                });
            }
        });
    }

    checkSaveMe() {
        if (!this.form.valid) return;

        if (this.form.value.saveme) {
            // add to storage
            localStorage.setItem(environment.config.storageKey + 'saveme', 'true');
            localStorage.setItem(environment.config.storageKey + 'email', this.form.value.email);
            localStorage.setItem(environment.config.storageKey + 'username', this.form.value.username);
            localStorage.setItem(environment.config.storageKey + 'password', this.form.value.password);
        } else {
            // remove from storage
            localStorage.removeItem(environment.config.storageKey + 'saveme');
            localStorage.removeItem(environment.config.storageKey + 'email');
            localStorage.removeItem(environment.config.storageKey + 'username');
            localStorage.removeItem(environment.config.storageKey + 'password');
        }
    }
}
