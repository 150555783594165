import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DirectiiService } from '../directii.service';
import { IDirectieAddPayload, IDirectieForm } from '../directii.interface';
import { environment } from 'src/environments/environment';

import { helper } from 'src/environments/helper';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-add-directie',
    templateUrl: './add-directie.component.html',
    styleUrls: ['./add-directie.component.scss'],
})
export class AddDirectieComponent {
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        slug: new FormControl('', [noSpacesOrDotsValidator()]),
        description: new FormControl('', []),
    });

    constructor(
        private dialogRef: MatDialogRef<AddDirectieComponent>,
        private directiiService: DirectiiService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.directiiService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, description } = this.form.value as IDirectieForm;
        const slug = name
            .split(' ')
            .map((item) => item.toLowerCase())
            .join('_');
        const payload: IDirectieAddPayload = {
            name,
            slug,
            description,
        };

        this.directiiService
            .addDirectie(payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
