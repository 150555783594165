import { Component, OnInit } from '@angular/core';
import { helper } from 'src/environments/helper';
import { CerereSabloaneService } from '../../../../settings-sidenav/cerere-sabloane/cerere-sabloane.service';
import { finalize } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestsService } from '../../requests.service';

@Component({
  selector: 'app-cerere-detalii-sabloane',
  templateUrl: './cerere-detalii-sabloane.component.html',
  styleUrls: ['./cerere-detalii-sabloane.component.scss'],
})
export class CerereDetaliiSabloaneComponent implements OnInit {
  isLoading = false;
  helper = helper;
  sabloaneData: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<CerereDetaliiSabloaneComponent>,
    private sabloaneService: CerereSabloaneService,
    private requestService: RequestsService
  ) {}

  ngOnInit(): void {
    this.getSabloane();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  getSabloane() {
    this.isLoading = true;
    this.sabloaneService
      .getSabloane()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((response: any) => {
        this.sabloaneData = response.data;
      });
  }

  sendSablon(sablonContent: string) {
    this.requestService.sendContent(sablonContent);
    this.closeDialog();
  }
}
