import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ClientPermissionsService {
    private httpOptions: any;

    private basePath = environment.interop.basePath;
    private getUserPermissionsAuthLibUrl = this.basePath + environment.interop.clients.permissions.getUserPermissionsAuthLib;
    private getUserPermissionsUrl = this.basePath + environment.interop.clients.permissions.listUserPermissions;
    private updateAssignedPermissionUserUrl = this.basePath + environment.interop.clients.permissions.updateAssignedPermissionUser;
    private listPermissionsUrl = this.basePath + environment.interop.clients.permissions.listPermissions;

    constructor(private http: HttpClient) { }

    getUserPermissionsAuthLib(client_id: number, module_id: number) {
        return this.http
            .get<{ errors: boolean; data: { [client_id: number]: { permissions: any[], groups: any[] } }; status_code: number }>(
                this.getUserPermissionsAuthLibUrl + client_id + '/' + module_id
            )
            .pipe(map((response) => response.data[client_id].permissions));
    }

    getUserPermissions(payload: { client_id: number, user_id: number }) {
        return this.http.post(this.getUserPermissionsUrl, payload);
    }

    updateAssignedPermissionUser(data: any): Observable<any> {
        return this.http.post(this.updateAssignedPermissionUserUrl, data);
    }

    listPermissions() {
        return this.http.get(this.listPermissionsUrl);
    }
}
