import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { TipuriBeneficiarService } from '../tipuri-beneficiar.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';
import { TipuriBeneficiarAddPayload } from '../tipur-beneficiar.interface';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-add-tipuri-beneficiar',
    templateUrl: './add-tipuri-beneficiar.component.html',
    styleUrls: ['./add-tipuri-beneficiar.component.scss']
})
export class AddTipuriBeneficiarComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        slug: new FormControl('', [Validators.required, noSpacesOrDotsValidator()]),
    });

    constructor(
        private dialogRef: MatDialogRef<AddTipuriBeneficiarComponent>,
        private tipuriBeneficiarService: TipuriBeneficiarService,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.tipuriBeneficiarService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, slug } = this.form
            .value as TipuriBeneficiarAddPayload;

        const newTipBeneficiar: TipuriBeneficiarAddPayload = {
            name,
            slug
        };

        this.tipuriBeneficiarService
            .addTipBeneficiar(newTipBeneficiar)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
