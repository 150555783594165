import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../utils/storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    private userPermissions: string[] = [];
    private userPermissions$: ReplaySubject<any> = new ReplaySubject<any>();
    private isUserAdmin: boolean = false;

    private basePath = environment.interop.basePath;
    private getUserPermissionsUrl = this.basePath + environment.interop.clients.permissions.getUserPermissionsAuthLib;

    private clientId = environment.interop.clientId;
    private moduleId = environment.interop.moduleId;

    constructor(private http: HttpClient, private storageService: StorageService) { }

    private fetchUserPermissions(clientId: number) {
        return this.http
            .get(this.getUserPermissionsUrl + clientId)
            .pipe(
                // tap((data) => console.log(data)),
                map(
                    (response: any) =>
                        (Object.values(response.data)[0] as any).permissions
                )
            );
    }

    storeUserPermissions() {
        return this.fetchUserPermissions(this.clientId)
            .subscribe({
                next: (data: any) => {
                    this.userPermissions = data;
                    this.userPermissions$.next(true);
                },
                error: (err: any) => {
                    console.log(err);
                },
            });
    }

    getUserPermissions() {
        return this.userPermissions;
    }

    getUserPermissions$() {
        return this.userPermissions$;
    }

    checkUserAdmin(): any {
        var self = this;
        const userObj = self.storageService.getObject(environment.config.userKey);
        userObj
            .then((val) => {
                this.isUserAdmin = val.user_type_id == 1 ? true : false;
            })
            .catch((err) => {
                console.log(err, 'error on storage read');
            });
    }

    hasPermission(targetPermission: string): boolean {
        if (this.isUserAdmin) {
            return true;
        }
        return this.userPermissions.some((permission) =>
            permission.includes(targetPermission)
        );
    }

    resetUserPermissions() {
        this.isUserAdmin = false;
        this.userPermissions = [];
        this.userPermissions$ = new ReplaySubject<any>();
    }
}
