import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestsService } from '../../requests.service';
import { finalize } from 'rxjs';
import { helper } from 'src/environments/helper';
import { NotificationService } from 'src/app/services/notification.service';
import { notDefaultValidator } from 'src/app/shared/validators/not-default';

@Component({
    selector: 'app-notificare-email',
    templateUrl: './notificare-email.component.html',
    styleUrls: ['./notificare-email.component.scss']
})
export class NotificareEmailComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    isLoading = false;

    form: FormGroup = new FormGroup({
        value: new FormControl('-1', [Validators.required, notDefaultValidator('-1')]),
    });

    values: { id: number, name: string }[] = [
        { id: 2, name: 'Confirmare' },
        { id: 3, name: 'Confirmare primire documente' },
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NotificareEmailComponent>,
        private requestService: RequestsService,
        private notificationService: NotificationService,
    ) { }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    submitForm() {
        this.isLoading = true;

        this.requestService
            .resendNotification(this.data, this.form.get('value')?.value)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true })
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                }
            })
    }
}
