import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientAddPayload, ClientEditPayload } from './client.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private token!: string;
  private httpOptions: any;

  private basePath = environment.interop.basePath;
  private getClientsDTURL =
    this.basePath +
    environment.interop.platform.nomenclatoare.client.getClientsDT;
  private storeClientsURL =
    this.basePath +
    environment.interop.platform.nomenclatoare.client.storeClient;
  private updateClientURL =
    this.basePath + environment.interop.platform.nomenclatoare.client.putClient;
  private deleteClientURL =
    this.basePath +
    environment.interop.platform.nomenclatoare.client.deleteClient;
  private getClientDetailsURL =
    this.basePath + environment.interop.platform.nomenclatoare.client.getClient;

  private getActiveClientsURL = this.basePath + environment.interop.platform.nomenclatoare.client.getClientsActive;

  constructor(private http: HttpClient) { }

  getClientsDT() {
    return this.http.get(this.getClientsDTURL, this.httpOptions);
  }

  getClientById(id: number) {
    return this.http.get(this.getClientDetailsURL + id, this.httpOptions);
  }

  getActiveClients() {
    return this.http.get(this.getActiveClientsURL, this.httpOptions);
  }

  addClient(client: ClientAddPayload) {
    return this.http.post(this.storeClientsURL, client, this.httpOptions);
  }

  updateClient(client: ClientEditPayload) {
    return this.http.put(this.updateClientURL, client, this.httpOptions);
  }

  deleteClient(id: number) {
    return this.http.delete(this.deleteClientURL + id);
  }

  setToken(token: string) {
    this.token = token;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      }),
    };
  }
}
