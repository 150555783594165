import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DirectiiService } from '../directii.service';
import {
    IDirectie,
    IDirectieForm,
    IDirectieUpdatePayload,
} from '../directii.interface';

import { helper } from 'src/environments/helper';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-edit-directie',
    templateUrl: './edit-directie.component.html',
    styleUrls: ['./edit-directie.component.scss'],
})
export class EditDirectieComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl(this.data.name, [Validators.required]),
        slug: new FormControl(this.data.slug, [noSpacesOrDotsValidator()]),
        description: new FormControl(this.data.description, []),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IDirectie,
        private dialogRef: MatDialogRef<EditDirectieComponent>,
        private directiiService: DirectiiService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.directiiService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, slug, description } = this.form.value as IDirectieForm;
        // const slug = name
        // 	.split(' ')
        // 	.map((item) => item.toLowerCase())
        // 	.join('_');
        const payload: IDirectieUpdatePayload = {
            id_directie: this.data.id_directie,
            name,
            slug,
            description,
        };

        this.directiiService
            .updateDirectie(payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err: any) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let msg;
            try {
                // try to catch the message from server
                msg = res.error.errors.message[0];
            } catch (error) {
                // controll output message
                msg = helper.dialogConfig.generalMessages.error;
            }
            this.notificationService.warningSwal(
                this.errorTitle,
                msg,
                this.errorIcon
            );
        }
    }
}
