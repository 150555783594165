import { Component, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { environment } from 'src/environments/environment';
import { DirectiiService } from './directii.service';
import { IDirectieUpdatePayload } from './directii.interface';
import { EditDirectieComponent } from './edit-directie/edit-directie.component';
import { AddDirectieComponent } from './add-directie/add-directie.component';

import { helper } from 'src/environments/helper';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-institutii-directii',
    templateUrl: './directii.component.html',
    styleUrls: ['./directii.component.scss'],
})
export class DirectiiComponent {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = true;
    showCard = false;
    activeValue: string = '-1';
    publicValue: string = '-1';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.directie.getDirectiiDT;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    toggleCard() {
        this.showCard = !this.showCard;
    }

    resetForm() {
        this.activeValue = '-1';

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    onSelectionChange(event: any): void {
        this.activeValue = event.target.value;
        this.publicValue = event.target.value;
    }

    constructor(
        private dialogService: DialogService,
        private http: HttpClient,
        private dialog: MatDialog,
        private directiiService: DirectiiService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            pagingType: 'full_numbers',
            serverSide: true,
            // processing: true,
            responsive: true,
            pageLength: 25,
            searching: false,
            lengthMenu: [10, 25, 50, 100],
            language: environment.config.romanianLanguageDataTable,
            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.active = this.activeValue;
                // dataTablesParameters.public = this.publicValue;

                // show loader first
                this.isLoading = true;

                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        console.log(resp);
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            columns: [
                {
                    title: 'ID',
                    data: 'id_directie',
                    searchable: false,
                },
                {
                    title: 'Denumire',
                    data: 'name',
                    searchable: false,
                },
                {
                    title: 'Slug',
                    data: 'slug',
                    searchable: false,
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    orderable: false,
                    className: 'all',
                    searchable: false,
                    render: (data: any, type: any, row: any, met: any) => {
                        return (
                            `
              <div class="d-flex align-center justify-content-left">
						<button type="button" class="action-btn edit ms-1">
							<img class="btn-img" src="/assets/icons/edit.svg" alt="edit"/>
						</button>
						<button type="button" class="action-btn delete ms-1">
							<img class="btn-img" src="/assets/icons/delete.svg" alt="delete"/>
						</button>
						<button type="button" class="action-btn update_status_active ms-1 ` +
                            (row.active == 1 ? 'action-success' : 'action-danger') +
                            `">
							<img class="btn-img" src="` +
                            (row.active == 1
                                ? '/assets/icons/check-white.svg'
                                : '/assets/icons/close-white.svg') +
                            `" alt="update_status_active"/>
						</button>
            </div>
					`
                        );
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any, index: number) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;
                const updateStatusActiveIcon = lastTd?.querySelector(
                    '.update_status_active'
                ) as HTMLElement;

                if (editIcon) {
                    if (!this.permissionsService.hasPermission('settings.directii.update')) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (deleteIcon) {
                    if (!this.permissionsService.hasPermission('settings.directii.delete')) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id_directie } = data;
                        this.deleteDirectie(id_directie);
                    });
                }

                if (updateStatusActiveIcon) {
                    if (!this.permissionsService.hasPermission('settings.directii.update-status')) {
                        updateStatusActiveIcon.style.display = 'none';
                    }
                    updateStatusActiveIcon.addEventListener('click', () => {
                        const { id_directie } = data;
                        this.changeStatusActive(id_directie);
                    });
                }

                return row;
            },
        };
    }

    changeStatusActive(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul direcției selectate!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusActiveAction(id);
            }
        });
    }

    changeStatusActiveAction(id: number) {
        this.directiiService.changeStatusDirectieActive(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Direcție actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusActiveAction(id);
                    }
                });
            }
        );
    }

    rerenderDT(resetPaging = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(resetPaging);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddDirectieComponent, {
            ...this.dialogService.getDialogConfig(),
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Direcție adăugată cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: IDirectieUpdatePayload) {
        const dialogRef = this.dialog.open(EditDirectieComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Direcție actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    deleteDirectie(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // we do this because we need to have some control on activity
                this.deleteDirectieAction(id);
            }
        });
    }

    deleteDirectieAction(id: number) {
        this.directiiService.deleteDirectie(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Direcție ștearsă cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }

                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteDirectieAction(id);
                    }
                });
            }
        );
    }
}
