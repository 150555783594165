import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { EditUserPayload, User } from '../user.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { UsersService } from '../users.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, finalize, map } from 'rxjs';
import { atLeastOneFieldValidator } from 'src/app/utils/common/validators/at-least-one-field';
import { DirectiiService } from '../../directii/directii.service';
import { CompartimenteService } from '../../compartimente/compartimente.service';
import { FunctiiService } from '../../functii/functii.service';
import { Generic, GenericCompartiment, GenericDirectie } from 'src/app/interfaces/generic.interface';
import { completeNameValidator } from 'src/app/shared/validators/complete-name';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    userTypes$!: Observable<Generic[]>;
    directiiActive$!: Observable<Generic[]>;
    compartimenteActive$!: Observable<Generic[]>;
    functiiActive$!: Observable<Generic[]>;

    options: { key: string; value: boolean }[] = [
        { key: 'Da', value: true },
        { key: 'Nu', value: false },
    ];

    defaultParameter: { name: string; id: string } = {
        name: '--Alegeți--',
        id: '-1',
    };

    token!: string;
    isLoading = false;
    dataLoaded: boolean = false;
    form: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: EditUserPayload,
        private dialogRef: MatDialogRef<EditUserComponent>,
        public usersService: UsersService,
        public directiiService: DirectiiService,
        public compartimenteService: CompartimenteService,
        public functiiService: FunctiiService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        console.log(this.data, 'here is my data?')
        this.processToken();
    }

    ngOnInit(): void {
        this.loadUserTypes();
        this.loadDirectiiActive();
        // this.loadCompartimenteActive();
        this.loadFunctiiActive();
        this.getUserDetails();
    }

    getUserDetails() {
        this.isLoading = true;
        this.usersService.getUsersById(this.data.id_user).pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
            .subscribe((res: any) => {
                if (res && res.data) {
                    this.loadFormWithData(res.data);
                } else {
                    // Handle error or unexpected response format
                }
            });
    }

    loadFormWithData(data: any) {
        // how can i load the from on page if this function/form group renders after i receive success on page

        this.data = data // regroup data variable on component
        this.form = new FormGroup(
            {
                name: new FormControl(this.data.name, [Validators.required, completeNameValidator()]),
                email: new FormControl(this.data.email, [Validators.required]),
                username: new FormControl(this.data.username, [Validators.required]),
                user_type_id: new FormControl(this.data.user_type_id, [Validators.required]),
                functie_id: new FormControl(this.data.functie_id, [Validators.required]),
                notificari: new FormControl(this.data.notificari, [Validators.required]),
                telefon_work: new FormControl(this.data.telefon_work, []),
                id_sesizare_departament: new FormControl(this.data.id_sesizare_departament, [Validators.required,]),
                id_sesizare_compartiment: new FormControl(this.data.id_sesizare_compartiment, []),
                slug: new FormControl(this.data.slug, []),
            },
            { validators: atLeastOneFieldValidator('email', 'username') }
        );
        this.dataLoaded = true
    }

    loadUserTypes() {
        this.isLoading = true;
        this.userTypes$ = this.usersService
            .getUserTypeActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    loadDirectiiActive() {
        this.isLoading = true;
        this.directiiActive$ = this.directiiService
            .getDirectiiActive()
            .pipe(finalize(() => {
                this.isLoading = false
                this.loadCompartimenteActive(this.data.id_sesizare_departament);
            }))
    }

    onDirectieChange(directieId: number): void {
        if (directieId) {
            this.loadCompartimenteActive(directieId);
        } else {
            this.compartimenteActive$ = new Observable<Generic[]>((observer) => {
                observer.next([{ name: '--Alegeți--', id: -1 }]);
                observer.complete();
            });

            console.log(this.compartimenteActive$, 'check here pls')
        }
    }

    loadCompartimenteActive(directieId?: number) {
        this.isLoading = true;
        let compartimenteObservable$ = directieId
            ? this.compartimenteService.getCompartimenteByDirectieId(directieId)
            : this.compartimenteService.getCompartimenteActive();

        this.compartimenteActive$ = compartimenteObservable$.pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => {
                this.isLoading = false;
            })
        );
    }

    loadFunctiiActive() {
        this.isLoading = true;
        this.functiiActive$ = this.functiiService
            .getFunctiiActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.usersService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const {
            name,
            username,
            email,
            user_type_id,
            functie_id,
            notificari,
            telefon_work,
            public_view,
            id_sesizare_compartiment,
            id_sesizare_departament,
            slug,
        } = this.form.value as User;

        const updatedUser: EditUserPayload = {
            id_user: this.data.id_user,
            name,
            username,
            email,
            user_type_id,
            functie_id,
            notificari,
            telefon_work,
            public_view,
            id_sesizare_compartiment,
            id_sesizare_departament,
            slug,
        };

        this.usersService
            .updateUser(updatedUser)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let msg;
            try {
                // try to catch the message from server
                msg = res.error.errors.message[0];
            } catch (error) {
                // controll output message
                msg = helper.dialogConfig.generalMessages.error;
            }
            this.notificationService.warningSwal(
                this.errorTitle,
                msg,
                this.errorIcon
            );
        }
    }
}
