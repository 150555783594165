<div class="edit-container">
    <h1 class="edit-title">Actualizare utilizator</h1>
    <form class="edit-card__form" [formGroup]="form" (submit)="submitForm()" *ngIf="dataLoaded == true">
        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Nume complet</mat-label>
                    <input type="text" matInput formControlName="name" placeholder="(ex: Nume prenume)" />
                    <mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
                        Numele trebuie sa fie de forma "Nume prenume"
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Slug(Instituție/Departament/Birou/Serviciu)</mat-label>
                    <input type="text" matInput formControlName="slug" />
                    <mat-error *ngIf="
                !form.controls['slug'].valid &&
                form.controls['slug'].touched
              ">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Username</mat-label>
                    <input type="text" matInput formControlName="username" />
                    <mat-error *ngIf="
                !form.controls['username'].valid &&
                form.controls['username'].touched
              ">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="text" matInput formControlName="email" />
                    <mat-error *ngIf="
                !form.controls['email'].valid &&
                form.controls['email'].touched
              ">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Tip Utilizator</mat-label>
                    <mat-select formControlName="user_type_id">
                        <mat-option *ngFor="let userType of userTypes$ | async" [value]="userType.id">
                            {{ userType.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Număr de telefon</mat-label>
                    <input type="text" matInput formControlName="telefon_work" />
                    <mat-error *ngIf="
                !form.controls['telefon_work'].valid &&
                form.controls['telefon_work'].touched
              ">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Notificări Email</mat-label>
                    <mat-select formControlName="notificari">
                        <mat-option [value]='1'>Da</mat-option>
                        <mat-option [value]='0'>Nu</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                !form.controls['notificari'].valid &&
                form.controls['notificari'].touched
              ">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Direcție/Instituție</mat-label>
                    <mat-select formControlName="id_sesizare_departament"
                        (selectionChange)="onDirectieChange($event.value)">
                        <mat-option *ngFor="let directie of directiiActive$ | async" [value]="directie.id">
                            {{ directie.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Compartiment/Birou/Serviciu</mat-label>
                    <mat-select formControlName="id_sesizare_compartiment">
                        <mat-option *ngFor="let compartiment of compartimenteActive$ | async" [value]="compartiment.id">
                            {{ compartiment.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Funcție</mat-label>
                    <mat-select formControlName="functie_id">
                        <mat-option *ngFor="let functie of functiiActive$ | async" [value]="functie.id">
                            {{ functie.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="row">
            <div class="col d-flex justify-content-between">
                <button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
                    {{ helper.dialogConfig.buttonLabels.close }}
                </button>
                <button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
                    {{ helper.dialogConfig.buttonLabels.save }}
                </button>
            </div>
        </div>
    </form>
    <div class="section-spinner position-absolute" *ngIf="isLoading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
        </div>
    </div>
</div>