import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client } from '../client.interface';
import { InfoClientSidebar } from '../../../../../utils/enum/info-client-sidebar';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-info-client',
    templateUrl: './info-client.component.html',
    styleUrls: ['./info-client.component.scss'],
})
export class InfoClientComponent {

    readonly InfoClientSidebar = InfoClientSidebar;
    selectedComponent: InfoClientSidebar = InfoClientSidebar.GENERAL as InfoClientSidebar;

    filteredComponents: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Client,
        private dialogRef: MatDialogRef<InfoClientComponent>,
        private permissionsService: PermissionsService
    ) {
        this.filterComponents();
    }

    filterComponents() {
        this.filteredComponents = [...this.componentKeys]

        if (!this.permissionsService.hasPermission('clients.contacts.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== InfoClientSidebar.CONTACTS);
        }

        if (!this.permissionsService.hasPermission('clients.operators.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== InfoClientSidebar.OPERATORS);
        }

        if (!this.permissionsService.hasPermission('clients.permissions.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== InfoClientSidebar.PERMISSIONS);
        }
    }

    componentMapping: Record<InfoClientSidebar, string> = {
        [InfoClientSidebar.GENERAL]: 'app-client-general-info',
        [InfoClientSidebar.CONTACTS]: 'app-client-contacts',
        [InfoClientSidebar.OPERATORS]: 'app-client-operators',
        [InfoClientSidebar.PERMISSIONS]: 'app-client-permissions'
    };

    componentKeys: string[] = Object.keys(this.componentMapping);

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    onChange($event: { value: any }) {
        this.selectedComponent = $event.value;
    }
}
