import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { environment } from 'src/environments/environment';
import { CentralizatorService } from './centralizator.service';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { helper } from 'src/environments/helper';
import { PermissionsService } from 'src/app/services/permissions.service';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-centralizator',
    templateUrl: './centralizator.component.html',
    styleUrls: ['./centralizator.component.scss']
})
export class CentralizatorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    isFilterLoading = false;
    showCard = false;
    isPublic!: boolean;

    searchParamsForm!: FormGroup;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.centralizator.getCentralizatorDt;

    defaultParameter: { name: string; id: string } = {
        name: '-- Alegeți --',
        id: '-1',
    };

    initialFormValues = {
        siruta: '',
        judet: '-1',
    };

    buildSearchParamsForm() {
        this.searchParamsForm = new FormGroup({
            siruta: new FormControl(''),
            judet: new FormControl('-1'),
        });
    }

    resetForm() {

        this.searchParamsForm.reset(this.initialFormValues);

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }

    constructor(
        private http: HttpClient,
        private cetralizatorService: CentralizatorService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
        this.buildSearchParamsForm();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            order: [[0, 'desc']],
            serverSide: true,
            searching: false,
            responsive: true,
            pageLength: 25,

            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.filters = this.searchParamsForm.value;

                this.isLoading = true;
                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });

                        this.isPublic = resp.data.display_public;
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'index',
                    render: (data: any, type: any, row: any) => {
                        return `
                                <div style="display: flex; align-items: center; gap: 5px;">
                                    <span>${row.reg_number ? row.reg_number : 'N/A'}</span>
                                    <span>/</span>
                                    <span>${row.index}</span>
                                    <span>/</span>
                                    <span>${this.formatDate(row.created_at)}</span>
                                </div>
                            `;
                    },
                },
                {
                    title: 'Tip solicitare',
                    data: 'subject',
                    sortable: false,
                    // render: (data: any, type: any, row: any) => {
                    //     const imageUrl = '/assets/icons/icon-addcard.svg';
                    //     const imageTreeUrl = '/assets/icons/icon-tree.svg';
                    //     return `
                    // 			<span><strong>${row.subject}</strong></span><br/><br/>
                    // 			<span style="color:#bdbdbd; font-size:12px"> Categorie / Tip</span><br/>
                    // 			<span>
                    // 			<img src="${imageUrl}" alt="Image" style="width: 20px; height: 20px;">
                    // 			${row.categorie_directie} <br/>
                    // 			<img src="${imageTreeUrl}" alt="Image" style="width: 20px; height: 20px;">
                    // 			${row.categorie_compartiment}</span><br/><br/>
                    // 		`;
                    // },
                },
                {
                    title: 'Identificare',
                    data: 'name',
                    sortable: false,
                    render: (data: any, type: any, row: any) => {
                        return `
                            <div class="d-flex flex-column gap-2">
                                <div>
                                    <span style="color:#bdbdbd; font-size:12px">Tip beneficiar</span><br/>
                                    ${data}
                                </div>
                            </div>
                        `
                    },
                },
            ],
        };
    }

    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    formatDate(input: string): string {
        const [datePart, timePart] = input.split(' ');
        const [year, month, day] = datePart.split('-');

        return `${day}.${month}.${year}`;
    }

    changePublic() {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul centralizatorului în cadrul portalului.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.cetralizatorService.changeStatusPublic()
                    .subscribe({
                        next: () => {
                            Swal.fire({
                                title: helper.dialogConfig.headers.success,
                                text: 'Status centralizator actualizat cu succes!',
                                icon: 'success',
                                confirmButtonText: helper.dialogConfig.buttonLabels.close,
                                allowOutsideClick: false,
                            }).then(() => {
                                this.rerenderDT();
                            });
                        },
                        error: (err) => {
                            let msg;
                            try {
                                // try to catch the message from server
                                msg = err.error.errors.message[0];
                            } catch (error) {
                                // controll output message
                                msg = helper.dialogConfig.generalMessages.error;
                            }

                            Swal.fire({
                                title: helper.dialogConfig.headers.errorAtentie,
                                text: msg,
                                icon: 'success',
                                confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                                showCancelButton: true,
                                cancelButtonText: helper.dialogConfig.buttonLabels.close,
                                reverseButtons: true,
                                allowOutsideClick: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.changePublic();
                                }
                            });
                        }
                    })
            }
        });
    }
}
