import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JudeteComponent } from './judete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { AddJudetComponent } from './add-judet/add-judet.component';
import { EditJudetComponent } from './edit-judet/edit-judet.component';

@NgModule({
  declarations: [JudeteComponent, AddJudetComponent, EditJudetComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [JudeteComponent],
})
export class JudeteModule {}
