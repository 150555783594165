<div class="settings-page">
    <div class="settings-page__content">
        <div class="settings-card">
            <div class="settings-card__header">
                <img src="/assets/images/Group 24.svg" alt="" />
                <div class="settings-card__header-headline">
                    <h2>Bine ați venit</h2>
                    <h4>Administrativ - Setări - Portal Operator {{env.config.appName}} </h4>
                </div>
            </div>
        </div>
    </div>
	<div class="content-box">
        <div class="settings-page-container">
            <mat-button-toggle-group [value]="selectedComponent" (change)="onChange($event)"
                class="settings-page_menu">
                <mat-button-toggle [value]="option" class="settings-page_menu-item"
                    *ngFor="let option of filteredComponents">
                    {{ option }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="settings-page__content">
            <ng-container *ngIf="selectedComponent === AdminSettingsSidebar.CONFIGURARI">
                <app-config-admin></app-config-admin>
            </ng-container>
            <ng-container *ngIf="selectedComponent === AdminSettingsSidebar.TYPE_IMAGE">
                <app-type-image-admin></app-type-image-admin>
            </ng-container>

            <ng-container *ngIf="selectedComponent === AdminSettingsSidebar.TYPE_USER">
                <app-user-type></app-user-type>
            </ng-container>
            <ng-container *ngIf="selectedComponent === AdminSettingsSidebar.CRONS">
                <app-crons></app-crons>
            </ng-container>
        </div>
	</div>
</div>