import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
	IDirectieAddPayload,
	IDirectieUpdatePayload,
} from './directii.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DirectiiService {
	private token!: string;
	private httpOptions: any;

	private basePath = environment.interop.basePath;
	private getDirectiiURL =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie.getDirectii;
	private getDirectiiDTUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie.getDirectiiDT;
	private storeDirectieUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie.storeDirectie;
	private updateDirectieUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie.putDirectie;
	private deleteDirectieUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie.deleteDirectie;
	private getDirectiiActiveUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie.getDirectiiActive;
	private getDirectiiPublicUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie.getDirectiiActivePublic;

	private changeStatusDirectieActiveUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.directie
			.changeStatusDirectieActive;

	constructor(private http: HttpClient) { }

	getDirectiiDT() {
		return this.http.get(this.getDirectiiDTUrl, this.httpOptions);
	}

	addDirectie(data: IDirectieAddPayload) {
		return this.http.post(this.storeDirectieUrl, data, this.httpOptions);
	}

	updateDirectie(data: IDirectieUpdatePayload) {
		return this.http.put(this.updateDirectieUrl, data, this.httpOptions);
	}

	deleteDirectie(DirectieId: number) {
		return this.http.delete(
			this.deleteDirectieUrl + DirectieId,
			this.httpOptions
		);
	}

	getDirectiiActive() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getDirectiiActiveUrl
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({
						id: item.id_directie,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get directii active:', error);
					return throwError(error);
				})
			);
	}

	getDirectii() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getDirectiiActiveUrl
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({
						id: item.id_directie,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get directii active:', error);
					return throwError(error);
				})
			);
	}

	getDirectiiPublic() {
		return this.http.get(this.getDirectiiPublicUrl, this.httpOptions);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}

	changeStatusDirectieActive(id: number) {
		return this.http.put(
			this.changeStatusDirectieActiveUrl + id,
			{},
			this.httpOptions
		);
	}
}
