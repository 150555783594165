<div class="rapoarte-page">
    <div class="content-box">
        <div class="content">
            <div class="header">
                <div class="header-title title">Live Report</div>
                <div class="d-flex flex-column gap-2 align-items-end">
                    <button class="btn" [ngClass]="{'btn-danger': isAutoRefreshEnabled, 'btn-success': !isAutoRefreshEnabled}" (click)="toggleAutoRefresh()">
                        <span class="d-flex align-items-center gap-2" *ngIf="!isAutoRefreshEnabled" style="color: #ffffff;"><i class="fa-solid fa-play"></i>START</span>
                        <span class="d-flex align-items-center gap-2" *ngIf="isAutoRefreshEnabled" style="color: #ffffff;"><i class="fa-solid fa-xmark"></i>STOP</span>
                    </button>
                    <span class="refreshText" *ngIf="isAutoRefreshEnabled">Următoarea actualizare în: {{formattedRemainingTime}}</span>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="content-card">
                <div class="general-title title">Proiecte depuse în funcție de beneficiar</div>
                <div class="row">
                    <div *ngFor="let key of beneficiariesKeys(); let i = index" class="col-border col-xl-4 col-sm-6 d-flex flex-column justify-content-start align-items-center text-center">
                        <div class="pie animate" [ngStyle]="{'--p': calculateGeneralPercentage(beneficiaries[key]), '--c': colors6[i]}">{{ calculateGeneralPercentage(beneficiaries[key]) | number: '1.2-2' }}%</div>
                        <div class="d-flex flex-column gap-1 mt-2">
                            <span class="label-text">{{ getLabel(key) }}</span>
                            <span class="recenzii-text">{{beneficiaries[key]}} solicitări</span>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="content-card">
                <div class="general-title title">Număr solicitări depuse (totale)</div>
                <div class="row">
                    <div *ngFor="let key of generalKeys(); let i = index" class="col-md-border col-xl-3 col-sm-6 d-flex flex-column justify-content-start align-items-center text-center">
                        <div class="pie animate" [ngStyle]="{'--p': calculateGeneralPercentage(general[key]), '--c': colors4[i]}">{{ calculateGeneralPercentage(general[key]) | number: '1.2-2' }}%</div>
                        <div class="d-flex flex-column gap-1 mt-2">
                            <span class="label-text">{{ getLabel(key) }}</span>
                            <span class="recenzii-text">{{general[key]}} solicitări</span>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="content-card">
                <div class="general-title title">Număr solicitări depuse (pe regiuni)</div>
                <div *ngFor="let region of regionKeys()" class="row mb-5">
                    <div class="subtitle">Număr solicitări depuse - {{region}}</div>
                    <div class="row">
                        <div *ngFor="let subKey of regionSubKeys(region); let i = index" class="col-md-border col-xl-3 col-sm-6 d-flex flex-column justify-content-start align-items-center text-center">
                            <div class="pie animate" [ngStyle]="{'--p': calculateRegionData(region, subKey, i), '--c': colors4[i]}">
                                {{ calculateRegionData(region, subKey, i) | number: '1.2-2' }}%
                            </div>
                            <div class="d-flex flex-column gap-1 mt-2">
                                <span class="label-text">{{ getLabel(subKey) }}</span>
                                <span class="recenzii-text">{{regions[region][subKey]}} solicitări</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="section-spinner position-absolute" *ngIf="isLoading">
                <div class="spinner position-absolute top-50 start-50 translate-middle">
                    <mat-spinner></mat-spinner>
                    <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
