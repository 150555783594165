<div>
  <div class="section-top">
    <div class="section-top-actions d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-sm btn-info pull-right ms-1"
        (click)="rerenderDT(false)"
      >
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
    </div>
  </div>
    <div class="table-container">
      <table
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="row-border hover w-100"
      ></table>
    </div>
    <!-- TO DO Check if this it needed. Commented it because of the busy component rendering multiple loadin spinners-->
    <!-- <div class="section-spinner position-absolute" *ngIf="isLoading">
      <div class="spinner position-absolute top-50 start-50 translate-middle">
        <mat-spinner></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
      </div>
    </div> -->
  </div>
  