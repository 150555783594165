<div class="edit-container">
    <h1 class="edit-title">Închidere Lucrare - Document</h1>
    <form class="edit-card__form" [formGroup]="form" (submit)="submitForm()">
        <div class="row">
			<div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Tip rezoluție</mat-label>
                    <mat-select formControlName="finalizare_type">
                        <mat-option *ngFor="let val of tipuriRezolutii" value="{{val.id}}">
                            {{val.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        !form.controls['finalizare_type'].valid &&
                        form.controls['finalizare_type'].touched
                    ">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
			<div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Observații Închidere Lucrare</mat-label>
                    <textarea matInput formControlName="observatii"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					Închide
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					Actualizează
				</button>
			</div>
		</div>
    </form>

    <div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>