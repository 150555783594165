<div class="add-container">
	<h1 class="add-title">Adaugă contact</h1>

	<div class="row form-selection-container" *ngIf="!selectedForm">
		<button class="buttons" type="button" mat-flat-button color="primary" (click)="toggleForm('nou')">
			Adăugare contact nou
		</button>

		<button class="buttons " type="button" mat-flat-button color="primary" (click)="toggleForm('existent')">
			Adăugare utilizator existent
		</button>
	</div>

	<form *ngIf="selectedForm === 'nou'" class="add-card__form" [formGroup]="form" (submit)="submitForm()">

		<div class="row">
			<div class="col add-field">
				<mat-form-field appearance="fill">
					<mat-label>Nume</mat-label>
					<input type="text" matInput formControlName="last_name" />
					<mat-error *ngIf="!form.controls['last_name'].valid && form.controls['last_name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col add-field">
				<mat-form-field appearance="fill">
					<mat-label>Prenume</mat-label>
					<input type="text" matInput formControlName="first_name" />
					<mat-error *ngIf="!form.controls['first_name'].valid && form.controls['first_name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col add-field">
				<mat-form-field appearance="fill">
					<mat-label>Email</mat-label>
					<input type="text" matInput formControlName="email" />
					<mat-error *ngIf="!form.controls['email'].valid && form.controls['email'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col add-field">
				<mat-form-field appearance="fill">
					<mat-label>Număr de telefon</mat-label>
					<input type="tel" matInput formControlName="phone" placeholder="074-123-1234" />
					<mat-error *ngIf="!form.controls['phone'].valid && form.controls['phone'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col add-field">
				<mat-form-field appearance="fill">
					<mat-label>Funcție</mat-label>
					<mat-select formControlName="functie_id">
						<mat-option *ngFor="let functie of functiiActive$ | async" [value]="functie.id">
							{{ functie.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col add-field">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Observații</mat-label>
					<textarea rows="3" matInput formControlName="observations"></textarea>
				</mat-form-field>
			</div>
		</div>

		<div class="add-action-btns">
			<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
				{{ helper.dialogConfig.buttonLabels.close }}
			</button>
			<button class="buttons" type="submit" mat-flat-button color="primary">
				{{ helper.dialogConfig.buttonLabels.save }}
			</button>
			<!--  [disabled]="form.invalid" -->
		</div>
	</form>

	<form *ngIf="selectedForm === 'existent'" class="add-card__form" [formGroup]="formOperator" (submit)="submitForm()">

		<div class="row">
			<div class="col add-field">
				<mat-form-field appearance="fill">
					<mat-label>Utilizator</mat-label>
					<mat-select formControlName="user_id">
						<mat-option *ngFor="let user of usersActive$ | async" [value]="user.id_user">
							{{ user.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="add-action-btns">
			<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
				{{ helper.dialogConfig.buttonLabels.close }}
			</button>
			<button class="buttons" type="submit" mat-flat-button color="primary">
				{{ helper.dialogConfig.buttonLabels.save }}
			</button>
			<!--  [disabled]="form.invalid" -->
		</div>
	</form>

	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>