import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { CerereSeriesService } from '../cerere-series.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { Serie } from '../cerere-series.interface';
import { finalize, first } from 'rxjs';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-add-cerere-series',
    templateUrl: './add-cerere-series.component.html',
    styleUrls: ['./add-cerere-series.component.scss']
})
export class AddCerereSeriesComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        slug: new FormControl('', [Validators.required, noSpacesOrDotsValidator()]),
        first_number: new FormControl(''),
        slug_seq: new FormControl('', [Validators.required, noSpacesOrDotsValidator()]),
    });

    constructor(
        private dialogRef: MatDialogRef<AddCerereSeriesComponent>,
        private cerereSeriesService: CerereSeriesService,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.cerereSeriesService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        if (this.form.get('first_number')?.value === '') {
            this.form.get('first_number')?.patchValue(1);
        }

        const { name, slug, first_number, slug_seq } = this.form
            .value as Serie;

        const newSerie: Serie = {
            name,
            slug,
            first_number,
            slug_seq,
        };

        this.cerereSeriesService
            .addSerie(newSerie)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
