import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { CerereTypeService } from '../cerere-type.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { activeUsersByOrganigramaPayload } from '../../users-settings/user.interface';
import {
    CerereType,
    CerereTypeAddPayload,
    CerereTypeUpdatePayload,
} from '../cerere-type.interface';
import { Observable, Subject, finalize, map } from 'rxjs';
import { DirectiiService } from '../../directii/directii.service';
import { CompartimenteService } from '../../compartimente/compartimente.service';
import { CerereStatusService } from '../../cerere-status/cerere-status.service';
import { CererePriorityService } from '../../cerere-priority/cerere-priority.service';
import { UsersService } from '../../users-settings/users.service';
import { Generic } from 'src/app/interfaces/generic.interface';
import { notDefaultValidator } from 'src/app/shared/validators/not-default';
import { CerereSeriesService } from '../../cerere-series/cerere-series.service';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-edit-cerere-type',
    templateUrl: './edit-cerere-type.component.html',
    styleUrls: ['./edit-cerere-type.component.scss'],
})
export class EditCerereTypeComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl(this.data.name, [Validators.required]),
        slug: new FormControl(this.data.slug, [Validators.required, noSpacesOrDotsValidator()]),
        priority_id: new FormControl(this.data.priority_id, [Validators.required]),
        status_id: new FormControl(this.data.status_id, [Validators.required]),
        user_id: new FormControl(this.data.user_id, []),
        directie_id: new FormControl(this.data.directie_id, [Validators.required]),
        compartiment_id: new FormControl(this.data.compartiment_id, []),
        serie_id: new FormControl(this.data.serie_id, [Validators.required, notDefaultValidator('-1')]),
        observations: new FormControl(this.data.observations),
    });

    directiiActive$!: Observable<Generic[]>;
    compartimenteActive$!: Observable<Generic[]>;
    usersActive$!: Observable<Generic[]>;
    statusesActive$!: Observable<Generic[]>;
    prioritiesActive$!: Observable<Generic[]>;
    series$!: Observable<Generic[]>;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    defaultParameter: { name: string; id: string } = {
        name: '--Alegeți--',
        id: '-1',
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CerereType,
        private dialogRef: MatDialogRef<EditCerereTypeComponent>,
        private cerereTypeService: CerereTypeService,
        public directiiService: DirectiiService,
        public compartimenteService: CompartimenteService,
        public cerereStatusService: CerereStatusService,
        public cererePriorityService: CererePriorityService,
        public usersService: UsersService,
        private notificationService: NotificationService,
        private authService: AuthService,
        private cerereSeriesService: CerereSeriesService,
    ) {
        this.processToken();
    }

    ngOnInit() {
        this.loadDirectiiActive();
        this.loadStatususesActive();
        this.loadPrioritiesActive();
        this.loadSeries();

        if (this.data.compartiment_id) {
            this.loadCompartimenteActive(this.data.directie_id);
        }

        if (this.data.user_id) {
            this.loadUsersActive();
        }
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.cerereTypeService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const {
            name,
            slug,
            priority_id,
            status_id,
            directie_id,
            compartiment_id,
            user_id,
            serie_id,
            observations,
        } = this.form.value as CerereTypeAddPayload;

        const updateCerereType: CerereTypeUpdatePayload = {
            id: this.data.id,
            name,
            slug,
            priority_id,
            status_id,
            user_id,
            directie_id,
            compartiment_id,
            serie_id,
            observations,
        };

        this.cerereTypeService
            .updateRequestType(updateCerereType)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let msg;
            try {
                // try to catch the message from server
                msg = res.error.errors.message[0];
            } catch (error) {
                // controll output message
                msg = helper.dialogConfig.generalMessages.error;
            }
            this.notificationService.warningSwal(
                this.errorTitle,
                msg,
                this.errorIcon
            );
        }
    }

    loadDirectiiActive() {
        this.isLoading = true;
        this.directiiActive$ = this.directiiService
            .getDirectiiActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    onDirectieChange(directieId: number): void {
        if (directieId) {
            this.loadCompartimenteActive(directieId);
            this.loadUsersActive();
        } else {
            this.compartimenteActive$ = new Observable<Generic[]>((observer) => {
                observer.next([{ name: '--Alegeți--', id: -1 }]);
                observer.complete();
            });

            console.log(this.compartimenteActive$, 'check here pls');
        }
    }

    loadCompartimenteActive(directieId?: number) {
        this.isLoading = true;
        let compartimenteObservable$ = directieId
            ? this.compartimenteService.getCompartimenteByDirectieId(directieId)
            : this.compartimenteService.getCompartimenteActive();

        this.compartimenteActive$ = compartimenteObservable$.pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => {
                this.isLoading = false;
            })
        );
    }

    onDirectieCompartimentChange(compartimentId: number): void {
        this.loadUsersActive();
    }

    loadStatususesActive() {
        this.isLoading = true;
        this.statusesActive$ = this.cerereStatusService.getActiveStatus().pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => (this.isLoading = false))
        );
    }

    loadPrioritiesActive() {
        this.isLoading = true;
        this.prioritiesActive$ = this.cererePriorityService
            .getActiveCererePriorities()
            .pipe(
                map((results: any) => [this.defaultParameter, ...results]),
                finalize(() => (this.isLoading = false))
            );
    }

    loadUsersActive() {
        this.isLoading = true;
        this.usersActive$ = this.usersService
            .getActiveUsersByOrganigrama(this.form.value)
            .pipe(
                map((results: any) => [this.defaultParameter, ...results]),
                finalize(() => (this.isLoading = false))
            );
    }

    loadSeries() {
        this.isLoading = true;

        this.series$ = this.cerereSeriesService
            .getActiveSeries()
            .pipe(
                map((res: any) => [this.defaultParameter, ...res]),
                finalize(() => (this.isLoading = false))
            );
    }
}
