<div class="container-fluid">
    <div class="container-card p-3">
        <div class="info-header p-3 d-flex align-items-center gap-2">
            <mat-icon>info</mat-icon>
            <span class="m-0">Info Instituție</span>
        </div>
        <div class="container-fluid">
            <div class="info-row row">
                <label class="col-md-3">Nume:</label>
                <span class="col-md-3">{{client.name}}</span>
            </div>
            <div class="info-row row">
                <label class="col-md-3">Nume afișaj</label>
                <span class="col-md-3">{{client.display_name}}</span>
            </div>
            <div class="info-row row">
                <label class="col-md-3">Observații:</label>
                <span class="col-md-3">{{client.observations}}</span>
            </div>
            <div class="info-row row">
                <label class="col-md-3">Cod culoare:</label>
                <span class="col-md-3">{{client.bg_color}}</span>
            </div>
            <div class="info-row row">
                <label class="col-md-3">Denumire web:</label>
                <span class="col-md-3">{{client.web_name}}</span>
            </div>
        </div>
    </div>
</div>