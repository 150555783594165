<div class="edit-container">
	<h1 class="edit-title">Actualizare contact</h1>
	<form class="edit-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col edit-field">
				<mat-form-field appearance="fill">
					<mat-label>Nume</mat-label>
					<input type="text" matInput formControlName="last_name" />
					<mat-error *ngIf="!form.controls['last_name'].valid && form.controls['last_name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col edit-field">
				<mat-form-field appearance="fill">
					<mat-label>Prenume</mat-label>
					<input type="text" matInput formControlName="first_name" />
					<mat-error *ngIf="!form.controls['first_name'].valid && form.controls['first_name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col edit-field">
				<mat-form-field appearance="fill">
					<mat-label>Email</mat-label>
					<input type="text" matInput formControlName="email" />
					<mat-error *ngIf="!form.controls['email'].valid && form.controls['email'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col edit-field">
				<mat-form-field appearance="fill">
					<mat-label>Număr de telefon</mat-label>
					<input type="tel" matInput formControlName="phone" placeholder="074-123-1234" />
					<mat-error *ngIf="!form.controls['phone'].valid && form.controls['phone'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="edit-field">
				<mat-form-field appearance="fill">
					<mat-label>Funcție</mat-label>
					<mat-select formControlName="functie_id">
						<mat-option *ngFor="let functie of functiiActive$ | async" [value]="functie.id">
							{{ functie.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="edit-field">
				<mat-form-field appearance="fill">
					<mat-label>Observații</mat-label>
					<input type="text" matInput formControlName="observations" />
				</mat-form-field>
			</div>
		</div>


		<div class="edit-action-btns">
			<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
				{{ helper.dialogConfig.buttonLabels.close }}
			</button>
			<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
				{{ helper.dialogConfig.buttonLabels.save }}
			</button>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>