import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CererePriorityComponent } from './cerere-priority.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { AddCererePriorityComponent } from './add-cerere-priority/add-cerere-priority.component';
import { EditCererePriorityComponent } from './edit-cerere-priority/edit-cerere-priority.component';

@NgModule({
  declarations: [CererePriorityComponent, AddCererePriorityComponent, EditCererePriorityComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [CererePriorityComponent],
})
export class CererePriorityModule {}
