import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';

@Injectable({
    providedIn: 'root',
})
export class ClientOperatorsService {
    private httpOptions: any;

    private basePath = environment.interop.basePath;
    private getDTUrl = this.basePath + environment.interop.clients.operators.getClientOperatorsDT;
    private getUsersByClientUrl = this.basePath + environment.interop.clients.operators.getUsersByClient;
    private storeUrl = this.basePath + environment.interop.clients.operators.storeClientOperator;
    private massStoreUrl = this.basePath + environment.interop.clients.operators.massStoreClientOperators;
    private deleteUrl = this.basePath + environment.interop.clients.operators.deleteClientOperator;


    constructor(private http: HttpClient) { }

    getClientOperatorsDT(dataTablesParameters: any) {
        return this.http.post<DataTablesResponse>(this.getDTUrl, dataTablesParameters)
    }

    getUsersByClient(client_id: number, active: number = 1) {
        return this.http.get(`${this.getUsersByClientUrl}${client_id}/${active}`)
    }

    addClientOperator(data: any) {
        return this.http.post(this.storeUrl, data);
    }

    massAddClientOperators(data: any) {
        return this.http.post(this.massStoreUrl, data);
    }

    deleteClientOperator(id: number) {
        return this.http.delete(this.deleteUrl + id);
    }
}
