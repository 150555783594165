export enum SidebarSettings {
    UTILIZATORI = 'Utilizatori',
    INSTITUTII = 'Instituții / Direcții',
    COMPARTIMENTE = 'Departamente / Birouri / Servicii',
    FUNCTII = 'Funcții',
    PLATFORM_SECTION = 'Secțiuni Platformă',
    PLATFORM_FAQ = 'FAQ Platformă',
    PLATFORM_CONTENT = 'Conținut Platformă',
    PLATFORM_ANNOUNCES = 'Anunțuri Platformă',
    JUDETE = 'Județe',
    SOLICITARE_TYPES = 'Tipuri Solicitare',
    SOLICITARE_STATUS = 'Status Solicitare',
    SOLICITARE_PRIORITY = 'Prioritate Solicitare',
    SOLICITARE_SERIES = 'Serii Solicitare',
    TIPURI_DOCUMENTE = 'Tipuri Documente',
    TIPURI_BENEFICIAR = 'Tipuri Beneficiar',
}

export enum AdminSettingsSidebar {
    CONFIGURARI = 'Configurări',
    TYPE_IMAGE = 'Dimensiuni Tip Imagini',
    TYPE_USER = 'Tip Utilizator',
    CRONS = 'Cron-uri',
}
