<div class="pages">
	<div class="pages-top-bar"></div>

	<div class="pages-container">
		<div class="pages-welcome">
			<div class="pages-welcome-container">
				<img src="assets/images/newAppLogo.svg" alt="Primaria Sectorului 4" class="pages-welcome-avatar" />
				<div class="pages-welcome-title">Bine ați venit!</div>
				<div class="pages-welcome-description">
					Autentificați-vă în platforma operator a 
					<span class="pages-welcome-bold">Ministerului Cercetării, Inovării și Digitalizării</span>
				</div>
			</div>
		</div>
		<div class="pages-content">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>