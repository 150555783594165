import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';
import { CronsService } from '../crons.service';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-add-cron',
    templateUrl: './add-cron.component.html',
    styleUrls: ['./add-cron.component.scss'],
})
export class AddCronComponent {
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;

    form: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        path: new FormControl('', Validators.required),
        slug: new FormControl('', [Validators.required, noSpacesOrDotsValidator()]),
        interval: new FormControl('', Validators.required),
    });

    constructor(
        private dialogRef: MatDialogRef<AddCronComponent>,
        private cronsService: CronsService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.cronsService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const crons = {
            name: this.form.get('name')?.value,
            path: this.form.get('path')?.value,
            slug: this.form.get('slug')?.value,
            interval: this.form.get('interval')?.value,
        };
        this.cronsService
            .addCrons(crons)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: () => {
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                },
            });
    }
}
