import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { CerereSabloaneService } from '../cerere-sabloane.service';
import { finalize } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';
import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-add-cerere-sabloane',
  templateUrl: './add-cerere-sabloane.component.html',
  styleUrls: ['./add-cerere-sabloane.component.scss'],
})
export class AddCerereSabloaneComponent implements OnInit {
  // general error
  errorTitle: string = environment.config.customNotifications.headers.error;
  errorIcon: string = environment.config.customNotifications.icons.error;
  errorType: string = environment.config.customNotifications.icons.error;
  helper = helper;

  editorConfig!: AngularEditorConfig;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    content: new FormControl(''),
  });

  constructor(
    private dialogRef: MatDialogRef<AddCerereSabloaneComponent>,
    private cerereSabloaneService: CerereSabloaneService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.cerereSabloaneService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const sablon = {
      name: this.form.get('name')?.value,
      content: this.form.get('content')?.value,
    };

    this.cerereSabloaneService
      .addSablon(sablon)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let errorMessage =
            environment.config.customNotifications.generalMessages.error;
          this.notificationService.warningSwal(
            this.errorTitle,
            errorMessage,
            this.errorIcon
          );
        },
      });
  }

  ngOnInit(): void {
    this.editorConfig = initializeEditor();
  }
}
