import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CentralizatorComponent } from './centralizator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';


@NgModule({
    declarations: [CentralizatorComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    exports: [CentralizatorComponent]
})
export class CentralizatorModule { }
