import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from '../../auth.service';
import {
	IChangePasswordForceUserPayload
} from '../change-password-force.interface';

import { environment } from 'src/environments/environment';

import { helper } from 'src/environments/helper'

@Component({
	selector: 'app-change-password-force',
	templateUrl: './change-password-force.component.html',
	styleUrls: ['./change-password-force.component.scss']
})
export class ChangePasswordForceComponent {
	// general error
	errorTitle: string = helper.dialogConfig.headers.errorAtentie;
	errorIcon: string = helper.dialogConfig.icons.error;
	errorType: string = helper.dialogConfig.icons.error;

	hideTP = true; // temporary password
	hideNP = true; // new password
	hideCP = true; // confirm password

	isLoading = false;
	form: FormGroup

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IChangePasswordForceUserPayload,
		private dialogRef: MatDialogRef<ChangePasswordForceComponent>,
		private notificationService: NotificationService,
		private authService: AuthService
	) {

		this.form = new FormGroup({
			id_user: new FormControl(this.data.id_user, [Validators.required]),
			email: new FormControl(this.data.email, []),
			username: new FormControl(this.data.username, []),
			temporary_password: new FormControl(this.data.temporary_password, [
				Validators.required,
				Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
				Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword)
			]),
			new_password: new FormControl(this.data.new_password, [
				Validators.required,
				Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
				Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword)
			]),
			confirm_new_password: new FormControl(this.data.confirm_new_password, [
				Validators.required,
				Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
				Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword)
			])
		});
		// , { validators: this.checkPasswords });
	}

	closeDialog() {
		this.dialogRef?.close({
			success: false,
		});
	}

	// checkPasswords(group: FormGroup) {
	// 	const password = group.get('new_password').value;
	// 	const confirmPassword = group.get('confirm_new_password').value;
	// 	return password === confirmPassword ? null : { notSame: true };
	//   }

	submitForm() {
		if (this.isLoading) return;

		this.isLoading = true;

		this.authService
			.updatePasswordForce(this.form.value)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe({
				next: () => {
					this.dialogRef.close({ success: true });
				},
				error: (err: any) => {
					let msg
					try {
						// try to catch the message from server
						msg = err.error.errors.message[0]
					} catch (error) {
						// controll output message
						msg = helper.dialogConfig.generalMessages.error
					}
					this.notificationService.warningSwal(
						helper.dialogConfig.headers.errorAtentie,
						msg,
						this.errorIcon
					);
				},
			});
	}

	onHttpError(res: any) {
		if (res.errors && res.errors.message && res.errors.message.length > 0) {
			res.errors.message.forEach((msg: string) => {
				this.notificationService.warningSwal(
					this.errorTitle,
					msg,
					this.errorIcon
				);
			});
		} else {
			let msg
			try {
				// try to catch the message from server
				msg = res.error.errors.message[0]
			} catch (error) {
				// controll output message
				msg = helper.dialogConfig.generalMessages.error
			}
			this.notificationService.warningSwal(
				this.errorTitle,
				msg,
				this.errorIcon
			);
		}
	}
}
