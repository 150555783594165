import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformAnnouncesAddPayload, PlatformAnnouncesUpdatePayload } from './platform-announces.interface';

@Injectable({
	providedIn: 'root'
})
export class PlatformAnnouncesService {
	private basePath = environment.interop.basePath;
	private getPlatformAnnounceDT = this.basePath + environment.interop.platform.nomenclatoare.platformAnnounces.getPlatformAnnouncesDT;
	private token!: string;
	private httpOptions: any;
	private storePlatformAnnounceURL = this.basePath + environment.interop.platform.nomenclatoare.platformAnnounces.storePlatformAnnounce;
	private updateStorePlatformAnnounce = this.basePath + environment.interop.platform.nomenclatoare.platformAnnounces.putPlatformAnnounce;
	private deleteStorePlatformAnnounce = this.basePath + environment.interop.platform.nomenclatoare.platformAnnounces.deletePlatformAnnounce;
	private getPlatformAnnouncesActive = this.basePath + environment.interop.platform.nomenclatoare.platformAnnounces.getPlatformAnnouncesActive;
	private changeStatusPlatformAnnounceActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.platformAnnounces.changeStatusPlatformAnnounceActive;

	constructor(private http: HttpClient) { }

	getPlatformAnnouncesDT() {
		return this.http.get(this.getPlatformAnnounceDT, this.httpOptions);
	}

	addPlatformAnnounce(platformAnnounce: PlatformAnnouncesAddPayload) {
		return this.http.post(this.storePlatformAnnounceURL, platformAnnounce, this.httpOptions);
	}

	updatePlatformAnnounce(platformAnnounce: PlatformAnnouncesUpdatePayload) {
		return this.http.put(this.updateStorePlatformAnnounce, platformAnnounce, this.httpOptions);
	}

	deletePlatformAnnounce(platformAnnounceId: number) {
		return this.http.delete(this.deleteStorePlatformAnnounce + platformAnnounceId);
	}

	getActivePlatformAnnounce() {
		return this.http.get(this.getPlatformAnnouncesActive, this.httpOptions);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}

	changeStatuseActive(id: number) {
		return this.http.put(this.changeStatusPlatformAnnounceActiveUrl + id, {}, this.httpOptions);
	}
}
