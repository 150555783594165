import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordForceComponent } from './change-password-force/change-password-force.component';


const routes: Routes = [
	{
		path: '',
		component: LoginComponent
	}
];

@NgModule({
	declarations: [LoginComponent, ChangePasswordForceComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
	]
})
export class LoginModule { }
