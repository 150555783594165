import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CerereSabloaneComponent } from './cerere-sabloane.component';
import { MaterialModule } from 'src/app/material.module';
import { AddCerereSabloaneComponent } from './add-cerere-sabloane/add-cerere-sabloane.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditCerereSabloaneComponent } from './edit-cerere-sabloane/edit-cerere-sabloane.component';

@NgModule({
  declarations: [
    CerereSabloaneComponent,
    AddCerereSabloaneComponent,
    EditCerereSabloaneComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [CerereSabloaneComponent],
})
export class CerereSabloaneModule {}
