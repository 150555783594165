import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function completeNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value: string = control.value || '';

        if (value.split(/\s+/).filter(item => item !== '').length < 2) {
            return { notEnoughItems: true };
        }

        return null;
    };
}