import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { ClientAddPayload, ClientEditPayload } from '../client.interface';
import { ClientsService } from '../clients.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss'],
})
export class EditClientComponent {
  // general error
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl(this.data.name, [Validators.required]),
    observations: new FormControl(this.data.observations, [
      Validators.required,
    ]),
    logo_small: new FormControl(this.data.logo_small, [Validators.required]),
    logo_large: new FormControl(this.data.logo_large, [Validators.required]),
    bg_color: new FormControl(this.data.bg_color, [Validators.required]),
    display_name: new FormControl(this.data.display_name, [
      Validators.required,
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ClientEditPayload,
    private dialogRef: MatDialogRef<EditClientComponent>,
    private clientService: ClientsService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.clientService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const {
      name,
      observations,
      logo_small,
      logo_large,
      bg_color,
      display_name,
    } = this.form.value as ClientAddPayload;
    const slugg = name
      .split(' ')
      .map((item) => item.toLowerCase())
      .join('_');
    const updatedClient: ClientEditPayload = {
      id_client: this.data.id_client,
      name,
      observations,
      logo_small,
      logo_large,
      bg_color,
      display_name,
    };

    this.clientService
      .updateClient(updatedClient)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            helper.dialogConfig.headers.errorAtentie,
            msg,
            this.errorIcon
          );
        },
      });
  }

  onHttpError(res: any) {
    if (res.errors && res.errors.message && res.errors.message.length > 0) {
      res.errors.message.forEach((msg: string) => {
        this.notificationService.warningSwal(
          this.errorTitle,
          msg,
          this.errorIcon
        );
      });
    } else {
      let msg;
      try {
        // try to catch the message from server
        msg = res.error.errors.message[0];
      } catch (error) {
        // controll output message
        msg = helper.dialogConfig.generalMessages.error;
      }
      this.notificationService.warningSwal(
        this.errorTitle,
        msg,
        this.errorIcon
      );
    }
  }
}
