import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AddPictureComponent } from './add-picture/add-picture/add-picture.component';
import { OpenImageComponent } from './openImage/open-image.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [GalleryComponent, AddPictureComponent, OpenImageComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [GalleryComponent],
})
export class GalleryModule {}
