<mat-card class="login-card">
    <mat-card-header class="login-card__header">
        <mat-card-title class="login-card__title">Autentificare</mat-card-title>
    </mat-card-header>

    <!-- Content -->
    <mat-card-content class="login-card__content">
        <form class="login-card-form" [formGroup]="form" (ngSubmit)="submitForm(form)">
            <div class="login-card-form-top">
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="text" matInput placeholder="Ex. eu@exemplu.ro" formControlName="email" />
                    <mat-hint>Introduceți adresa de email</mat-hint>
                    <mat-error *ngIf="
						!form.controls['email'].valid && form.controls['email'].touched
					">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="login-card-form-top">
                <p class="text-center">
                    <i>sau</i>
                </p>
                <mat-form-field appearance="fill">
                    <mat-label>Username</mat-label>
                    <input type="text" matInput placeholder="Ex. pop____" formControlName="username" />
                    <mat-hint>Introduceți username</mat-hint>
                    <mat-error *ngIf="
						!form.controls['username'].valid && form.controls['username'].touched
					">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Parola</mat-label>
                <input type="text" matInput required [type]="hide ? 'password' : 'text'" placeholder="Ex. kmbsd88$98" formControlName="password"
                />
                <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
                <mat-hint>Introduceți parola</mat-hint>
                <mat-error *ngIf="
              !form.controls['password'].valid &&
              form.controls['password'].touched
            ">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>

            <div class="login-card-form-base">
                <mat-checkbox color="primary" formControlName="saveme">
                    <span class="checkbox-text">Păstrează-mă autentificat</span>
                </mat-checkbox>
            </div>
            <button type="submit" class="login-card-button login-card-button-one" mat-flat-button color="primary" [disabled]="!form.valid || isLoading"
                (click)="submitForm(form)">
                AUTENTIFICAȚI-VĂ
            </button>
        </form>

    </mat-card-content>

    <mat-divider></mat-divider>

    <div class="section-spinner position-absolute" *ngIf="isLoading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">Vă rugăm așteptați!</h3>
        </div>
    </div>
</mat-card>