import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LayoutService } from './layout.service';
import { helper } from '../../../environments/helper'

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogRef!: MatDialogRef<any>;

  constructor(private layoutService: LayoutService) {}

  getDialogConfig(
    title: string = helper.dialogConfig.headers.errorAtentie,
    msg: string = helper.dialogConfig.generalMessages.error
  ) {
    // Determine the screen size dynamically
    const screenSize = this.getScreenSize();

    let minWidth, maxWidth;
    // Adjust minWidth and maxWidth based on the screen size
    switch (screenSize) {
      case 'XS':
        minWidth = '90%'; // Use percentage for very small screens
        maxWidth = '95%';
        break;
      case 'SM':
        minWidth = 340; // Fixed size for small screens
        maxWidth = '80%'; // Allow some flexibility
        break;
      case 'MD':
        minWidth = 500; // Standard size for medium screens
        maxWidth = '75%';
        break;
      case 'LG':
      default:
        minWidth = 500; // Standard size for large screens
        maxWidth = 600; // Max limit for dialog width
        break;
    }

    return {
      minWidth: minWidth,
      maxWidth: maxWidth,
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true,
      data: {
        title,
        msg,
      },
    };
  }

  // Example method to determine the current screen size
  getScreenSize() {
    const width = window.innerWidth;
    if (width < 600) {
      return 'XS';
    } else if (width >= 600 && width < 768) {
      return 'SM';
    } else if (width >= 768 && width < 992) {
      return 'MD';
    } else {
      return 'LG';
    }
  }

  closeDialog() {
    this.dialogRef?.close();
  }
}
