import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EditPlatformSection, PlatformSection } from './platform-section.interface';

@Injectable({
	providedIn: 'root'
})
export class PlatformSectionService {
	private token!: string;
	private httpOptions: any;

	private basePath = environment.interop.basePath;
	private getPlatformSectionsDTURL = this.basePath + environment.interop.platform.nomenclatoare.platformSection.getPlatformSectionsDT;
	private getPlatformSectionsURL = this.basePath + environment.interop.platform.nomenclatoare.platformSection.getPlatformSections;
	private getPlatformSectionURL = this.basePath + environment.interop.platform.nomenclatoare.platformSection.getPlatformSection;
	private getPlatformSectionActiveURL = this.basePath + environment.interop.platform.nomenclatoare.platformSection.getPlatformSectionsActive;

	private updatePlatformSectionURL = this.basePath + environment.interop.platform.nomenclatoare.platformSection.putPlatformSection;
	private storePlatformSectionURL = this.basePath + environment.interop.platform.nomenclatoare.platformSection.storePlatformSection;

	private deletePlatformSectionURL = this.basePath + environment.interop.platform.nomenclatoare.platformSection.deletePlatformSection;

	private changeStatusActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.platformSection.changeStatusPlatformSectionActive;

	constructor(private http: HttpClient) { }

	getPlatformSectionDT() {
		return this.http.get(this.getPlatformSectionsDTURL, this.httpOptions);
	}

	getPlatformSection() {
		return this.http.get(this.getPlatformSectionURL);
	}

	getPlatformSections(): Observable<EditPlatformSection[]> {
		return this.http.get(this.getPlatformSectionsURL, this.httpOptions).pipe(map((res: any) => res.data));
	}

	addPlatformSection(platformSection: PlatformSection) {
		return this.http.post(this.storePlatformSectionURL, platformSection, this.httpOptions);
	}

	updatePlatformSection(platformSection: EditPlatformSection) {
		return this.http.put(this.updatePlatformSectionURL, platformSection, this.httpOptions);
	}

	deletePlatformSection(platformSectionId: number) {
		return this.http.delete(this.deletePlatformSectionURL + platformSectionId);
	}

	getActivePlatformSection() {
		return this.http.get(this.getPlatformSectionActiveURL, this.httpOptions);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}

	changeStatuseActive(id: number) {
		return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
	}
}
