import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CronsInterfaceAdd, CronstInterfaceUpdate } from './crons.interface';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CronsService {
  private token!: string;
  private basePath = environment.interop.basePath;
  private httpOptions: any;

  private getCronsListURL =
    this.basePath + environment.interop.platform.nomenclatoare.crons.cronList;
  private cronsActive =
    this.basePath + environment.interop.platform.nomenclatoare.crons.cronActive;
  private cronsStore =
    this.basePath + environment.interop.platform.nomenclatoare.crons.cronStore;
  private cronsUpdate =
    this.basePath + environment.interop.platform.nomenclatoare.crons.cronUpdate;
  private cronsDelete =
    this.basePath + environment.interop.platform.nomenclatoare.crons.cronDelete;
  private cronChangeStatusActive =
    this.basePath + environment.interop.platform.nomenclatoare.crons.cronActivePut;

  constructor(private http: HttpClient) {}

  getCrons() {
    return this.http.get(this.getCronsListURL, this.httpOptions);
  }

  getCronsActive() {
    return this.http.get(this.cronsActive, this.httpOptions);
  }

  addCrons(data: CronsInterfaceAdd) {
    return this.http.post(this.cronsStore, data, this.httpOptions);
  }

  updateCrons(data: CronstInterfaceUpdate) {
    return this.http.put(this.cronsUpdate, data, this.httpOptions);
  }

  deleteCron(id: number) {
    return this.http.delete(this.cronsDelete + id);
  }

  changeStatusActive(id: number) {
    return this.http.put(
        this.cronChangeStatusActive + id,
        this.httpOptions
    );
  }

  setToken(token: string) {
    this.token = token;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      }),
    };
  }
}
