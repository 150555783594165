import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Serie, SerieEditPayload } from './cerere-series.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CerereSeriesService {
    private token!: string;
    private httpOptions: any;

    private basePath = environment.interop.basePath;

    private getSeriesUrl = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.getCerereSeries;
    private storeSerieUrl = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.storeCerereSerie;
    private deleteSerieUrl = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.deleteCerereSerie;
    private updateSerieUrl = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.putCerereSerie;
    private getSeriesActive = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.getCerereSeriesActive;
    private getSeriesPublic = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.getCerereSeriesActivePublic;
    private changeSerieActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.changeStatusCerereSerieActive;
    private changeSeriePublicUrl = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.changeStatusCerereSeriePublic;
    private updateNrCurentUrl = this.basePath + environment.interop.platform.nomenclatoare.cerereSeries.updateCurrentNumber;

    constructor(private http: HttpClient) { }

    addSerie(data: Serie) {
        return this.http.post(this.storeSerieUrl, data, this.httpOptions);
    }

    getActiveSeries() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(this.getSeriesActive, this.httpOptions)
            .pipe(
                map((response: any) =>
                    response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get active request series:', error);
                    return throwError(error);
                })
            );
    }

    getPublicSeries() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(this.getSeriesPublic, this.httpOptions)
            .pipe(
                map((response: any) =>
                    response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get public request series:', error);
                    return throwError(error);
                })
            );
    }

    updateSerie(updateSerie: SerieEditPayload) {
        return this.http.put(this.updateSerieUrl, updateSerie, this.httpOptions);
    }

    updateNrCurent(id: number, nrCurent: number) {
        return this.http.post(this.updateNrCurentUrl + id, { current_number: nrCurent });
    }

    deleteSerie(id: number) {
        return this.http.delete(this.deleteSerieUrl + id);
    }

    getSeries() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getSeriesUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get request series:', error);
                    return throwError(error);
                })
            );
    }

    changeSerieActive(id: number) {
        return this.http.put(this.changeSerieActiveUrl + id, {}, this.httpOptions);
    }

    changeSeriePublic(id: number) {
        return this.http.put(this.changeSeriePublicUrl + id, {}, this.httpOptions);
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }
}
