import { LayoutComponent } from './layout/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesLayoutComponent } from './layout/pages-layout/pages-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ClientsComponent } from './layout/pages-layout/nomenclatoare/clients/clients.component';
import { GalleryComponent } from './layout/pages-layout/nomenclatoare/gallery/gallery.component';
import { SettingsSidenavComponent } from './layout/pages-layout/nomenclatoare/settings-sidenav/settings-sidenav.component';
import { AdminSettingsComponent } from './layout/pages-layout/nomenclatoare/administration/admin-settings/admin-settings.component';
import { RequestsComponent } from './layout/pages-layout/nomenclatoare/cetatean/requests/requests.component';
import { TokenGuard } from './guards/token.guard';
import { LogoutComponent } from './logout/logout.component';
import { CerereSabloaneComponent } from './layout/pages-layout/nomenclatoare/settings-sidenav/cerere-sabloane/cerere-sabloane.component';
import { PermissionsGuard } from './guards/permissions.guard';
import { UatComponent } from './layout/pages-layout/nomenclatoare/uat/uat.component';
import { RapoarteComponent } from './layout/pages-layout/nomenclatoare/rapoarte/rapoarte.component';
import { CentralizatorComponent } from './layout/pages-layout/nomenclatoare/centralizator/centralizator.component';

const routes: Routes = [
    {
        path: 'pages',
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: 'pages',
        component: PagesLayoutComponent,
    },
    {
        path: '',
        redirectTo: '/requests',
        pathMatch: 'full',
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [TokenGuard, PermissionsGuard],

        children: [
            {
                path: '',
                component: DashboardComponent,
            },
            {
                path: 'settings',
                component: SettingsSidenavComponent,
            },
            {
                path: 'galerie',
                component: GalleryComponent,
            },
            {
                path: 'clienti',
                component: ClientsComponent,
            },
            {
                path: 'admin-settings',
                component: AdminSettingsComponent,
            },
            {
                path: 'requests',
                component: RequestsComponent,
            },
            {
                path: 'sabloane-requests',
                component: CerereSabloaneComponent,
            },
            {
                path: 'uat',
                component: UatComponent,
            },
            {
                path: 'rapoarte',
                component: RapoarteComponent,
            },
            {
                path: 'centralizator',
                component: CentralizatorComponent,
            },
        ],
    },
    {
        path: 'logout',
        component: LogoutComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        useHash: true
    })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
