<div class="add-container">
	<div class="row">
		<div class="col d-flex justify-content-between">
            <div class="p-1">
                <h1 class="mx-1">Șabloane</h1>
            </div>
            <div class="p-1">
                <button type="button" class="action-btn" (click)="closeDialog()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
		</div>
	</div>

    <div *ngFor="let sablon of sabloaneData" class="card m-3">
        <div class="card-header">
            <div class="col d-flex justify-content-between align-items-center">
                <div>
                    {{ sablon.name }}
                </div>
                <div>
                    <button class="btn" (click)="sendSablon(sablon.content)">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" [innerHTML]="sablon.content | safeHtml">
        </div>
    </div>

    <div class="section-spinner position-absolute" *ngIf="isLoading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
        </div>
    </div>
</div>