import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { TipuriBeneficiarService } from '../tipuri-beneficiar.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';
import { TipuriBeneficiarAddPayload, TipuriBeneficiarEditPayload } from '../tipur-beneficiar.interface';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-edit-tipuri-beneficiar',
    templateUrl: './edit-tipuri-beneficiar.component.html',
    styleUrls: ['./edit-tipuri-beneficiar.component.scss']
})
export class EditTipuriBeneficiarComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl(this.data.name, [Validators.required]),
        slug: new FormControl(this.data.slug, [Validators.required, noSpacesOrDotsValidator()]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: TipuriBeneficiarEditPayload,
        private dialogRef: MatDialogRef<EditTipuriBeneficiarComponent>,
        private tipuriBeneficiarService: TipuriBeneficiarService,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.tipuriBeneficiarService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, slug } = this.form
            .value as TipuriBeneficiarAddPayload;

        const updateUat: TipuriBeneficiarEditPayload = {
            id: this.data.id,
            name,
            slug
        };

        this.tipuriBeneficiarService
            .updateTipBeneficiar(updateUat)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
