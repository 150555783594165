import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RapoarteService {
    private basePath = environment.interop.basePath;

    private getRapoarteUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.fetchReports;

    constructor(private http: HttpClient) { }

    getRapoarte() {
        return this.http.get(this.getRapoarteUrl);
    }
}
