import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformFaqComponent } from './platform-faq.component';
import { MaterialModule } from 'src/app/material.module';
import { AddPlatformFaqComponent } from './add-platform-faq/add-platform-faq.component';
import { EditPlatformFaqComponent } from './edit-platform-faq/edit-platform-faq.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PlatformFaqComponent, AddPlatformFaqComponent, EditPlatformFaqComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [PlatformFaqComponent]
})
export class PlatformFaqModule { }
