import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Image } from '../picture';

@Component({
  selector: 'app-open-image',
  templateUrl: './open-image.component.html',
  styleUrls: ['./open-image.component.scss'],
})
export class OpenImageComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Image,
    private dialogRef: MatDialogRef<OpenImageComponent>
  ) {}

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }
}
