import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompartimenteComponent } from './compartimente.component';
import { MaterialModule } from 'src/app/material.module';
import { AddCompartimentComponent } from './add-compartiment/add-compartiment.component';
import { EditCompartimentComponent } from './edit-compartiment/edit-compartiment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CompartimenteComponent,
    AddCompartimentComponent,
    EditCompartimentComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [CompartimenteComponent],
})
export class CompartimenteModule {}
