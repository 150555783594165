<div class="settings-card">
    <div class="settings-card__header">
        <img src="/assets/images/Group 9.svg" alt="" />
        <div class="settings-card__header-headline">
            <h2 class="m-0 p-0">Galerie</h2>
        </div>
    </div>
    <button *ngIf="permissionsService.hasPermission('galerie.add')"
        (click)="openAddDialog()" type="button" class="btn btn-sm btn-white pull-right ms-1">
        <i class="fa-solid fa-plus"></i>
        Adaugă
    </button>
</div>
<div class="image-row">
    <div class="image-container" *ngFor="let image of images; let i = index">
        <img [src]="buildImagePath(image)" [alt]="image.original_filename">
        <div class="overlay">
            <a class="icon download-icon" (click)="openImgDialog(image)">
                <img src="/assets/icons/add.svg" alt="Download"></a>
            <a class="icon delete-icon" (click)="deleteImage(image.id)" 
                *ngIf="permissionsService.hasPermission('galerie.delete')">
                <img src="/assets/icons/delete.svg" alt="Delete">
            </a>
        </div>
    </div> 
</div>
<div class="section-spinner position-absolute" *ngIf="isLoading">
    <div class="spinner position-absolute top-50 start-50 translate-middle">
        <mat-spinner></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>

