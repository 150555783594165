import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { helper } from 'src/environments/helper';
import { Cerere, EditRequestAssigneePayload, EditRequestPriorityPayload, EditRequestStatusPayload, HistoryEntry } from '../../request.interface';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Generic } from 'src/app/interfaces/generic.interface';
import { Subscription, combineLatest, finalize, map } from 'rxjs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { RequestsService } from '../../requests.service';
import { CererePriorityService } from '../../../../settings-sidenav/cerere-priority/cerere-priority.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CerereStatusService } from '../../../../settings-sidenav/cerere-status/cerere-status.service';

@Component({
    selector: 'app-actualizare-cerere',
    templateUrl: './actualizare-cerere.component.html',
    styleUrls: ['./actualizare-cerere.component.scss']
})
export class ActualizareCerereComponent implements OnInit, OnChanges {
    @Input() data!: any;
    @Output() cerereChanged: EventEmitter<Cerere> = new EventEmitter<Cerere>();
    @Output() istoricChanged: EventEmitter<HistoryEntry[]> = new EventEmitter<HistoryEntry[]>();

    istoric!: HistoryEntry[];
    passedRequestId!: number;
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    successTitle: string = helper.dialogConfig.headers.success;
    successIcon: string = helper.dialogConfig.icons.success;
    successType: string = helper.dialogConfig.icons.success;

    editorConfig!: AngularEditorConfig;
    isLoading = false;
    selectedActionToggle = 'status';
    showSignatures: any;

    defaultChoiceParameter: { name: string; id: string } = {
        name: '-- Alegeți --',
        id: '-1',
    };

    compartimenteActive: any[] = [];
    requestPriorities: Generic[] = [];
    requestStatuses: Generic[] = [];
    usersActive: Generic[] = [];

    sendUpdateAssigneeForm!: FormGroup;
    sendUpdateStatusForm!: FormGroup;
    sendUpdatePriorityForm!: FormGroup;

    constructor(
        private requestService: RequestsService,
        private cererePrioritiesService: CererePriorityService,
        private cerereStatusService: CerereStatusService,
        private notificationService: NotificationService,
        private fb: FormBuilder,
        private commonService: CommonService,
        public toastr: ToastrService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.passedRequestId = this.data.cerere.id;
    }

    ngOnInit(): void {
        this.editorConfig = initializeEditor();
        this.loadRequestPriorities();
        this.loadRequestStatuses();

        this.sendUpdateStatusForm = this.fb.group({
            current_status: new FormControl('-1'),
            message: new FormControl(''),
        });

        this.sendUpdatePriorityForm = this.fb.group({
            id_cerere_priority: new FormControl('-1'),
            message: new FormControl(''),
        });

        this.sendUpdateAssigneeForm = this.fb.group({
            directie_id: new FormControl('-1'),
            compartiment_id: new FormControl('-1'),
            user_id: new FormControl('-1'),
            content: new FormControl(''),
        });
    }

    loadRequestPriorities() {
        this.cererePrioritiesService
            .getCererePriorities()
            .pipe(map((results: any) => [this.defaultChoiceParameter, ...results]))
            .subscribe((data) => {
                this.requestPriorities = data;
            });
    }

    loadRequestStatuses() {
        this.cerereStatusService
            .getActiveStatus()
            .pipe(map((results: any) => [this.defaultChoiceParameter, ...results]))
            .subscribe((data) => {
                this.requestStatuses = data;
            });
    }

    onDirectieChange(directieId: number): void {
        this.isLoading = true;
        if (directieId) {
            this.loadCompartimenteActive(directieId);
            this.loadUsersActive();
        } else {
            this.compartimenteActive = [{ name: '--Alegeți--', id: -1 }];
            this.isLoading = false;
        }
    }

    onDirectieCompartimentChange(compartimentId: number): void {
        this.loadUsersActive();
    }

    loadCompartimenteActive(directieId?: number) {
        this.isLoading = true;
        let compartimenteObservable$ = directieId
            ? this.commonService.getCompartimenteByDirectieId(directieId)
            : this.commonService.getCompartimenteActive();

        compartimenteObservable$
            .pipe(map((results: any) => [this.defaultChoiceParameter, ...results]))
            .subscribe((data) => {
                this.compartimenteActive = data;
                this.isLoading = false;
            });
    }

    loadUsersActive() {
        this.isLoading = true;
        this.commonService
            .getUsersByOrganigrama(this.sendUpdateAssigneeForm.value)
            .pipe(map((results: any) => [this.defaultChoiceParameter, ...results]))
            .subscribe((data) => {
                this.usersActive = data;
                this.isLoading = false;
            });
    }

    onActionChange(event: MatButtonToggleChange) {
        this.selectedActionToggle = event.value;
    }

    submitUpdateStatusMessage() {
        this.isLoading = true;

        const formValues = this.sendUpdateStatusForm.value;

        const newRequestStatus: EditRequestStatusPayload = {
            ...formValues,
            id_cerere: this.data.cerere!.id,
        };

        this.requestService
            .updateRequestStatus(newRequestStatus)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.isLoading = true;
                    this.notificationService.warningSwal(
                        this.successTitle,
                        'Cerere actualizată cu succes!',
                        this.successIcon
                    );
                    // refresh data please
                    this.getRequestInfo();

                    this.sendUpdateStatusForm.patchValue(
                        {
                            current_status: '-1',
                            message: '',
                        }
                    );
                },
                error: (err) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    submitUpdatePriorityMessage() {
        this.isLoading = true;

        const formValues = this.sendUpdatePriorityForm.value;

        const newRequestPriority: EditRequestPriorityPayload = {
            ...formValues,
            id_cerere: this.data.cerere!.id,
        };

        this.requestService
            .updateRequestPriority(newRequestPriority)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.isLoading = true;
                    this.notificationService.warningSwal(
                        this.successTitle,
                        'Cerere actualizată cu succes!',
                        this.successIcon
                    );
                    // refresh data please
                    this.getRequestInfo();
                    this.sendUpdatePriorityForm.patchValue({
                        id_cerere_priority: '-1',
                        message: '',
                    });
                },
                error: (err) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    submitUpdateAssigneeMessage() {
        this.isLoading = true;

        const formValues = this.sendUpdateAssigneeForm.value;

        const newRequestAssignee: EditRequestAssigneePayload = {
            ...formValues,
            cerere_id: this.data.cerere!.id,
        };

        this.requestService
            .updateRequestAssignee(newRequestAssignee)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.isLoading = true;
                    this.notificationService.warningSwal(
                        this.successTitle,
                        'Cerere actualizată cu succes!',
                        this.successIcon
                    );
                    // refresh data please
                    this.getRequestInfo();
                    this.sendUpdateAssigneeForm.patchValue({
                        directie_id: '-1',
                        compartiment_id: '-1',
                        user_id: '-1',
                        message: '',
                    });
                },
                error: (err) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    getRequestInfo() {
        this.isLoading = true;

        const infoDocList = this.requestService.getInfoDocList(this.passedRequestId)
            .pipe(map((response: any) => response.data));
        const history = this.requestService.getHistoryById(this.passedRequestId)
            .pipe(map((response: any) => response.data));

        combineLatest([infoDocList, history]).subscribe(
            ([requestData1, requestData2]) => {
                this.isLoading = false;
                this.data.cerere = requestData1;

                // History
                this.istoric = requestData2;
                this.cerereChanged.emit(this.data.cerere);
                this.istoricChanged.emit(this.istoric);
            },
            error => {
                console.error('Error', error);
            })
    }
}
