<div class="actiuni-card-container action-card-{{actionType}}" [class.selected]="selectedClass()">
    <div class="card-status">
        <div class="card-header">
            <span class="title">{{ title }}</span>
            <span class="description" *ngIf="
				(actionType == 'validate' && cerere?.validated_date == null) 
				|| (!['validate'].includes(actionType) && actionText !== 'finalizare' && cerere?.cancel_date == null)
				|| (actionType == 'finalizare' && cerere?.finalize_date == null)
			">{{ description }}</span>
        </div>
    </div>
    <div class="card-content" *ngIf="actionType == 'validate' && cerere?.validated_date !== null">
        <div class="card-content__header">
            <span class="user"><i class="fa-regular fa-user"></i> {{ cerere?.validated_user_name }}</span>
            <span class="date"><i class="fa-regular fa-calendar-check"></i> {{ cerere?.validated_date | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
        </div>
    </div>
    <div class="card-content" *ngIf="['invalidate', 'anulare'].includes(actionType) && cerere?.cancel_date !== null">
        <div class="card-content__header">
            <span class="user"><i class="fa-regular fa-user"></i> {{ cerere?.cancel_user_name }}</span>
            <span class="date"><i class="fa-regular fa-calendar-check"></i> {{ cerere?.cancel_date | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
        </div>
        <div class="card-content__body">
            <div class="card-content__body__text">
                <i class="fa-regular fa-message"></i> {{ cerere?.cancel_obs }}
            </div>
        </div>
    </div>
    <div class="card-content" *ngIf="['finalizare'].includes(actionType) && cerere?.finalize_date !== null">
		<div class="card-content__header">
			<span class="user"><i class="fa-regular fa-user"></i> {{ cerere?.finalize_user_name }}</span>
			<span class="date"><i class="fa-regular fa-calendar-check"></i> {{ cerere?.finalize_date | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
			<span class="date"><i class="fa-regular fa-comments"></i> {{ cerere?.finalize_name }}</span>
		</div>
		<div *ngIf="cerere?.finalize_obs !== null" class="card-content__body">
			<div class="card-content__body__text">
				<i class="fa-regular fa-message"></i> {{ cerere?.finalize_obs  }}
			</div>
		</div>
	</div>
    <div class="card-actions">
        <div class="card-image">
            <mat-icon [ngStyle]="{'color': iconColor}">{{ iconName }}</mat-icon>
        </div>
        <div class="card-button">
            <button
                *ngIf="
                    (actionType == 'validate' && (permissionsService.hasPermission('solicitari.view.validare-cerere'))) 
                    || (actionType == 'invalidate' && (permissionsService.hasPermission('solicitari.view.invalidare-cerere')))
                    || (actionType == 'anulare' && (permissionsService.hasPermission('solicitari.view.clasare-cerere')))
                    || (actionType == 'finalizare' && (permissionsService.hasPermission('solicitari.view.inchidere-lucrare')))
                "
                type="button" mat-raised-button color={{btnColor}} class="action-button" [disabled]="
                    (actionType == 'validate' && cerere?.validated_date !== null) 
                    || (actionType == 'invalidate' && cerere?.cancel_date !== null)
                    || (actionType == 'anulare' && cerere?.cancel_date !== null)
                    || (actionType == 'finalizare' && cerere?.finalize_date !== null) 
				" (click)="onButtonClick()">{{ actionText }}</button>
        </div>
    </div>
	<div class="card-footer"  *ngIf="
        (actionType == 'validate' && permissionsService.hasPermission('solicitari.view.resetare-validare') && cerere?.validated_date !== null && cerere?.cancel_type == null && cerere?.finalize_date == null) 
        || (actionType == 'invalidate' && permissionsService.hasPermission('solicitari.view.resetare-invalidare') && cerere?.cancel_date !== null && cerere?.finalize_date == null)
        || (actionType == 'anulare' && permissionsService.hasPermission('solicitari.view.resetare-clasare') && cerere?.cancel_date !== null && cerere?.finalize_date == null)
        || (actionType == 'finalizare' && permissionsService.hasPermission('solicitari.view.resetare-finalizare') && cerere?.finalize_date !== null)
    ">
		<div class="card-button">
			<button type="button" mat-raised-button color={{btnColor}} class="action-button w-100"  
			(click)="onButtonResetClick()"><mat-icon class="resetIcon" style="color: 'red'">report_problem</mat-icon> Reset</button>
		</div>
	</div>
</div>