import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PlatformContentService } from '../platform-content.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize, of, Observable } from 'rxjs';

import { PlatformSectionService } from '../../platform-section/platform-section.service';
import { EditPlatformSection } from '../../platform-section/platform-section.interface';

import { helper } from 'src/environments/helper';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';
@Component({
  selector: 'app-add-platform-content',
  templateUrl: './add-platform-content.component.html',
  styleUrls: ['./add-platform-content.component.scss'],
})
export class AddPlatformContentComponent implements OnInit {
  // general error
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;
  editorConfig!: AngularEditorConfig;

  token!: string;
  isLoading = false;
  isSectionsLoading = true;
  sections$!: Observable<EditPlatformSection[]>;

  form: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    slug: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
    observations: new FormControl(''),
    section_id: new FormControl('', Validators.required),
  });

  constructor(
    private dialogRef: MatDialogRef<AddPlatformContentComponent>,
    private platformContentService: PlatformContentService,
    private notificationService: NotificationService,
    private platformSectionService: PlatformSectionService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  ngOnInit(): void {
    this.getSectionsList();
    this.editorConfig = initializeEditor();
  }

  getSectionsList() {
    let platformResponse = this.platformSectionService.getPlatformSections();

    platformResponse.subscribe({
      next: (sections: any) => {
        this.sections$ = of(sections as EditPlatformSection[]);
        this.isSectionsLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isSectionsLoading = false;
      },
    });
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.platformContentService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const platformContent = {
      title: this.form.get('title')?.value,
      slug: this.form.get('slug')?.value,
      content: this.form.get('content')?.value,
      observations: this.form.get('observations')?.value,
      section_id: this.form.get('section_id')?.value,
    };
    this.platformContentService
      .addPlatformContent(platformContent)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        },
      });
  }
}
