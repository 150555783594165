import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../utils/storage/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard implements CanActivate {
  token!: string;

  constructor(
    private authService: AuthService, 
    private router: Router, 
		private storageService: StorageService,
    ) {}

    async canActivate(): Promise<boolean> {
      try {
        const token = await this.storageService.getString(environment.config.tokenKey);
        
        if (this.authService.isTokenExpired(token) || !token) {
          this.router.navigate(['/pages/login']);
          return false;
        }
        return true;
        
      } catch (error) {
        this.router.navigate(['/pages/login']);
        return false;
      }
    }
}