import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/utils/storage/storage.service';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

const helperJWT = new JwtHelperService();
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private tokenKey = 'authToken';

    private apiPath = environment.interop.basePath;
    private loginPath = environment.interop.user.loginUrl;
    private logoutPath = environment.interop.user.logoutUrl;

    private changePasswordUrl =
        this.apiPath +
        environment.interop.platform.nomenclatoare.passwords.changePassword;

    private shortTokenUrl = this.apiPath + environment.interop.user.generateShortToken;
    private userDetailsUrl = this.apiPath + environment.interop.user.getUserDetails;

    constructor(
        private http: HttpClient,
        private storageService: StorageService
    ) { }

    login(data: { email: string; cnp?: number; password: string }) {
        return this.http.post(`${this.apiPath + this.loginPath}`, data);
    }

    logout() {
        const token = this.getAuthToken();

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        return this.http.post(`${this.apiPath + this.logoutPath}`, null, {
            headers,
        });
    }

    isAuthenticated(): boolean {
        return !!sessionStorage.getItem(this.tokenKey);
    }

    getAuthToken(): string | null {
        return sessionStorage.getItem(this.tokenKey);
    }

    setAuthToken(token: string): void {
        sessionStorage.setItem(this.tokenKey, token);
    }

    clearStorage() {
        const keysToKeep = [
            environment.config.storageKey + 'saveme',
            environment.config.storageKey + 'email',
            environment.config.storageKey + 'username',
            environment.config.storageKey + 'password'
        ];

        const allKeys = Object.keys(localStorage);

        // Parcurge fiecare cheie
        allKeys.forEach(key => {
            if (!keysToKeep.includes(key)) {
                localStorage.removeItem(key);
            }
        });

        sessionStorage.clear();
    }

    async returnTokenUser() {
        return this.storageService.getString(environment.config.tokenKey);
    }

    async getToken() {
        const tokenObj = this.returnTokenUser();
        tokenObj.then((value) => {
            return value;
        });
    }

    redirectToLogin() {
        window.location.href = environment.interop.linkRedirectAuth;
    }

    isTokenExpired(token: string): boolean {
        const isExpired = helperJWT.isTokenExpired(token);
        return isExpired;
    }

    changePassword(data: any) {
        return this.http.post(this.changePasswordUrl, data);
    }

    generateShortToken(clientId: number, moduleId: number) {
        return this.http.get(`${this.shortTokenUrl}${clientId}/${moduleId}`);
    }

    getUserDetails() {
        return this.http.get(this.userDetailsUrl);
    }
}
