import { Injectable } from '@angular/core';
import { ScreenSize } from '../enum/screen-size.enum';
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private screenSize = ScreenSize.SM;
  constructor() {
    window.removeEventListener('resize', () => this.setScreenSize());
    window.addEventListener('resize', () => this.setScreenSize());
    this.setScreenSize();
  }
  private setScreenSize() {
    innerWidth >= ScreenSize.XS && (this.screenSize = ScreenSize.XS);
    innerWidth >= ScreenSize.SM && (this.screenSize = ScreenSize.SM);
    innerWidth >= ScreenSize.MD && (this.screenSize = ScreenSize.MD);
    innerWidth >= ScreenSize.LG && (this.screenSize = ScreenSize.LG);
    innerWidth >= ScreenSize.XL && (this.screenSize = ScreenSize.XL);
  }
  getScreenSize() {
    if (this.isXS()) return 'xs';
    if (this.isSM()) return 'sm';
    if (this.isMD()) return 'md';
    if (this.isLG()) return 'lg';
    if (this.isXL()) return 'xl';
    return 'xs';
  }
  isXS() {
    return this.screenSize == ScreenSize.XS;
  }
  isSM() {
    return this.screenSize == ScreenSize.SM;
  }
  isMD() {
    return this.screenSize == ScreenSize.MD;
  }
  isLG() {
    return this.screenSize == ScreenSize.LG;
  }
  isXL() {
    return this.screenSize == ScreenSize.XL;
  }
}
