import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiuniCerereCardComponent } from './actiuni-cerere-card.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  declarations: [ActiuniCerereCardComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ActiuniCerereCardComponent],
})
export class ActiuniCerereCardModule {}
