import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { environment } from 'src/environments/environment';
import { CerereSabloaneService } from './cerere-sabloane.service';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { AddCerereSabloaneComponent } from './add-cerere-sabloane/add-cerere-sabloane.component';
import { EditCerereSabloaneComponent } from './edit-cerere-sabloane/edit-cerere-sabloane.component';
import Swal from 'sweetalert2';
import { SablonAddPayload } from './sabloane.interface';
import { helper } from 'src/environments/helper';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-cerere-sabloane',
    templateUrl: './cerere-sabloane.component.html',
    styleUrls: ['./cerere-sabloane.component.scss'],
})
export class CerereSabloaneComponent
    implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    showCard = false;
    activeValue: string = '-1';
    publicValue: string = '-1';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    resetForm() {
        this.activeValue = '-1';
        this.publicValue = '-1';

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.cerereSabloane
            .getCerereSabloaneDT;

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private cerereSabloaneService: CerereSabloaneService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            responsive: true,
            pageLength: 25,
            searching: false,

            ajax: (dataTablesParameters: any, callback: any) => {
                this.isLoading = true;
                dataTablesParameters.active = this.activeValue;
                dataTablesParameters.public_visible = this.publicValue;

                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'id',
                },
                {
                    title: 'Nume',
                    data: 'name',
                },
                {
                    title: 'Conținut',
                    data: 'content',
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    className: 'all',
                    orderable: false,
                    render: () => {
                        return `
            <div class="d-flex align-center justify-content-left">
              <button class="action-btn edit">
                <img class="btn-img" src="../../../../assets/icons/edit.svg" alt="edit"/>
              </button>
              <button class="action-btn delete ms-1">
                <img class="btn-img" src="../../../../assets/icons/delete.svg" alt="delete"/>
              </button>
              </div>
            `;
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;

                if (editIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'sabloane-cerere.update'
                        )
                    ) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (deleteIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'sabloane-cerere.delete'
                        )
                    ) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.deleteSablon(id);
                    });
                }

                return row;
            },
        };
    }

    rerenderDT(paginate = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddCerereSabloaneComponent, {
            ...this.dialogService.getDialogConfig(),
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Șablon solicitare adăugat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: SablonAddPayload) {
        const dialogRef = this.dialog.open(EditCerereSabloaneComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Șablon solicitare actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    deleteSablon(sablonId: number) {
        Swal.fire({
            title: 'Sunteți sigur?',
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm!',
            cancelButtonText: 'Anulare',
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.cerereSabloaneService.deleteSablon(sablonId).subscribe(() => {
                    Swal.fire({
                        title: 'Șters!',
                        text: 'Șablon solicitare șters cu succes.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.rerenderDT();
                    });
                });
            }
        });
    }
}
