import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeImageAdminComponent } from './type-image-admin.component';
import { AddTypeImageAdminComponent } from './add-type-image-admin/add-type-image-admin.component';
import { EditTypeImageAdminComponent } from './edit-type-image-admin/edit-type-image-admin.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TypeImageAdminComponent, AddTypeImageAdminComponent, EditTypeImageAdminComponent],
  imports: [
    CommonModule, MaterialModule, FormsModule, ReactiveFormsModule,
  ],
  exports: [TypeImageAdminComponent]
})
export class TypeImageAdminModule { }
