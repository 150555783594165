import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { formatMoment } from 'ngx-bootstrap/chronos/format';
import { Observable, map } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ToastService } from 'src/app/utils/services/toast.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  env: any = environment;

  private token!: string;
  private httpOptions: any;
  private basePath = environment.interop.basePath;

  errorTitle: string = environment.config.customNotifications.headers.error;
  errorIcon: string = environment.config.customNotifications.icons.error;
  errorType: string = environment.config.customNotifications.icons.error;
  // general success alert
  successTitle: string = environment.config.customNotifications.headers.success;
  successIcon: string = environment.config.customNotifications.icons.success;
  successType: string = environment.config.customNotifications.icons.success;

  private listGallery = this.basePath + environment.interop.gallery.listGallery;
  private storeImage = this.basePath + environment.interop.gallery.uploadImage;
  private deleteImage = this.basePath + environment.interop.gallery.deleteImage;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private toastService: ToastService
  ) {}

  upload(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const uploadApi = this.fileUpload(formData);

    const subscription = uploadApi.subscribe({
      next: (response: any) => {
        this.toastService.openToast({
          title: this.successTitle,
          message: 'Fișierul dvs a fost adăugat cu succes.',
          type: this.successIcon,
        });
      },
      error: (error: any) => {
        console.error(`Error uploading file ${file.name}:`, error);
        let errorMessage =
          environment.config.customNotifications.generalMessages.error;
        this.notificationService.warningSwal(
          this.errorTitle,
          errorMessage,
          this.errorIcon
        );
      },
      complete: () => {
        subscription.unsubscribe();
      },
    });
  }

  fileUpload(formData: FormData) {
    console.log(formData);
    return this.http.post(this.storeImage, formData);
  }

  getImages() {
    return this.http
      .get<{ errors: boolean; data: { images: any[] }; status_code: number }>(
        this.listGallery
      )
      .pipe(map((response) => response.data.images));
  }

  deleteImageGallery(id: number) {
    return this.http.delete(this.deleteImage + id);
  }
}
