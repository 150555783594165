import { Component, Input, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, map, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ClientContactsService } from '../client-contacts.service';
import { AddClientContactPayload } from '../client-contacts.interface';
import { environment } from 'src/environments/environment';

import { helper } from 'src/environments/helper';
import { Client } from '../../../client.interface';
import { Generic, GenericUser } from '../../../../../../../interfaces/generic.interface';
import { FunctiiService } from '../../../../settings-sidenav/functii/functii.service';
import { UsersService } from '../../../../settings-sidenav/users-settings/users.service';

@Component({
    selector: 'app-add-client-contact',
    templateUrl: './add-client-contact.component.html',
    styleUrls: ['./add-client-contact.component.scss'],
})
export class AddClientContactComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;
    functiiActive$!: Observable<Generic[]>;
    usersActive$!: Observable<GenericUser[]>;
    selectedForm: '' | 'nou' | 'existent' = '';

    defaultUserParameter: { name: string; id_user: string } = {
        name: '--Alegeți--',
        id_user: '-1',
    };

    //token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        client_id: new FormControl(this.data.id_client, [Validators.required]),
        user_id: new FormControl('', []),
        last_name: new FormControl('', [Validators.required]),
        first_name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        phone: new FormControl('', []),
        observations: new FormControl('', []),
        functie_id: new FormControl('', [Validators.required]),
    });

    formOperator: FormGroup = new FormGroup({
        client_id: new FormControl(this.data.id_client, [Validators.required]),
        user_id: new FormControl('', [Validators.required]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Client,
        private dialogRef: MatDialogRef<AddClientContactComponent>,
        private clientContactService: ClientContactsService,
        private notificationService: NotificationService,
        private functiiService: FunctiiService,
        private usersService: UsersService
    ) { }

    ngOnInit(): void {
    }

    closeDialog() {
        this.dialogRef ?.close({ success: false });
    }

    toggleForm(formType: 'nou' | 'existent') {
        this.selectedForm = formType;

        if (formType === 'nou') {
            this.loadFunctiiActive();
        }
        else {
            this.loadUsersActive();
        }
    }

    loadFunctiiActive() {
        this.isLoading = true;
        this.functiiActive$ = this.functiiService
            .getFunctiiActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    loadUsersActive() {
        this.isLoading = true;
        this.usersActive$ = this.usersService.getUsersActive()
            .pipe(
                map((results: any) => [this.defaultUserParameter, ...results.data]),
                finalize(() => {
                    this.isLoading = false;
                })
            );
    }

    submitForm() {
        this.isLoading = true;
        let payload: any;

        if (this.selectedForm == 'nou') {
            if (!this.form.valid) return;
            payload = this.form.value as AddClientContactPayload;
        }

        if (this.selectedForm == 'existent') {
            if (!this.formOperator.valid) return;
            payload = this.formOperator.value;
        }

        this.clientContactService.addClientContact(payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res: any) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err: any) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(this.errorTitle, msg, this.errorIcon);
                },
            });
    }
}
