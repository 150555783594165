import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformContentComponent } from './platform-content.component';
import { MaterialModule } from 'src/app/material.module';
import { AddPlatformContentComponent } from './add-platform-content/add-platform-content.component';
import { EditPlatformContentComponent } from './edit-platform-content/edit-platform-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PlatformContentComponent, AddPlatformContentComponent, EditPlatformContentComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [PlatformContentComponent]
})
export class PlatformContentModule { }
