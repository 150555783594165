import { Component, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { environment } from 'src/environments/environment';
import { FunctiiService } from './functii.service';
import { AddFunctieComponent } from './add-functie/add-functie.component';
import { EditFunctieComponent } from './edit-functie/edit-functie.component';
import { IFunctieUpdatePayload } from './functii.interface';

import { helper } from 'src/environments/helper';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-functii',
    templateUrl: './functii.component.html',
    styleUrls: ['./functii.component.scss'],
})
export class FunctiiComponent {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    showCard = false;
    activeValue: string = '-1';
    publicValue: string = '-1';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.functie.getFunctiiDT;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    onSelectionChange(event: any): void {
        this.activeValue = event.target.value;
        this.publicValue = event.target.value;
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }

    resetForm() {
        this.activeValue = '-1';
        this.publicValue = '-1';

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }
    constructor(
        private dialogService: DialogService,
        private http: HttpClient,
        private dialog: MatDialog,
        private functiiService: FunctiiService,
        public permissionsService: PermissionsService
    ) { }

    ngOnInit(): void {
        this.renderDT();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            responsive: true,
            searching: false,
            pageLength: 25,

            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.active = this.activeValue;
                dataTablesParameters.public_visible = this.publicValue;

                this.isLoading = true;
                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'id',
                },
                {
                    title: 'Denumire',
                    data: 'name',
                },
                {
                    title: 'Acronym',
                    data: 'acronym',
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    orderable: false,
                    className: 'all',
                    render: (data: any, type: any, row: any, met: any) => {
                        return (
                            `
              <div class="d-flex align-center justify-content-left">
							<button class="action-btn edit ms-1">
								<img class="btn-img" src="/assets/icons/edit.svg" alt="edit"/>
							</button>
							<button class="action-btn delete ms-1">
								<img class="btn-img" src="/assets/icons/delete.svg" alt="delete"/>
							</button>
							<button type="button" class="action-btn update_status_active ms-1 ` +
                            (row.active == true ? 'action-success' : 'action-danger') +
                            `">
								<img class="btn-img" src="` +
                            (row.active == true
                                ? '/assets/icons/check-white.svg'
                                : '/assets/icons/close-white.svg') +
                            `" alt="update_status_active"/>
							</button>
							<button type="button" class="action-btn update_status_public ms-1 ` +
                            (row.public_visible == true
                                ? 'action-success'
                                : 'action-danger') +
                            `">
								<img class="btn-img" src="` +
                            (row.public_visible == true
                                ? '/assets/icons/public_white.svg'
                                : '/assets/icons/public_not_white.svg') +
                            `" alt="update_status_public"/>
							</button>
              </div>
						`
                        );
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;
                const updateStatusActiveIcon = lastTd?.querySelector(
                    '.update_status_active'
                ) as HTMLElement;
                const updateStatusPublicIcon = lastTd?.querySelector(
                    '.update_status_public'
                ) as HTMLElement;

                if (editIcon) {
                    if (
                        !this.permissionsService.hasPermission('settings.functii.update')
                    ) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (deleteIcon) {
                    if (
                        !this.permissionsService.hasPermission('settings.functii.delete')
                    ) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.deleteFunctii(id);
                    });
                }

                if (updateStatusActiveIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.functii.update-status'
                        )
                    ) {
                        updateStatusActiveIcon.style.display = 'none';
                    }
                    updateStatusActiveIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusActive(id);
                    });
                }

                if (updateStatusPublicIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.functii.update-status'
                        )
                    ) {
                        updateStatusPublicIcon.style.display = 'none';
                    }
                    updateStatusPublicIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusPublic(id);
                    });
                }

                return row;
            },
        };
    }

    changeStatusActive(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul funcției selectate!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusActiveAction(id);
            }
        });
    }

    changeStatusActiveAction(id: number) {
        this.functiiService.changeStatuseActive(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Funcție actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusActiveAction(id);
                    }
                });
            }
        );
    }

    changeStatusPublic(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul funcției selectate!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusPublicAction(id);
            }
        });
    }

    changeStatusPublicAction(id: number) {
        this.functiiService.changeStatusePublic(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Funcție actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusPublicAction(id);
                    }
                });
            }
        );
    }

    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddFunctieComponent, {
            ...this.dialogService.getDialogConfig(),
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Funcție adăugată cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: IFunctieUpdatePayload) {
        const dialogRef = this.dialog.open(EditFunctieComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Funcție actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    deleteFunctii(id: number) {
        Swal.fire({
            title: 'Sunteți sigur?',
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm!',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.functiiService.deleteFunctie(id).subscribe(() => {
                    Swal.fire({
                        title: 'Șters!',
                        text: 'Funcție ștearsă cu succes.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.rerenderDT();
                    });
                });
            }
        });
    }
}
