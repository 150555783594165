<div class="add-container">
	<h1 class="add-title">Adaugă conținut platformă</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
				<div class="spinner">
					<mat-spinner color="primary" *ngIf="isSectionsLoading"></mat-spinner>
				</div>
				<mat-form-field class="w-100" *ngIf="!isSectionsLoading" appearance="fill">
					<mat-label>Secțiune</mat-label>
					<mat-select formControlName="section_id">
						<mat-option *ngFor="let section of sections$ | async" [value]="section.id">
							{{ section.name }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="
					!form.controls['section_id'].valid &&
					form.controls['section_id'].touched
					">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Titlu</mat-label>
					<input type="text" matInput formControlName="title" />
				</mat-form-field>
				<mat-error *ngIf="!form.controls['title'].valid && form.controls['title'].touched">
					Câmp obligatoriu
				</mat-error>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Slug</mat-label>
					<input type="text" matInput formControlName="slug" />
				</mat-form-field>
				<mat-error *ngIf="!form.controls['slug'].valid && form.controls['slug'].touched">
					Câmp obligatoriu
				</mat-error>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Observații</mat-label>
					<input type="text" matInput formControlName="observations" />
				</mat-form-field>
				<mat-error *ngIf="!form.controls['observations'].valid && form.controls['observations'].touched">
					Câmp obligatoriu
				</mat-error>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="fw-bold">Conținut</div>
				<angular-editor formControlName="content" [config]="editorConfig" ></angular-editor>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>