import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TypeImageService {
	private token!: string;
	private httpOptions: any;

	private basePath = environment.interop.basePath;
	private getImageTypesDTURL = this.basePath + environment.interop.platform.nomenclatoare.imageType.getImageTypesDT;
	private getImageTypesURL = this.basePath + environment.interop.platform.nomenclatoare.imageType.getImageTypes;
	private getImageTypeURL = this.basePath + environment.interop.platform.nomenclatoare.imageType.getImageType;
	private addImageTypeURL = this.basePath + environment.interop.platform.nomenclatoare.imageType.storeImageType;
	private updateImageTypeURL = this.basePath + environment.interop.platform.nomenclatoare.imageType.putImageType;
	private deleteImageTypeURL = this.basePath + environment.interop.platform.nomenclatoare.imageType.deleteImageType;

	constructor(private http: HttpClient) { }

	getImageTypesDT() {
		return this.http.get(this.getImageTypesDTURL, this.httpOptions);
	}

	addImageType(imageType: any) {
		return this.http.post(this.addImageTypeURL, imageType, this.httpOptions);
	}

	updateImageType(imageType: any) {
		return this.http.put(this.updateImageTypeURL, imageType, this.httpOptions);
	}

	deleteImageType(imageTypeId: number) {
		return this.http.delete(this.deleteImageTypeURL + imageTypeId);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}
}
