import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { helper } from 'src/environments/helper';
import { TipDocumente, TipDocumenteEditPayload } from '../tipuri-documente.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TipuriDocumenteService } from '../tipuri-documente.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-edit-tipuri-documente',
    templateUrl: './edit-tipuri-documente.component.html',
    styleUrls: ['./edit-tipuri-documente.component.scss']
})
export class EditTipuriDocumenteComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;

    form: FormGroup = new FormGroup({
        name: new FormControl(this.data.name, [Validators.required]),
        slug: new FormControl(this.data.slug, [Validators.required, noSpacesOrDotsValidator()]),
        archive_auto_days: new FormControl(this.data.archive_auto_days),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: TipDocumenteEditPayload,
        private dialogRef: MatDialogRef<EditTipuriDocumenteComponent>,
        private tipuriDocumenteService: TipuriDocumenteService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.tipuriDocumenteService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, slug, archive_auto_days } = this.form
            .value as TipDocumente;

        const updateTip: TipDocumenteEditPayload = {
            id: this.data.id,
            name,
            slug,
            archive_auto_days,
        };

        this.tipuriDocumenteService
            .updateTipDocumente(updateTip)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
