import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, Subject, finalize, map, startWith } from 'rxjs';
import { atLeastOneFieldValidator } from 'src/app/utils/common/validators/at-least-one-field';
import { Generic, GenericUser } from 'src/app/interfaces/generic.interface';
import { Client } from '../../../client.interface';
import { ClientOperatorsService } from '../client-operators.service';
import { UsersService } from '../../../../settings-sidenav/users-settings/users.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'app-add-client-operator',
    templateUrl: './add-client-operator.component.html',
    styleUrls: ['./add-client-operator.component.scss'],
})
export class AddClientOperatorComponent implements OnInit {

    // multiple user selection logic
    @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;
    usersCtrl = new FormControl('');
    selectedUsers: GenericUser[] = [];
    filteredUsers!: Observable<GenericUser[]> | undefined;
    allUsers: GenericUser[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];


    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    clientModulesActive$!: Observable<Generic[]>;
    //usersActive$!: Observable<GenericUser[]>; // single user select logic

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    defaultParameter: { name: string; id: number } = {
        name: '--Alegeți--',
        id: -1,
    };

    defaultUserParameter: { name: string; id_user: number } = {
        name: '--Alegeți--',
        id_user: -1,
    };

    isLoading = false;
    form: FormGroup = new FormGroup({
        client_id: new FormControl(this.data.id_client, [Validators.required]),
        //user_id: new FormControl(-1, [Validators.required]), // single user select logic
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Client,
        private dialogRef: MatDialogRef<AddClientOperatorComponent>,
        private clientModuleService: ClientOperatorsService,
        private notificationService: NotificationService,
        private usersService: UsersService,
    ) {
        // multiple user selection logic
        this.filteredUsers = this.usersCtrl?.valueChanges.pipe(
            startWith(null),
            map((value: string | null) => (value ? this.usersFilter(value) : this.allUsers.slice()))
        );
    }

    ngOnInit(): void {
        //this.loadUsersActive(); // single user select logic
        this.getUsersActive(); // multiple users selection
    }

    closeDialog() {
        this.dialogRef?.close({ success: false });
    }

    /* single user select logic
        loadUsersActive() {
            this.isLoading = true;
            this.usersActive$ = this.usersService.getUsersActive()
                .pipe(
                    map((results: any) => [this.defaultUserParameter, ...results.data]),
                    finalize(() => {
                        this.isLoading = false;
                    })
                );
        }
    */

    submitForm() {
        if (!this.selectedUsers.length) {
            this.notificationService.warningSwal(this.errorTitle, 'Alegeți cel puțin un operator.', this.errorIcon);
            return;
        }

        this.isLoading = true;

        // multiple user selection logic to map from user objects to user ids
        const payload = { ...this.form.value, ids_user: this.selectedUsers?.map((u: GenericUser) => u.id_user) };

        this.clientModuleService.massAddClientOperators(payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res: any) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err: any) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(this.errorTitle, msg, this.errorIcon);
                },
            });
    }


    // multiple user selection logic
    getUsersActive() {
        this.isLoading = true;
        this.usersService.getUsersActive()
            .pipe(
                map((response: any) => response.data),
                finalize(() => { this.isLoading = false; })
            )
            .subscribe((requestData: GenericUser[]) => {
                this.allUsers = requestData;
            })
    }

    addUser(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            const foundOption = this.allUsers.find(u => u.name.toLowerCase() === value.toLowerCase());
            if (foundOption) {

                this.selectedUsers.push(foundOption);
            }
        }

        event.chipInput!.clear();
        this.usersCtrl.setValue(null);
    }

    removeUser(user: GenericUser): void {
        const index = this.selectedUsers.indexOf(user);

        if (index >= 0) {
            this.selectedUsers.splice(index, 1);
        }
    }

    onUserSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedOption = this.allUsers.find(u => u.name === event.option.viewValue);
        if (selectedOption && !this.selectedUsers.find(u => u.id_user === selectedOption.id_user)) {

            this.selectedUsers.push(selectedOption);
            this.userInput.nativeElement.value = '';
            this.usersCtrl.setValue(null);
        }
    }

    private usersFilter(value: string | GenericUser): GenericUser[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value;
        } else {
            filterValue = value.name;
        }

        return this.allUsers.filter(u => u.name.toLowerCase().includes(filterValue));
    }
}
