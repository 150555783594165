import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { CererePriorityService } from '../cerere-priority.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { CererePriorityAddPayload } from '../cerere-priority.interface';
import { finalize } from 'rxjs';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-add-cerere-priority',
    templateUrl: './add-cerere-priority.component.html',
    styleUrls: ['./add-cerere-priority.component.scss'],
})
export class AddCererePriorityComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        bg_color: new FormControl('', [Validators.required]),
        time: new FormControl('', [Validators.required]),
        slug: new FormControl('', [Validators.required, noSpacesOrDotsValidator()]),
    });

    constructor(
        private dialogRef: MatDialogRef<AddCererePriorityComponent>,
        private cererePriorityService: CererePriorityService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.cererePriorityService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, bg_color, time, slug } = this.form
            .value as CererePriorityAddPayload;
        const newPriority: CererePriorityAddPayload = {
            name,
            bg_color,
            time,
            slug
        };

        this.cererePriorityService
            .addCererePriority(newPriority)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
