import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { ClientContactsComponent } from './client-contacts.component';
import { AddClientContactComponent } from './add-client-contact/add-client-contact.component';
import { EditClientContactComponent } from './edit-client-contact/edit-client-contact.component';

@NgModule({
  declarations: [ClientContactsComponent, AddClientContactComponent, EditClientContactComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  exports: [ClientContactsComponent],
})
export class ClientContactsModule { }
