import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
	CererePriorityAddPayload,
	CererePriorityEditPayload,
} from './cerere-priority.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CererePriorityService {
	private token!: string;
	private httpOptions: any;
	private basePath = environment.interop.basePath;
	private getCererePrioritiesUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.cererePriority
			.getCererePriorities;
	private getCererePriorityDT =
		this.basePath +
		environment.interop.platform.nomenclatoare.cererePriority
			.getCererePrioritiesDT;
	private storeCererePriorityURL =
		this.basePath +
		environment.interop.platform.nomenclatoare.cererePriority
			.storeCererePriority;
	private updateStoreCererePriority =
		this.basePath +
		environment.interop.platform.nomenclatoare.cererePriority.putCererePriority;
	private deleteStoreCererePriority =
		this.basePath +
		environment.interop.platform.nomenclatoare.cererePriority
			.deleteCererePriority;
	private getCererePrioritiesActive =
		this.basePath +
		environment.interop.platform.nomenclatoare.cererePriority
			.getCererePrioritiesActive;
	private getCererePrioritiesPublic =
		this.basePath +
		environment.interop.platform.nomenclatoare.cererePriority
			.getCererePrioritiesActivePublic;

	constructor(private http: HttpClient) { }

	getCererePrioritiesDT() {
		return this.http.get(this.getCererePriorityDT, this.httpOptions);
	}

	addCererePriority(cererePriority: CererePriorityAddPayload) {
		return this.http.post(
			this.storeCererePriorityURL,
			cererePriority,
			this.httpOptions
		);
	}

	updateCererePriority(cererePriority: CererePriorityEditPayload) {
		return this.http.put(
			this.updateStoreCererePriority,
			cererePriority,
			this.httpOptions
		);
	}

	deleteCererePriority(id: number) {
		return this.http.delete(this.deleteStoreCererePriority + id);
	}

	getActiveCererePriorities() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(this.getCererePrioritiesActive, this.httpOptions)
			.pipe(
				map((response: any) =>
					response.data.map((item : any) => ({
						id: item.id,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get request priorities:', error);
					return throwError(error);
				})
			);
	}

	getPublicCererePriorities() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(this.getCererePrioritiesPublic, this.httpOptions)
			.pipe(
				map((response: any) =>
					response.data.map((item : any) => ({
						id: item.id,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get request priorities:', error);
					return throwError(error);
				})
			);;
	}

	getCererePriorities() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getCererePrioritiesUrl
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({
						id: item.id,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get request priorities:', error);
					return throwError(error);
				})
			);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}
}
