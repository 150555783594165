import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FunctiiService } from '../functii.service';
import {
  IFunctie,
  IFunctieForm,
  IFunctieUpdatePayload,
} from '../functii.interface';

import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-edit-functie',
  templateUrl: './edit-functie.component.html',
  styleUrls: ['./edit-functie.component.scss'],
})
export class EditFunctieComponent {
  // general error
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl(this.data.name, [Validators.required]),
    observations: new FormControl(this.data.observations, []),
    acronym: new FormControl(this.data.acronym, [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFunctie,
    private dialogRef: MatDialogRef<EditFunctieComponent>,
    private functiiService: FunctiiService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.functiiService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const { name, observations, acronym } = this.form.value as IFunctieForm;
    const slug = name
      .split(' ')
      .map((item) => item.toLowerCase())
      .join('_');
    const updateFunctie: IFunctieUpdatePayload = {
      id: this.data.id,
      name,
      slug,
      observations,
      acronym,
    };

    this.functiiService
      .updateFunctie(updateFunctie)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            helper.dialogConfig.headers.errorAtentie,
            msg,
            this.errorIcon
          );
        },
      });
  }

  onHttpError(res: any) {
    if (res.errors && res.errors.message && res.errors.message.length > 0) {
      res.errors.message.forEach((msg: string) => {
        this.notificationService.warningSwal(
          this.errorTitle,
          msg,
          this.errorIcon
        );
      });
    } else {
      let msg;
      try {
        // try to catch the message from server
        msg = res.error.errors.message[0];
      } catch (error) {
        // controll output message
        msg = helper.dialogConfig.generalMessages.error;
      }
      this.notificationService.warningSwal(
        this.errorTitle,
        msg,
        this.errorIcon
      );
    }
  }
}
