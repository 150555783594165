import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User, activeUsersByOrganigramaPayload } from './user.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private token!: string;
  private httpOptions: any;

  private basePath = environment.interop.basePath;
  private getUsersUrl =
    this.basePath + environment.interop.platform.nomenclatoare.user.getUsers;
  private getUsersDTUrl =
    this.basePath + environment.interop.platform.nomenclatoare.user.getUsersDT;
  private storeUserURL =
    this.basePath + environment.interop.platform.nomenclatoare.user.storeUser;
  private updateUserUrl =
    this.basePath + environment.interop.platform.nomenclatoare.user.updateUser;
  private getUsersPublicUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.user.getUsersPublic;
  private getUsersActiveUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.user.getUsersActive;

  private changeStatusActiveUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.user.changeStatusUserActive;
  private changeStatusPublicUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.user.changeStatusUserPublic;

  private userTypeListActiveURL =
    this.basePath +
    environment.interop.platform.nomenclatoare.user.getUserTypeActive;

  private getUsersByIdUrl =
    this.basePath + environment.interop.platform.nomenclatoare.user.getUser;

  // admin
  private resetPasswordAdminUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.user.resetPasswordAdmin;

  private getActiveUsersByOrganigramaUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.user.getActiveUsersByOrganigrama;

  constructor(private http: HttpClient) {}

  getUsersDT() {
    return this.http.get(this.getUsersDTUrl, this.httpOptions);
  }

  getUsersById(userId: number) {
    return this.http.get(this.getUsersByIdUrl + userId, this.httpOptions);
  }

  addUser(user: User) {
    return this.http.post(this.storeUserURL, user, this.httpOptions);
  }

  updateUser(user: User) {
    return this.http.put(this.updateUserUrl, user, this.httpOptions);
  }

  getUsersActive() {
    return this.http.get(this.getUsersActiveUrl, this.httpOptions);
  }

  getUsersPublic() {
    return this.http.get(this.getUsersPublicUrl, this.httpOptions);
  }

  changeStatuseActive(id: number) {
    return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
  }

  changeStatusePublic(id: number) {
    return this.http.put(this.changeStatusPublicUrl + id, {}, this.httpOptions);
  }

  getUserTypeActive() {
    return this.http
      .get<{ errors: boolean; data: any[]; status_code: number }>(
        this.userTypeListActiveURL
      )
      .pipe(
        map((response) =>
          response.data.map((item) => ({ id: item.id, name: item.name }))
        ),
        catchError((error) => {
          console.error('Error in getUserTypeActive:', error);
          return throwError(error);
        })
      );
  }

  getUsers() {
    return this.http
      .get<{ errors: boolean; data: any[]; status_code: number }>(
        this.getUsersUrl
      )
      .pipe(
        map((response) =>
          response.data.map((item) => ({ id: item.id_user, name: item.name }))
        ),
        catchError((error) => {
          console.error('Error in getUsers:', error);
          return throwError(error);
        })
      );
  }

  setToken(token: string) {
    this.token = token;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      }),
    };
  }

  resetPassword(id: number) {
    return this.http.post(
      this.resetPasswordAdminUrl + id,
      {},
      this.httpOptions
    );
  }

  getActiveUsersByOrganigrama(data: activeUsersByOrganigramaPayload) {
    return this.http
      .post<{ errors: boolean; data: any[]; status_code: number }>(
        this.getActiveUsersByOrganigramaUrl,
        data,
        this.httpOptions
      )
      .pipe(
        map((response: any) =>
          response.data.map((item: any) => ({ id: item.id, name: item.name }))
        ),
        catchError((error) => {
          console.error('Error in getUserTypeActive:', error);
          return throwError(error);
        })
      );
  }
}
