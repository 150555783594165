<div class="add-container">
	<h1 class="add-title">Asignează în masă cereri</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="add-field">
            <mat-form-field>
                <mat-label>Direcție</mat-label>
                <mat-select formControlName="directie_id" (selectionChange)="onDirectieChange($event.value)">
                    <mat-option *ngFor="let directie of directii$ | async" [value]="directie.id">
                        {{ directie.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!form.controls['directie_id'].valid && form.controls['directie_id'].touched">
					Câmp obligatoriu
				</mat-error>
            </mat-form-field>
		</div>

        <div class="add-field">
            <mat-form-field>
                <mat-label>Compartiment</mat-label>
                <mat-select formControlName="compartiment_id">
                    <mat-option *ngFor="let compartiment of compartimenteActive$ | async" [value]="compartiment.id">
                        {{ compartiment.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
		</div>

        <div class="add-field">
            <mat-form-field>
                <mat-label>Operator</mat-label>
                <mat-select formControlName="operator">
                    <mat-option *ngFor="let user of users$ | async" [value]="user.id">
                        {{ user.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
		</div>

		<div class="add-action-btns">
			<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
                {{ helper.dialogConfig.buttonLabels.close }}
			</button>
			<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
                {{ helper.dialogConfig.buttonLabels.save }}
			</button>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>