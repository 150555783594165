import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { UsersService } from '../users.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../user.interface';
import { Observable, Subject, finalize, map } from 'rxjs';
import { atLeastOneFieldValidator } from 'src/app/utils/common/validators/at-least-one-field';
import { DirectiiService } from '../../directii/directii.service';
import { CompartimenteService } from '../../compartimente/compartimente.service';
import { FunctiiService } from '../../functii/functii.service';
import { Generic } from 'src/app/interfaces/generic.interface';
import { completeNameValidator } from 'src/app/shared/validators/complete-name';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    userTypes$!: Observable<Generic[]>;
    directiiActive$!: Observable<Generic[]>;
    compartimenteActive$!: Observable<Generic[]>;
    functiiActive$!: Observable<Generic[]>;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    defaultParameter: { name: string; id: string } = {
        name: '--Alegeți--',
        id: '-1',
    };

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup(
        {
            email: new FormControl('', [Validators.required, Validators.email]),
            username: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required, completeNameValidator()]),
            user_type_id: new FormControl('', [Validators.required]),
            functie_id: new FormControl('', [Validators.required]),
            notificari: new FormControl('', [Validators.required]),
            telefon_work: new FormControl('', []),
            id_sesizare_compartiment: new FormControl(null, []),
            id_sesizare_departament: new FormControl('', [Validators.required]),
            slug: new FormControl('', []),
        },
        { validators: atLeastOneFieldValidator('email', 'username') }
    );

    constructor(
        private dialogRef: MatDialogRef<AddUserComponent>,
        public usersService: UsersService,
        public directiiService: DirectiiService,
        public compartimenteService: CompartimenteService,
        public functiiService: FunctiiService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }
    ngOnInit(): void {
        this.loadUserTypes();
        this.loadDirectiiActive();
        // this.loadCompartimenteActive();
        this.loadFunctiiActive();
    }

    loadUserTypes() {
        this.isLoading = true;
        this.userTypes$ = this.usersService
            .getUserTypeActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    loadDirectiiActive() {
        this.isLoading = true;
        this.directiiActive$ = this.directiiService
            .getDirectiiActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    onDirectieChange(directieId: number): void {
        if (directieId) {
            this.loadCompartimenteActive(directieId);
        } else {
            this.compartimenteActive$ = new Observable<Generic[]>((observer) => {
                observer.next([{ name: '--Alegeți--', id: -1 }]);
                observer.complete();
            });

            console.log(this.compartimenteActive$, 'check here pls')
        }
    }

    loadCompartimenteActive(directieId?: number) {
        this.isLoading = true;
        let compartimenteObservable$ = directieId
            ? this.compartimenteService.getCompartimenteByDirectieId(directieId)
            : this.compartimenteService.getCompartimenteActive();

        this.compartimenteActive$ = compartimenteObservable$.pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => {
                this.isLoading = false;
            })
        );
    }

    loadFunctiiActive() {
        this.isLoading = true;
        this.functiiActive$ = this.functiiService
            .getFunctiiActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.usersService.setToken(token);
        });
    }

    submitForm() {

        // how to check if form is invalid and console log errors
        if (this.form.invalid) {
            return;
        }


        this.isLoading = true;

        const {
            name,
            username,
            email,
            user_type_id,
            functie_id,
            notificari,
            telefon_work,
            public_view,
            id_sesizare_compartiment,
            id_sesizare_departament,
            slug,
        } = this.form.value as User;

        const newUser: User = {
            name,
            username,
            email,
            user_type_id,
            functie_id,
            notificari,
            telefon_work: "+4" + telefon_work,
            public_view,
            id_sesizare_compartiment,
            id_sesizare_departament,
            slug,
        };

        this.usersService
            .addUser(newUser)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

}
