import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, finalize, map } from 'rxjs';
import { Generic } from 'src/app/interfaces/generic.interface';
import { helper } from 'src/environments/helper';
import { CererePriorityService } from '../../../settings-sidenav/cerere-priority/cerere-priority.service';
import { UpdateMassPriorityPayload } from '../request.interface';
import { RequestsService } from '../requests.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-update-request-priority',
    templateUrl: './update-request-priority.component.html',
    styleUrls: ['./update-request-priority.component.scss']
})
export class UpdateRequestPriorityComponent {
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    isLoading = false;

    defaultParameter: { name: string; id: string } = {
        name: 'Toate',
        id: '-1',
    };

    requestPriorities$!: Observable<Generic[]>;

    form: FormGroup = new FormGroup({
        priority_id: new FormControl('', [Validators.required]),
        message: new FormControl(''),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: number[],
        private dialogRef: MatDialogRef<UpdateMassPriorityPayload>,
        private cererePriorityService: CererePriorityService,
        private requestsService: RequestsService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.loadRequestPriorities();
    }

    loadRequestPriorities() {
        this.isLoading = true;
        this.requestPriorities$ = this.cererePriorityService.getCererePriorities().pipe(
            map((results: any) => [...results, this.defaultParameter]),
            finalize(() => (this.isLoading = false))
        );
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    submitForm() {
        this.isLoading = true;
        const ids_cerere = this.data;
        const currentPriorityValue = this.form.get('priority_id')?.value;
        const messageValue = this.form.get('message')?.value;

        const newPriority: UpdateMassPriorityPayload = {
            ids_cerere,
            priority_id: currentPriorityValue,
            message: messageValue,
        };

        this.requestsService
            .updateMassPriority(newPriority)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
