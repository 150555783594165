import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FunctiiService } from '../functii.service';
import { IFunctieAddPayload, IFunctieForm } from '../functii.interface';
import { environment } from 'src/environments/environment';

import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-add-functie',
  templateUrl: './add-functie.component.html',
  styleUrls: ['./add-functie.component.scss'],
})
export class AddFunctieComponent {
  // general error
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    observations: new FormControl('', []),
    acronym: new FormControl('', [Validators.required]),
  });

  constructor(
    private dialogRef: MatDialogRef<AddFunctieComponent>,
    private functiiService: FunctiiService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.functiiService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const { name, observations, acronym } = this.form.value as IFunctieForm;
    const slug = name
      .split(' ')
      .map((item) => item.toLowerCase())
      .join('_');
    const newFunctie: IFunctieAddPayload = {
      name,
      slug,
      observations,
      acronym,
    };

    this.functiiService
      .addFunctie(newFunctie)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        },
      });
  }
}
