import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TipuriBeneficiarAddPayload, TipuriBeneficiarEditPayload } from './tipur-beneficiar.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TipuriBeneficiarService {
    private token!: string;
    private httpOptions: any;
    private basePath = environment.interop.basePath;

    private getTipuriBeneficiarUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriBeneficiar.getTipuriBeneficiar;
    private storeTipBeneficiarUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriBeneficiar.storeTipBeneficiar;
    private updateTipBeneficiarUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriBeneficiar.putTipBeneficiar;
    private deleteTipBeneficiarUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriBeneficiar.deleteTipBeneficiar;
    private changeStatusActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriBeneficiar.changeStatusTipBeneficiarActive;
    private changeStatusPublicUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriBeneficiar.changeStatusTipBeneficiarPublic;

    constructor(private http: HttpClient) { }

    getTipuriBeneficiar() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getTipuriBeneficiarUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get tipuri beneficiar: ', error);
                    return throwError(error);
                })
            );
    }

    addTipBeneficiar(tipBeneficiar: TipuriBeneficiarAddPayload) {
        return this.http.post(this.storeTipBeneficiarUrl, tipBeneficiar, this.httpOptions);
    }

    updateTipBeneficiar(tipBeneficiar: TipuriBeneficiarEditPayload) {
        return this.http.put(this.updateTipBeneficiarUrl, tipBeneficiar, this.httpOptions);
    }

    deleteTipBeneficiar(id: number) {
        return this.http.delete(this.deleteTipBeneficiarUrl + id)
    }

    changeStatuseActive(id: number) {
        return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
    }

    changeStatusePublic(id: number) {
        return this.http.put(this.changeStatusPublicUrl + id, {}, this.httpOptions);
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }
}
