import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RequestsService } from '../requests.service';
import {
    Cerere,
    CerereCetatean,
    ContCetatean,
    DocumentCetatean,
    Operator,
    RequestMessageInternPayload,
    RequestMessageNotitaPayload,
    RequestMessageToCetateanPayload,
    VerificareCont,
    IColeague,
    HistoryEntry,
    ContBeneficiar,
} from '../request.interface';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';
import { helper } from 'src/environments/helper';
import {
    Observable,
    Subscription,
    combineLatest,
    finalize,
    map,
    startWith,
} from 'rxjs';
import { Generic } from 'src/app/interfaces/generic.interface';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/utils/storage/storage.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { AppFilesService } from 'src/app/services/app-files.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UsersService } from '../../../settings-sidenav/users-settings/users.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { CerereDetaliiSabloaneComponent } from './sabloane/cerere-detalii-sabloane.component';
import { ModalInchidereLucrareComponent } from './modal-inchidere-lucrare/modal-inchidere-lucrare.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { NotificareEmailComponent } from './notificare-email/notificare-email.component';

@Component({
    selector: 'app-request-details',
    templateUrl: './request-details.component.html',
    styleUrls: ['./request-details.component.scss'],
})
export class RequestDetailsComponent implements OnInit {
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;

    successTitle: string = helper.dialogConfig.headers.success;
    successIcon: string = helper.dialogConfig.icons.success;
    successType: string = helper.dialogConfig.icons.success;

    token!: string;
    isLoading = false;
    selectedDocGenerabilToggle = 'active';
    selectedDocToggle = 'cereri';
    selectedMessageToggle = 'cetatean';
    selectedMessagingToggle = 'internalMsg';
    selectedActionToggle = 'status';
    passedRequestId!: number;
    helper = helper;
    editorConfig!: AngularEditorConfig;
    safeHtml!: SafeHtml;

    showSignatures: any;

    cerere: Cerere | null = null;
    cerereCetatean: CerereCetatean | null = null;
    documenteCetatean: DocumentCetatean[] = [];
    contCetatean!: ContCetatean | null;
    contBeneficiar!: ContBeneficiar;
    operator: Operator[] = [];
    verificareCont!: VerificareCont[];
    istoric!: HistoryEntry[];

    requestStatuses: Generic[] = [];
    requestPriorities: Generic[] = [];

    directiiActive: Generic[] = [];
    compartimenteActive: Generic[] = [];
    usersActive: Generic[] = [];

    sendMessageCetateanForm!: FormGroup;
    sendMessageInternForm!: FormGroup;
    sendMessageNotitaForm!: FormGroup;

    notifyAnswer!: FormGroup;

    sendUpdateStatusForm!: FormGroup;
    sendUpdatePriorityForm!: FormGroup;
    sendUpdateAssigneeForm!: FormGroup;

    uploadedFileNames: string[] = [];

    showValidateCard: boolean = true;

    user: any = null;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    coleaguesCtrl = new FormControl('');
    filteredColeagues!: Observable<IColeague[]>;
    coleagues: IColeague[] = [];
    allColeagues: IColeague[] = [];

    @ViewChild('coleagueInput') coleagueInput!: ElementRef<HTMLInputElement>;

    defaultChoiceParameter: { name: string; id: string } = {
        name: '-- Alegeți --',
        id: '-1',
    };

    responseTimes = [
        { key: '3', value: 3 },
        { key: '5', value: 5 },
        { key: '7', value: 7 },
        { key: '10', value: 10 },
    ];

    uploading: any = {
        files_mesagerie_cetatean: false,
        files_mesagerie_intern: false,
        files_mesagerie_notita: false,
    };

    files: any = {
        files_mesagerie_cetatean: [],
        files_mesagerie_intern: [],
        files_mesagerie_notita: [],
    };

    // Subscription to track our sabloane added from the button below messaging component
    private contentSubscription!: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private dialogRef: MatDialogRef<RequestDetailsComponent>,
        private requestService: RequestsService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private authService: AuthService,
        private userService: UsersService,
        private fb: FormBuilder,
        private storageService: StorageService,
        private sanitizer: DomSanitizer,
        private appFileService: AppFilesService,
        private commonService: CommonService,
        public toastr: ToastrService,
        public permissionsService: PermissionsService
    ) {
        this.processToken();
        this.passedRequestId = this.data.cerere.id;
    }

    processUserDetails() {
        var self = this;
        const userObj = self.storageService.getObject(environment.config.userKey);
        userObj
            .then((val) => {
                self.user = val;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    ngOnInit(): void {
        const htmlFromBackend = `<span><span class='fa fa-clock-o'></span> 30z</span> <span class='green-text'><b>27z 21h </b></span>`;
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlFromBackend);

        this.getRequestInfo();
        this.editorConfig = initializeEditor();
        this.getUsersActive();
        this.initializeCetateanForm();
        this.initNotifyAnswer();
        this.subscribeToContent();

        this.sendMessageInternForm = this.fb.group({
            message: new FormControl('', [Validators.required]),
            type: new FormControl('2'),
            coleagues: new FormControl(this.coleagues),
            files: this.fb.array([]),
            notify: new FormControl(false),
        });
        this.sendMessageNotitaForm = this.fb.group({
            message: new FormControl('', [Validators.required]),
            type: new FormControl('3'),
            coleagues: new FormControl([]),
            files: this.fb.array([]),
            notify: new FormControl(false),
        });

        this.filteredColeagues = this.coleaguesCtrl.valueChanges.pipe(
            startWith(null),
            map((value: string | null) =>
                value ? this._coleaguesFilter(value) : this.allColeagues.slice()
            )
        );
    }

    initializeCetateanForm() {
        this.sendMessageCetateanForm = this.fb.group({
            message: new FormControl('', [Validators.required]),
            type: new FormControl('1'),
            coleagues: new FormControl([]),
            files: this.fb.array([]),
            notify: new FormControl(false),
        });
    }

    initNotifyAnswer() {
        this.notifyAnswer = new FormGroup({
            notify_date_expire: new FormControl('', [Validators.required]),
        });
    }

    // This subscribes to any content change coming from Sabloane button below
    subscribeToContent() {
        this.contentSubscription = this.requestService.sablonContent$.subscribe({
            next: (content) => {
                this.updateMessageFormControl(content);
            },
        });
    }
    // This updated our form control
    updateMessageFormControl(content: string) {
        // Update the 'message' form control with the new content
        this.sendMessageCetateanForm.controls['message'].setValue(content);
    }

    ngOnDestroy(): void {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    // Coleagues section
    addColeague(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            const foundOption = this.allColeagues.find(
                (coleague) => coleague.name.toLowerCase() === value.toLowerCase()
            );
            if (foundOption) {
                this.coleagues.push(foundOption);
                this.sendMessageInternForm.controls['coleagues'].setValue([...this.coleagues]);
                this.sendMessageInternForm.controls['coleagues'].updateValueAndValidity();
            }
        }

        event.chipInput!.clear();

        this.coleaguesCtrl.setValue(null);
    }

    removeColeague(coleague: IColeague): void {
        const index = this.coleagues.indexOf(coleague);

        if (index >= 0) {
            this.coleagues.splice(index, 1);
            this.sendMessageInternForm.controls['coleagues'].setValue([...this.coleagues]);
            this.sendMessageInternForm.controls['coleagues'].updateValueAndValidity();
        }
    }

    selectedColeagues(event: MatAutocompleteSelectedEvent): void {
        const selectedOption = this.allColeagues.find(
            (coleague) => coleague.name === event.option.viewValue
        );
        if (
            selectedOption &&
            !this.coleagues.find(
                (coleague) => coleague.id_user === selectedOption.id_user
            )
        ) {
            this.coleagues.push(selectedOption);
            this.coleagueInput.nativeElement.value = '';
            this.coleaguesCtrl.setValue(null);
            this.sendMessageInternForm.controls['coleagues'].setValue([...this.coleagues]);
            this.sendMessageInternForm.controls['coleagues'].updateValueAndValidity();
        }
    }

    private _coleaguesFilter(value: string | IColeague): IColeague[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value.name.toLowerCase();
        }

        return this.allColeagues.filter((coleague) =>
            coleague.name.toLowerCase().includes(filterValue)
        );
    }

    getUsersActive() {
        this.userService
            .getUsersActive()
            .pipe(map((response: any) => response.data))
            .subscribe((requestData) => {
                this.allColeagues = requestData;
            });
    }

    handleButtonClick(buttonValue: string) {
        switch (buttonValue.toLowerCase()) {
            case 'validează':
                this.confirmValidareSolicitare();
                break;
            case 'invalidează':
                this.showValidateCard = false;
                this.confirmInValidareSolicitare();
                break;
            case 'clasare':
                this.confirmAnulareSolicitare();
                break;
            case 'închidere lucrare':
                if (this.cerere) {
                    this.openInchidereSolicitare(this.cerere);
                }
                break;
            default:
                console.log('Unrecognized button value', buttonValue);
        }
    }

    handleButtonResetClick(buttonValue: string) {
        switch (buttonValue.toLowerCase()) {
            case 'validează':
                this.resetValidareCerere();
                break;
            case 'invalidează':
                this.resetInvalidareCerere();
                break;
            case 'clasare':
                this.resetClasareCerere();
                break;
            case 'închidere lucrare':
                this.resetInchidereCerere();
                break;
            default:
                console.log('Unrecognized button value', buttonValue.toLowerCase());
        }
    }

    getRequestInfo() {
        this.isLoading = true;

        const infoDocList = this.requestService
            .getInfoDocList(this.passedRequestId)
            .pipe(map((response: any) => response.data));

        const history = this.requestService
            .getHistoryById(this.passedRequestId)
            .pipe(map((response: any) => response.data));

        combineLatest([infoDocList, history]).subscribe(
            ([requestData1, requestData2]) => {
                this.isLoading = false;
                this.cerere = requestData1;
                this.documenteCetatean = requestData1.documents;

                this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(requestData1.sla);
                // History
                this.istoric = requestData2;
            },
            (error) => {
                this.isLoading = false;
                console.error('Error', error);
                let msg;
                try {
                    msg = error.error.errors.message[0];
                } catch (error) {
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.notificationService.warningSwal(this.errorTitle, msg, this.errorIcon)
                    .then((result) => {
                        this.closeDialog();
                    });
            }
        );
    }

    onFileSelected(event: any, section: any) {
        const files = event.target.files;
        if (files) {
            var filesArray;
            switch (section) {
                case 'cetatean':
                    filesArray = this.sendMessageCetateanForm.get('files') as FormArray;
                    break;

                case 'intern':
                    filesArray = this.sendMessageInternForm.get('files') as FormArray;
                    break;

                case 'nota':
                    filesArray = this.sendMessageNotitaForm.get('files') as FormArray;
                    break;
            }

            Array.from(files).forEach((file: any) => {
                if (!this.uploadedFileNames.includes(file.name)) {
                    this.uploadedFileNames.push(file.name);
                    const fileControl = new FormControl(file);

                    filesArray.push(fileControl);
                }
            });
        }
    }

    deleteFile(fileName: string, section: any) {
        const nameIndex = this.uploadedFileNames.indexOf(fileName);
        if (nameIndex > -1) {
            this.uploadedFileNames.splice(nameIndex, 1);
        }
        var filesArray: any;
        switch (section) {
            case 'cetatean':
                filesArray = this.sendMessageCetateanForm.get('files') as FormArray;
                break;

            case 'intern':
                filesArray = this.sendMessageInternForm.get('files') as FormArray;
                break;

            case 'nota':
                filesArray = this.sendMessageNotitaForm.get('files') as FormArray;
                break;
        }

        // const filesArray = this.sendMessageForm.get('files') as FormArray;
        const fileIndex = filesArray.controls.findIndex(
            (control: any) => control.value.name === fileName
        );
        if (fileIndex > -1) {
            filesArray.removeAt(fileIndex);
        }
    }

    downloadDocument(document: DocumentCetatean) {
        let fullPath = this.requestService.downloadDocumentUrl + document.id;
        this.appFileService.downloadFile(fullPath);
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.requestService.setToken(token);
            this.processUserDetails();
        });
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    onDocGenerabilChange(event: MatButtonToggleChange) {
        this.selectedDocGenerabilToggle = event.value;
    }

    onDocChange(event: MatButtonToggleChange) {
        this.selectedDocToggle = event.value;
    }

    onMessageChange(event: MatButtonToggleChange) {
        this.selectedMessageToggle = event.value;
    }

    onMessagingChange(event: MatButtonToggleChange) {
        this.selectedMessagingToggle = event.value;
    }

    resetAllForms() {
        if (this.sendMessageInternForm.value) {
            this.coleagues = [];
            this.sendMessageInternForm.get('message')?.setValue('');
            this.sendMessageInternForm.get('type')?.setValue('2');
            this.sendMessageInternForm.get('notify')?.setValue(false);


            const filesFormArray = this.sendMessageInternForm.get(
                'files'
            ) as FormArray;
            filesFormArray.clear();
        }

        if (this.sendMessageCetateanForm.value) {
            this.sendMessageCetateanForm.get('message')?.setValue('');
            this.sendMessageCetateanForm.get('type')?.setValue('1');
            this.sendMessageCetateanForm.get('notify')?.setValue(false);
            this.notifyAnswer.get('notify_date_expire')?.setValue(null);

            const filesFormArray = this.sendMessageCetateanForm.get(
                'files'
            ) as FormArray;
            filesFormArray.clear();
        }

        if (this.sendMessageNotitaForm.value) {
            this.sendMessageNotitaForm.get('message')?.setValue('');
            this.sendMessageNotitaForm.get('type')?.setValue('3');
            this.sendMessageNotitaForm.get('notify')?.setValue(false);

            const filesFormArray = this.sendMessageNotitaForm.get(
                'files'
            ) as FormArray;
            filesFormArray.clear();
        }
    }

    submitMessage(section: any) {
        this.isLoading = true;

        var newMessage;
        switch (section) {
            case 'cetatean':
                newMessage = this.sendMessageCetateanForm
                    .value as RequestMessageToCetateanPayload;

                if (this.sendMessageCetateanForm.get('notify')?.value === true) {
                    newMessage = Object.assign(newMessage, {
                        notify_date_expire: this.notifyAnswer.value.notify_date_expire,
                    });
                }
                break;

            case 'intern':
                newMessage = this.sendMessageInternForm
                    .value as RequestMessageInternPayload;
                break;

            case 'nota':
                newMessage = this.sendMessageNotitaForm
                    .value as RequestMessageNotitaPayload;
                break;
        }

        this.requestService
            .postMessage(this.cerere!.id, newMessage)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                    this.uploadedFileNames = [];
                    this.resetAllForms();
                })
            )
            .subscribe({
                next: (res) => {
                    this.isLoading = true;
                    this.notificationService.warningSwal(
                        this.successTitle,
                        'Solicitare actualizată cu succes!',
                        this.successIcon
                    );
                    // refresh data please
                    this.getRequestInfo();

                    switch (section) {
                        case 'cetatean':
                            this.sendMessageCetateanForm.patchValue({
                                message: '',
                                files: [],
                            });
                            this.notifyAnswer.patchValue({
                                notify: false,
                                notify_date_expire: null,
                            });
                            break;

                        case 'intern':
                            this.sendMessageInternForm.patchValue({
                                message: '',
                                files: [],
                                coleagues: this.coleagues,
                            });
                            break;

                        case 'nota':
                            this.sendMessageNotitaForm.patchValue({
                                message: '',
                                files: [],
                            });
                            break;
                    }

                    // this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    confirmPreluarePersonala() {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să preluați solicitarea curentă. Continuați?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading = true;
                this.requestService
                    .preluareCerere(this.cerere!.id)
                    .pipe(finalize(() => (this.isLoading = false)))
                    .subscribe({
                        next: (res) => {
                            this.isLoading = true;
                            this.notificationService.warningSwal(
                                this.successTitle,
                                'Solicitare preluată cu succes!',
                                this.successIcon
                            ).then(() => this.getRequestInfo());
                        },
                        error: (err) => {
                            let msg;
                            try {
                                msg = err.error.errors.message[0];
                            } catch (error) {
                                msg = helper.dialogConfig.generalMessages.error;
                            }
                            this.notificationService.warningSwal(
                                this.errorTitle,
                                msg,
                                this.errorIcon
                            );
                        },
                    });
            }
        });
    }

    confirmInValidareSolicitare() {
        Swal.fire({
            title: 'Sunteți sigur?',
            text: 'O sa invalidați această solicitare! Acțiune ireversibilă.',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm!',
            cancelButtonText: 'Anulați',
            input: 'text', // Enable text input
            reverseButtons: true,
            inputPlaceholder: 'Completați motivul invalidării', // Placeholder for input
            inputValidator: (value: any) => {
                if (!value) {
                    return 'Este necesară completarea unui motiv!'; // Validation message if input is empty
                }
                return null; // Return null if input is valid
            },
            showLoaderOnConfirm: true,
            preConfirm: (inputValue) => {
                return this.requestService
                    .invalidateCerere({
                        cerere_id: this.cerere!.id,
                        observations: inputValue,
                    }) // Assuming 'this.http' is your HttpClient
                    .toPromise()
                    .then((response) => {
                        return response; // You might want to do something with the input value and response here
                    })
                    .catch((err) => {
                        Swal.showValidationMessage(
                            `Request failed: ${err.error.errors.message[0]}`
                        );
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Succes!',
                    text: 'Solicitare actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: 'Închide',
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    openInchidereSolicitare(data: Cerere) {
        let dialogRef = this.dialog.open(ModalInchidereLucrareComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: 'Succes!',
                    text: 'Solicitare actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: 'Închide',
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    confirmAnulareSolicitare() {
        Swal.fire({
            title: 'Sunteți sigur?',
            text: 'O sa anulați această solicitare! Acțiune ireversibilă.',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm!',
            cancelButtonText: 'Anulați',
            reverseButtons: true,
            input: 'text', // Enable text input
            inputPlaceholder: 'Completați motivul anulării', // Placeholder for input
            inputValidator: (value) => {
                if (!value) {
                    return 'Este necesară completarea unui motiv!'; // Validation message if input is empty
                }
                return null; // Return null if input is valid
            },
            showLoaderOnConfirm: true,
            preConfirm: (inputValue) => {
                return this.requestService
                    .anulareCerere({
                        cerere_id: this.cerere!.id,
                        observations: inputValue,
                    })
                    .toPromise()
                    .then((response) => {
                        return response;
                    })
                    .catch((err) => {
                        Swal.showValidationMessage(
                            `Request failed: ${err.error.errors.message[0]}`
                        );
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Succes!',
                    text: 'Solicitare actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: 'Închide',
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    confirmValidareSolicitare() {
        Swal.fire({
            title: 'Sunteți sigur?',
            text: 'O sa validați această solicitare! Acțiune ireversibilă.',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm!',
            cancelButtonText: 'Anulați',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: (inputValue) => {
                return this.requestService
                    .validateCerere(this.cerere!.id) // Assuming 'this.http' is your HttpClient
                    .toPromise()
                    .then((response) => {
                        return response; // You might want to do something with the input value and response here
                    })
                    .catch((err) => {
                        Swal.showValidationMessage(
                            `Request failed: ${err.error.errors.message[0]}`
                        );
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Succes!',
                    text: 'Solicitare actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: 'Închide',
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    resetValidareCerere() {
        Swal.fire({
            title: this.helper.swalTextConfig.commonTitles.areYouSure,
            text: "Sunteți sigur că doriți resetarea validării de solicitare?",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText:
                this.helper.swalTextConfig.validationMessages.confirmButton,
            cancelButtonText:
                this.helper.swalTextConfig.validationMessages.cancelButton,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            input: 'text', // Enable text input
            inputPlaceholder: "Necesită completarea unui motiv...",
            inputValidator: (value: any) => {
                if (!value) {
                    return 'Este necesară completarea unui motiv!';
                }
                return null; // Return null if input is valid
            },
            preConfirm: (inputValue) => {
                return this.requestService
                    .resetValidareCerere(this.cerere!.id, { motiv: inputValue })
                    .toPromise()
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: this.helper.swalTextConfig.headers.success,
                    text: this.helper.swalTextConfig.generalMessages.successs,
                    icon: 'success',
                    confirmButtonText: this.helper.swalTextConfig.buttonLabels.close,
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    resetInvalidareCerere() {
        Swal.fire({
            title: this.helper.swalTextConfig.commonTitles.areYouSure,
            text: "Sunteți sigur că doriți resetarea invalidării de solicitare?",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText:
                this.helper.swalTextConfig.validationMessages.confirmButton,
            cancelButtonText:
                this.helper.swalTextConfig.validationMessages.cancelButton,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            input: 'text', // Enable text input
            inputPlaceholder: "Necesită completarea unui motiv...",
            inputValidator: (value: any) => {
                if (!value) {
                    return 'Este necesară completarea unui motiv!';
                }
                return null; // Return null if input is valid
            },
            preConfirm: (inputValue) => {
                return this.requestService
                    .resetInvalidareCerere(this.cerere!.id, { motiv: inputValue })
                    .toPromise()
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: this.helper.swalTextConfig.headers.success,
                    text: this.helper.swalTextConfig.generalMessages.successs,
                    icon: 'success',
                    confirmButtonText: this.helper.swalTextConfig.buttonLabels.close,
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    resetClasareCerere() {
        Swal.fire({
            title: this.helper.swalTextConfig.commonTitles.areYouSure,
            text: "Sunteți sigur că doriți resetarea clasării de solicitare?",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText:
                this.helper.swalTextConfig.validationMessages.confirmButton,
            cancelButtonText:
                this.helper.swalTextConfig.validationMessages.cancelButton,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            input: 'text', // Enable text input
            inputPlaceholder: "Necesită completarea unui motiv...",
            inputValidator: (value: any) => {
                if (!value) {
                    return 'Este necesară completarea unui motiv!';
                }
                return null; // Return null if input is valid
            },
            preConfirm: (inputValue) => {
                return this.requestService
                    .resetClasareCerere(this.cerere!.id, { motiv: inputValue })
                    .toPromise()
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: this.helper.swalTextConfig.headers.success,
                    text: this.helper.swalTextConfig.generalMessages.successs,
                    icon: 'success',
                    confirmButtonText: this.helper.swalTextConfig.buttonLabels.close,
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    resetInchidereCerere() {
        Swal.fire({
            title: this.helper.swalTextConfig.commonTitles.areYouSure,
            text: "Sunteți sigur că doriți resetarea închiderii de solicitare?",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText:
                this.helper.swalTextConfig.validationMessages.confirmButton,
            cancelButtonText:
                this.helper.swalTextConfig.validationMessages.cancelButton,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            input: 'text', // Enable text input
            inputPlaceholder: "Necesită completarea unui motiv...",
            inputValidator: (value: any) => {
                if (!value) {
                    return 'Este necesară completarea unui motiv!';
                }
                return null; // Return null if input is valid
            },
            preConfirm: (inputValue) => {
                return this.requestService
                    .resetInchidereCerere(this.cerere!.id, { motiv: inputValue })
                    .toPromise()
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: this.helper.swalTextConfig.headers.success,
                    text: this.helper.swalTextConfig.generalMessages.successs,
                    icon: 'success',
                    confirmButtonText: this.helper.swalTextConfig.buttonLabels.close,
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    addFileCetatean(event: any, key: 'file') {
        event.eventPreventDefault = true;
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        const files = inputElement.files;

        // Iterate through the selected files and append them to the 'files' array in the form
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.uploading[key] = true;

            const formData = new FormData();
            formData.append('file', file, file.name);

            this.requestService.fileUpload(formData).subscribe({
                next: (result: any) => {
                    let response =
                        typeof result.status_code !== 'undefined' ? result : result.error;
                    console.log(result, 'response?');
                    if (typeof response.status_code !== 'undefined') {
                        if (
                            response.status_code == 200 &&
                            typeof response.data !== 'undefined'
                        ) {
                            // Handle the uploaded file by adding it to the 'files' array in the form
                            const files = this.sendMessageCetateanForm.get(
                                'files'
                            ) as FormArray;
                            files.push(new FormControl(result.data));

                            this.uploading[key] = false;
                            this.openToast({
                                title: this.successTitle,
                                message:
                                    helper.dialogConfig.generalMessages.fileSuccessfullyUploaded,
                                type: this.successIcon,
                            });
                        }
                    } else {
                        // Handle error cases here
                        let errorMessage =
                            environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(
                            this.errorTitle,
                            errorMessage,
                            this.errorIcon
                        );
                        this.isLoading = false;
                        this.uploading[key] = false;
                    }
                },
                error: (result: any) => {
                    this.isLoading = false;
                    this.uploading[key] = false;
                    this.onHttpError(result.error);
                },
            });
        }
    }

    addFileIntern(event: any, key: 'file') {
        event.eventPreventDefault = true;
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        const files = inputElement.files;

        // Iterate through the selected files and append them to the 'files' array in the form
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.uploading[key] = true;

            const formData = new FormData();
            formData.append('file', file, file.name);

            this.requestService.fileUpload(formData).subscribe({
                next: (result: any) => {
                    let response =
                        typeof result.status_code !== 'undefined' ? result : result.error;
                    if (typeof response.status_code !== 'undefined') {
                        if (
                            response.status_code == 200 &&
                            typeof response.data !== 'undefined'
                        ) {
                            // Handle the uploaded file by adding it to the 'files' array in the form
                            const files = this.sendMessageInternForm.get(
                                'files'
                            ) as FormArray;
                            files.push(new FormControl(result.data));

                            this.uploading[key] = false;

                            this.openToast({
                                title: this.successTitle,
                                message:
                                    helper.dialogConfig.generalMessages.fileSuccessfullyUploaded,
                                type: this.successIcon,
                            });
                        }
                    } else {
                        // Handle error cases here
                        let errorMessage =
                            environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(
                            this.errorTitle,
                            errorMessage,
                            this.errorIcon
                        );
                        this.isLoading = false;
                        this.uploading[key] = false;
                    }
                },
                error: (result: any) => {
                    this.isLoading = false;
                    this.uploading[key] = false;
                    this.onHttpError(result.error);
                },
            });
        }
    }

    addFileNotita(event: any, key: 'file') {
        event.eventPreventDefault = true;
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        const files = inputElement.files;

        // Iterate through the selected files and append them to the 'files' array in the form
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.uploading[key] = true;

            const formData = new FormData();
            formData.append('file', file, file.name);

            this.requestService.fileUpload(formData).subscribe({
                next: (result: any) => {
                    let response =
                        typeof result.status_code !== 'undefined' ? result : result.error;
                    if (typeof response.status_code !== 'undefined') {
                        if (
                            response.status_code == 200 &&
                            typeof response.data !== 'undefined'
                        ) {
                            // Handle the uploaded file by adding it to the 'files' array in the form
                            // const currentFiles = this.sendMessageNotitaForm.get('files')?.value || [];
                            // currentFiles.push(result.data);
                            // this.sendMessageNotitaForm.get('files')?.setValue(currentFiles);
                            const files = this.sendMessageNotitaForm.get(
                                'files'
                            ) as FormArray;
                            files.push(new FormControl(result.data));

                            this.uploading[key] = false;

                            this.openToast({
                                title: this.successTitle,
                                message: 'Fișierul dvs. a fost adăugat cu succes!',
                                type: this.successIcon,
                            });
                        }
                    } else {
                        // Handle error cases here
                        let errorMessage =
                            environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(
                            this.errorTitle,
                            errorMessage,
                            this.errorIcon
                        );
                        this.isLoading = false;
                        this.uploading[key] = false;
                    }
                },
                error: (result: any) => {
                    this.isLoading = false;
                    this.uploading[key] = false;
                    this.onHttpError(result.error);
                },
            });
        }
    }

    onHttpError(res: any, showErrorsInToast: boolean = false) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                if (showErrorsInToast) {
                    this.openToast({
                        title: this.errorTitle,
                        message: msg,
                        type: this.errorType,
                    });
                } else {
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                }
            });
        } else {
            let errorMessage =
                environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(
                this.errorTitle,
                errorMessage,
                this.errorIcon
            );
        }
    }

    removeFileCetatean(index: number) {
        // const currentFiles = this.sendMessageCetateanForm.value.files || [];
        // const currentFiles = this.sendMessageCetateanForm.get('files')?.value || [];
        // currentFiles.splice(index, 1);
        // this.sendMessageCetateanForm.patchValue({ files: currentFiles });

        const files = this.sendMessageCetateanForm.get('files') as FormArray;
        if (index > -1 && index < files.length) {
            files.removeAt(index);
        }
    }

    removeFileIntern(index: number) {
        // const currentFiles = this.sendMessageInternForm.get('files')?.value || [];
        // currentFiles.splice(index, 1);
        // this.sendMessageInternForm.patchValue({ files: currentFiles });

        const files = this.sendMessageInternForm.get('files') as FormArray;
        if (index > -1 && index < files.length) {
            files.removeAt(index);
        }
    }

    removeFileNota(index: number) {
        // const currentFiles = this.sendMessageNotitaForm.get('files')?.value || [];
        // currentFiles.splice(index, 1);
        // this.sendMessageNotitaForm.patchValue({ files: currentFiles });

        const files = this.sendMessageNotitaForm.get('files') as FormArray;
        if (index > -1 && index < files.length) {
            files.removeAt(index);
        }
    }

    openToast(object: any) {
        const { type, message, title } = object;

        let options = this.toastr.toastrConfig;
        options.progressBar = true;
        options.preventDuplicates = true;
        options.closeButton = true;

        const inserted = this.toastr.show(
            message,
            title,
            options,
            options.iconClasses[type]
        );
    }

    onCerereChanged(data: Cerere) {
        this.cerere = data;
    }

    onIstoricChanged(data: HistoryEntry[]) {
        this.istoric = data;
    }

    openSabloane() {
        const dialogRef = this.dialog.open(CerereDetaliiSabloaneComponent, {
            minHeight: '100vh',
            maxHeight: '100vh',
            minWidth: '50vw',
            maxWidth: '50vw',
            disableClose: true,
        });

        dialogRef
            .afterClosed()
            .subscribe(({ success }: { success: boolean }) => { });
    }

    openEditDialog(value: any, type: number) { // 1 = email, 2 = telefon
        let dialogRef = this.dialog.open(EditDialogComponent, {
            ...this.dialogService.getDialogConfig(),
            data: {
                id_cerere: this.cerere?.id,
                value,
                type
            }
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: 'Succes!',
                    text: 'Solicitare actualizată cu succes.',
                    icon: 'success',
                    confirmButtonText: 'Închide',
                }).then(() => {
                    this.getRequestInfo();
                });
            }
        });
    }

    resendEmailNotification(data: any) {
        let dialogRef = this.dialog.open(NotificareEmailComponent, {
            ...this.dialogService.getDialogConfig(),
            data
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Notificare email trimisă cu succes.',
                    icon: 'success',
                    confirmButtonText: 'Închide',
                });
            }
        });
    }

    resendSmsNotification() {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'Doriți să retrimiteți sms de confirmare?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading = true;
                this.requestService.resendNotification(this.cerere?.id, 1)
                    .pipe(finalize(() => (this.isLoading = false)))
                    .subscribe({
                        next: () => {
                            Swal.fire({
                                title: helper.dialogConfig.headers.success,
                                text: 'Notificare SMS trimisă cu succes.',
                                icon: 'success',
                                confirmButtonText: helper.dialogConfig.buttonLabels.close,
                                allowOutsideClick: false,
                            });
                        },
                        error: (err) => {
                            let msg;
                            try {
                                // try to catch the message from server
                                msg = err.error.errors.message[0];
                            } catch (error) {
                                // controll output message
                                msg = helper.dialogConfig.generalMessages.error;
                            }

                            Swal.fire({
                                title: helper.dialogConfig.headers.errorAtentie,
                                text: msg,
                                icon: 'success',
                                confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                                showCancelButton: true,
                                cancelButtonText: helper.dialogConfig.buttonLabels.close,
                                reverseButtons: true,
                                allowOutsideClick: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.resendSmsNotification();
                                }
                            });
                        }
                    })
            }
        })
    }
}
