import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformAnnouncesComponent } from './platform-announces.component';
import { MaterialModule } from 'src/app/material.module';
import { AddPlatformAnnouncesComponent } from './add-platform-announces/add-platform-announces.component';
import { EditPlatformAnnouncesComponent } from './edit-platform-announces/edit-platform-announces.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PlatformAnnouncesComponent, AddPlatformAnnouncesComponent, EditPlatformAnnouncesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
		FormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [PlatformAnnouncesComponent]
})
export class PlatformAnnouncesModule { }
