<div class="title p-2">
    <div class="row">
        <div class="col d-flex justify-content-start">
            <h1 class="mx-1">Actualizare parolă</h1>
        </div>
    </div>
</div>

<mat-divider class="m-2"></mat-divider>

<div class="container">
    <div class="row">
        <div class="col">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="row p-1">
                    <div class="col">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Parola curentă</mat-label>
                            <input type="password" matInput formControlName="old_password"
                                [type]="hideCurrentPass ? 'password' : 'text'" />
                            <button mat-icon-button type="button" matSuffix (click)="hideCurrentPass = !hideCurrentPass"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideCurrentPass">
                                <mat-icon>{{ hideCurrentPass ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row p-1">
                    <div class="col">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Parola nouă</mat-label>
                            <input type="password" matInput formControlName="password"
                                [type]="hidePass1 ? 'password' : 'text'" />
                            <button mat-icon-button type="button" matSuffix (click)="hidePass1 = !hidePass1"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass1">
                                <mat-icon>{{ hidePass1 ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-error *ngIf="form.get('password')?.errors?.['minlength']">
                            Parola trebuie să conțină minim 8 caractere
                        </mat-error>
                        <mat-error
                            *ngIf="form.get('password')?.hasError('pattern') && !form.get('password')?.hasError('minlength') && !form.get('password')?.hasError('maxlength')">
                            Parola trebuie să conțină cel puțin o literă de tipar, o literă mică, un număr și un
                            caracter special (#?!@$%^&*-).
                        </mat-error>
                    </div>
                </div>
                <div class="row p-1">
                    <div class="col">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Confirmă parola nouă</mat-label>
                            <input type="password" matInput formControlName="password_confirmation"
                                [type]="hidePass2 ? 'password' : 'text'" />
                            <button mat-icon-button type="button" matSuffix (click)="hidePass2 = !hidePass2"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass2">
                                <mat-icon>{{ hidePass2 ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-error *ngIf="form.errors?.['mismatch'] && form.get('password_confirmation')?.touched">
                            Parolele nu se potrivesc
                        </mat-error>
                    </div>
                </div>

                <mat-divider class="m-2"></mat-divider>

                <div class="p-2">
                    <div class="row">
                        <div class="col d-flex justify-content-between">
                            <button (click)="closeDialog()" type="button" class="btn btn-warning">Închide</button>
                            <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Salvează</button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="section-spinner position-absolute" *ngIf="isLoading">
                <div class="spinner position-absolute top-50 start-50 translate-middle">
                    <mat-spinner></mat-spinner>
                    <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                </div>
            </div>
        </div>
    </div>
</div>