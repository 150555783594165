import { Component, OnInit, Inject, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, Subject, Subscription, finalize, forkJoin, map, startWith, takeUntil } from 'rxjs';
import { atLeastOneFieldValidator } from 'src/app/utils/common/validators/at-least-one-field';
import { Generic, GenericUser } from 'src/app/interfaces/generic.interface';
import { Client } from '../../client.interface';
import { ClientOperatorsService } from '../client-operators/client-operators.service';
import { ClientPermissionsService } from './client-permissions.service';
import Swal from 'sweetalert2';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-client-permissions',
    templateUrl: './client-permissions.component.html',
    styleUrls: ['./client-permissions.component.scss']
})
export class ClientPermissionsComponent implements OnInit, OnDestroy {
    @Input() client!: Client;

    private unsubscribe$ = new Subject<void>();

    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    clientModulesActive$!: Observable<Generic[]>;
    clientUsersActive$!: Observable<Generic[]> | undefined;

    groupsAndPermissions$!: Observable<any>;
    allGroupsWithPermissions: any = [];
    selectedPermissions: any = []

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    defaultParameter: { name: string; id: number } = {
        name: '--Alegeți--',
        id: -1,
    };

    isLoading = false;
    groupPermissionsLoaded = false;

    form: FormGroup = new FormGroup({
        client_id: new FormControl(this.data.id_client, [Validators.required]),
        user_id: new FormControl(-1, [Validators.required]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Client,
        private clientOperatorsService: ClientOperatorsService,
        private notificationService: NotificationService,
        private clientPermissionsService: ClientPermissionsService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.loadClientUsersActive();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    get userCtrl() {
        return this.form.get('user_id');
    }

    loadClientUsersActive() {
        this.isLoading = true;

        this.clientUsersActive$ = this.clientOperatorsService.getUsersByClient(this.data.id_client)
            .pipe(
                map((results: any) => {
                    const extractedData = results.data.map((item: any) => ({
                        id: item.id_user,
                        name: item.name,
                    }));

                    return [this.defaultParameter, ...extractedData];
                }),
                finalize(() => {
                    this.isLoading = false;
                })
            );
    }

    onUserChange(userId: number): void {
        this.groupPermissionsLoaded = false;
        this.allGroupsWithPermissions = [];
        if (userId && userId !== -1) {
            this.loadPermissions();
        }
    }

    clearUsers() {
        this.userCtrl?.setValue(-1);
        this.clientUsersActive$ = undefined;
        this.groupPermissionsLoaded = false;
        this.allGroupsWithPermissions = [];
    }

    loadPermissions() {
        this.isLoading = true;

        const permissions$ = this.clientPermissionsService.listPermissions()
            .pipe(
                takeUntil(this.unsubscribe$),
                map((results: any) => {
                    const groupsArray = Object.values(results.data);

                    const groupsWithPermissions = groupsArray.map((item: any) => ({
                        groupId: item.id,
                        name: item.name,
                        checked: false,
                        permissions: item.permission.map((p: any) => ({
                            permissionAssignedOnGroupId: p.permission_assigned_on_group_id,
                            name: p.name,
                            checked: false
                        })),
                        subgroups: Object.values(item.subgroup).map((sgItem: any) => ({
                            subgroupId: sgItem.id,
                            name: sgItem.name,
                            checked: false,
                            permissions: sgItem.permission.map((sgp: any) => ({
                                permissionAssignedOnGroupId: sgp.permission_assigned_on_group_id,
                                name: sgp.name,
                                checked: false
                            })),
                        })),
                    }));

                    return groupsWithPermissions;
                })
            );

        const userPermissions$ = this.clientPermissionsService.getUserPermissions({ client_id: this.data.id_client, user_id: this.userCtrl?.value })
            .pipe(
                takeUntil(this.unsubscribe$),
                map((results: any) => {
                    return results.data;
                })
            );;

        forkJoin({ permissions$, userPermissions$ })
            .pipe(
                takeUntil(this.unsubscribe$),
                map(({ permissions$, userPermissions$ }) => {
                    // Combine the results and set 'checked' property based on the saved userPermissions
                    const combinedPermissions = permissions$.map((group: any) => {
                        group.permissions.forEach((permission: any) => {
                            permission.checked = userPermissions$.some((userPermissionAssignedOnGroupId: any) => userPermissionAssignedOnGroupId === permission.permissionAssignedOnGroupId);
                        });

                        group.subgroups.forEach((sg: any) => {
                            sg.permissions.forEach((permission: any) => {
                                permission.checked = userPermissions$.some((userPermissionAssignedOnGroupId: any) => userPermissionAssignedOnGroupId === permission.permissionAssignedOnGroupId);
                            });

                            this.setSubgroupChecked(sg);
                        });


                        this.setGroupChecked(group);
                        return group;
                    });

                    this.allGroupsWithPermissions = combinedPermissions;
                    this.selectedPermissions = userPermissions$;
                    this.groupPermissionsLoaded = true;
                    return combinedPermissions;
                }),
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    setGroupChecked(group: any) {
        let isGroupChecked = group.permissions.every((p: any) => p.checked);

        if (group.subgroups && group.subgroups.length > 0) {
            isGroupChecked = group.subgroups.every((sg: any) => sg.permissions.every((p: any) => p.checked))
        }

        group.checked = isGroupChecked;
    }

    setSubgroupChecked(subgroup: any) {
        subgroup.checked = subgroup.permissions.every((p: any) => p.checked);
    }

    updateCheckedList(permission: any, group: any, subgroup: any) {
        const existingIndex = this.selectedPermissions.findIndex(
            (item: any) => item === permission.permissionAssignedOnGroupId
        );

        if (permission.checked) {
            // If not found in the selectedPermissions, add the entry
            if (existingIndex === -1) {
                this.selectedPermissions.push(permission.permissionAssignedOnGroupId);
            }
        } else {
            // If found, because permission was unchecked, remove the entry
            if (existingIndex !== -1) {
                this.selectedPermissions.splice(existingIndex, 1);
            }
        }

        if (subgroup) {
            this.setSubgroupChecked(subgroup);
        }

        this.setGroupChecked(group);
        console.log(this.selectedPermissions);
    }

    markPermissions(group: any) {
        group.permissions.forEach((p: any) => {
            p.checked = group.checked;

            const existingPermissionIndex = this.selectedPermissions.findIndex(
                (item: any) => item === p.permissionAssignedOnGroupId
            );

            //add the permission to the selected list as the parent (group or subgroup) has been checked
            if (group.checked && existingPermissionIndex === -1) {
                this.selectedPermissions.push(p.permissionAssignedOnGroupId);
            }

            //remove the permission from the selected list as the parent (group or subgroup) has been unchecked
            if (!group.checked && existingPermissionIndex !== -1) {
                this.selectedPermissions.splice(existingPermissionIndex, 1);
            }
        });
    }

    updateCheckedGroup(event: Event, group: any) {
        event.stopPropagation();

        this.markPermissions(group);

        if (group.subgroups) {
            group.subgroups.forEach((sg: any) => {
                sg.checked = group.checked;
                this.markPermissions(sg);
            });
        }

        console.log(this.selectedPermissions);
    }

    updateCheckedSubgroup(event: Event, group: any, subgroup: any) {
        event.stopPropagation();

        this.markPermissions(subgroup);

        // we need to see if the group needs to be also checked (all its permissions and all its subgroups permissions have been checked)
        this.setGroupChecked(group);

        console.log(this.selectedPermissions);
    }

    groupHasAnyPermissions(group: any) {
        const hasPermissions = (group.permissions && group.permissions.length > 0) ||
            (group.subgroups && group.subgroups.some((sg: any) => sg.permissions && sg.permissions.length > 0))

        return hasPermissions;
    }

    submit() {

        if (!(this.form.valid && this.selectedPermissions.length)) {
            this.notificationService.warningSwal(this.errorTitle, 'Alegeți un modul, un utilizator și bifați cel puțin o permisiune.', this.errorIcon);
        }

        this.isLoading = true;

        const payload = { ...this.form.value, permissions_assigned_on_group: this.selectedPermissions };

        this.clientPermissionsService.updateAssignedPermissionUser(payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (responses: any[]) => {
                    // if clearing is not needed, then just display the success message
                    /*                    
                                        // clear form controls and existing permissions (user's and displayed ones)
                                        this.selectedPermissions = [];
                                        this.clearUsers();
                                        this.moduleCtrl ?.setValue(-1);
                                        this.groupsAndPermissions$ = new Observable<any>();
                    */
                    Swal.fire({
                        title: helper.dialogConfig.headers.success,
                        text: 'Permisiunile au fost actualizate cu succes.',
                        icon: 'success',
                        confirmButtonText: helper.dialogConfig.buttonLabels.close,
                        allowOutsideClick: false,
                    });
                },
                (err: any) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    console.log(payload, 'ERROR - ' + msg)
                    this.notificationService.warningSwal(this.errorTitle, msg, this.errorIcon);
                });
    }
}
