import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { FunctiiComponent } from './functii.component';
import { AddFunctieComponent } from './add-functie/add-functie.component';
import { EditFunctieComponent } from './edit-functie/edit-functie.component';

@NgModule({
  declarations: [FunctiiComponent, AddFunctieComponent, EditFunctieComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  exports: [FunctiiComponent],
})
export class FunctiiModule {}
