import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import {
	Compartiment,
	CompartimentUpdatePayload,
} from '../compartiment.interface';
import { CompartimenteService } from '../compartimente.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, Subject, finalize, map } from 'rxjs';
import { Generic } from 'src/app/interfaces/generic.interface';
import { DirectiiService } from '../../directii/directii.service';

@Component({
	selector: 'app-edit-compartiment',
	templateUrl: './edit-compartiment.component.html',
	styleUrls: ['./edit-compartiment.component.scss'],
})
export class EditCompartimentComponent {
	// general error
	errorTitle: string = helper.dialogConfig.headers.errorAtentie;
	errorIcon: string = helper.dialogConfig.icons.error;
	errorType: string = helper.dialogConfig.icons.error;
	helper = helper;

	token!: string;
	isLoading = false;
	form: FormGroup = new FormGroup({
		id_compartiment: new FormControl(this.data.id_compartiment, [
			Validators.required,
		]),
		directie_id: new FormControl(this.data.directie_id, [Validators.required]),
		name: new FormControl(this.data.name, [Validators.required]),
		description: new FormControl(this.data.description, []),
	});

	directiiActive$!: Observable<Generic[]>;

	defaultParameter: { name: string; id: string } = {
		name: '--Alegeți--',
		id: '-1',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Compartiment,
		private dialogRef: MatDialogRef<EditCompartimentComponent>,
		private compartimenteService: CompartimenteService,
		private notificationService: NotificationService,
		private authService: AuthService,
		public directiiService: DirectiiService,
	) {
		this.processToken();
	}

	ngOnInit() {
		this.loadDirectiiActive();
	}

	loadDirectiiActive() {
		this.isLoading = true;
		this.directiiActive$ = this.directiiService
			.getDirectiiActive()
			.pipe(finalize(() => (this.isLoading = false)));
	}

	closeDialog() {
		this.dialogRef?.close({
			success: false,
		});
	}

	processToken() {
		const tokenObj = this.authService.returnTokenUser();
		tokenObj.then((token) => {
			this.token = token;
			this.compartimenteService.setToken(token);
		});
	}

	submitForm() {
		this.isLoading = true;

		const { id_compartiment, directie_id, name, description } = this.form
			.value as CompartimentUpdatePayload;
		const slug = name
			.split(' ')
			.map((item) => item.toLowerCase())
			.join('_');
		const updateCompartiment: CompartimentUpdatePayload = {
			id_compartiment,
			directie_id,
			name,
			description,
		};

		this.compartimenteService
			.updateCompartiment(updateCompartiment)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe({
				next: () => {
					this.dialogRef.close({ success: true });
				},
				error: (err) => {
					let msg;
					try {
						// try to catch the message from server
						msg = err.error.errors.message[0];
					} catch (error) {
						// controll output message
						msg = helper.dialogConfig.generalMessages.error;
					}
					this.notificationService.warningSwal(
						helper.dialogConfig.headers.errorAtentie,
						msg,
						this.errorIcon
					);
				},
			});
	}

	onHttpError(res: any) {
		if (res.errors && res.errors.message && res.errors.message.length > 0) {
			res.errors.message.forEach((msg: string) => {
				this.notificationService.warningSwal(
					this.errorTitle,
					msg,
					this.errorIcon
				);
			});
		} else {
			let msg;
			try {
				// try to catch the message from server
				msg = res.error.errors.message[0];
			} catch (error) {
				// controll output message
				msg = helper.dialogConfig.generalMessages.error;
			}
			this.notificationService.warningSwal(
				this.errorTitle,
				msg,
				this.errorIcon
			);
		}
	}
}
