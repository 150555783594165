import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { File, HistoryEntry } from '../../request.interface';
import { RequestsService } from '../../requests.service';
import { AppFilesService } from 'src/app/services/app-files.service';
import { finalize } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-cereri-istoric',
    templateUrl: './cereri-istoric.component.html',
    styleUrls: ['./cereri-istoric.component.scss'],
})
export class CereriIstoricComponent implements OnInit, OnChanges {
    @Input() istoric?: HistoryEntry[] = [];
    @Input() user: any;
    @Input() notifyIds: any = [];
    @Input() id_cerere!: number | undefined;

    @Output() requestInfoUpdated: EventEmitter<void> = new EventEmitter();

    showCard = false;
    isLoadingOperator = false;
    isLoadingCitizen = false;
    isLoadingAll = false;

    isReportMessageLoading: { [key: number]: boolean } = {};
    filteredHistory!: HistoryEntry[];

    searchParamsForm!: FormGroup;

    initialFormValue = {
        defaultValue: '-1',
    };

    messageTypes = [
        { key: '--Toate--', value: '-1' },
        { key: 'Către UAT', value: ['0', '1'] },
        { key: 'Intern', value: ['2', '3'] },
    ];

    constructor(
        private requestService: RequestsService,
        private appFileService: AppFilesService
    ) { }

    ngOnInit(): void {
        this.initFilterForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterHistoryByType();
    }

    initFilterForm() {
        this.searchParamsForm = new FormGroup({
            messageType: new FormControl(this.initialFormValue.defaultValue),
        });
    }

    resetForm() {
        this.searchParamsForm.reset(this.initialFormValue);
        this.showCard = true;
    }

    filterHistoryByType() {
        const selectedType = this.searchParamsForm?.get('messageType')?.value;

        if (selectedType === '-1') {
            this.filteredHistory = [...(this.istoric || [])];
        } else {
            console.log(selectedType);
            this.filteredHistory = (this.istoric || []).filter(entry => selectedType.includes(entry.type.toString()));
        }
    }

    exportConv(type: number) {
        // Set the appropriate loading flag to true based on the type
        if (type === 1) this.isLoadingOperator = true;
        if (type === 2) this.isLoadingCitizen = true;
        if (type === 3) this.isLoadingAll = true;

        if (this.id_cerere) {
            this.requestService.exportHistory(this.id_cerere, type).subscribe({
                next: (data: Blob) => {
                    const blobUrl = URL.createObjectURL(data);
                    window.open(blobUrl, '_blank');
                    this.resetLoadingFlags();
                },
                error: (error: any) => {
                    console.error('Error exporting PDF:', error);
                    this.resetLoadingFlags();
                }
            });
        }
    }

    resetLoadingFlags() {
        this.isLoadingOperator = false;
        this.isLoadingCitizen = false;
        this.isLoadingAll = false;
    }


    downloadDocument(file: File) {
        let fullPath = this.requestService.downloadDocumentUrl + file.id_document;
        this.appFileService.downloadFile(fullPath);
    }

    resendMessage(cerereHistoryId: number) {
        this.isReportMessageLoading[cerereHistoryId] = true;
        this.requestService.rePostMessage(cerereHistoryId).pipe(finalize(() => {
            this.isReportMessageLoading[cerereHistoryId] = false;
            // Refresh the cereri-detalii component
            this.requestInfoUpdated.emit();
        })).subscribe();
    }
}
