import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IFunctieAddPayload, IFunctieUpdatePayload } from './functii.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FunctiiService {
	private token!: string;
	private httpOptions: any;

	private basePath = environment.interop.basePath;
	private getFunctiiDTUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie.getFunctiiDT;
	private storeFunctieUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie.storeFunctie;
	private updateFunctieUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie.putFunctie;
	private deleteFunctieUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie.deleteFunctie;
	private getFunctiiPublicUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie.getFunctiiActivePublic;
	private getFunctiiActiveUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie.getFunctiiActive;

	private changeStatusActiveUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie
			.changeStatusFunctieActive;
	private changeStatusPublicUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.functie
			.changeStatusFunctiePublic;

	constructor(private http: HttpClient) { }

	getFunctiiDT() {
		return this.http.get(this.getFunctiiDTUrl, this.httpOptions);
	}

	addFunctie(data: IFunctieAddPayload) {
		return this.http.post(this.storeFunctieUrl, data, this.httpOptions);
	}

	updateFunctie(data: IFunctieUpdatePayload) {
		return this.http.put(this.updateFunctieUrl, data, this.httpOptions);
	}

	deleteFunctie(functieId: number) {
		return this.http.delete(this.deleteFunctieUrl + functieId);
	}

	getFunctiiActive() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getFunctiiActiveUrl
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({ id: item.id, name: item.name }))
				),
				catchError((error) => {
					console.error('Error in get functii active:', error);
					return throwError(error);
				})
			);
	}

	getFunctiiPublic() {
		return this.http.get(this.getFunctiiPublicUrl, this.httpOptions);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}

	changeStatuseActive(id: number) {
		return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
	}

	changeStatusePublic(id: number) {
		return this.http.put(this.changeStatusPublicUrl + id, {}, this.httpOptions);
	}
}
