import { Component, Inject, OnInit } from '@angular/core';
import { EditPlatformSection } from '../../platform-section/platform-section.interface';
import { Observable, finalize, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FAQ, FAQForm, FAQUpdatePayload } from '../platform-faq.interface';
import { PlatformSectionService } from '../../platform-section/platform-section.service';
import { PlatformFaqService } from '../platform-faq.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';

import { helper } from 'src/environments/helper';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';

@Component({
  selector: 'app-edit-platform-faq',
  templateUrl: './edit-platform-faq.component.html',
  styleUrls: ['./edit-platform-faq.component.scss'],
})
export class EditPlatformFaqComponent implements OnInit {
  // general error
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;

  editorConfig!: AngularEditorConfig;

  token!: string;
  isLoading = false;
  isSectionsLoading = true;
  sections$!: Observable<EditPlatformSection[]>;

  form: FormGroup = new FormGroup({
    name: new FormControl(this.data.name, [Validators.required]),
    section_id: new FormControl(this.data.section_id, [Validators.required]),
    observations: new FormControl(this.data.observations, [
      Validators.required,
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: FAQ,
    private dialogRef: MatDialogRef<EditPlatformFaqComponent>,
    private platformSectionService: PlatformSectionService,
    private platformFaqService: PlatformFaqService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  getSectionsList() {
    let platformResponse = this.platformSectionService.getPlatformSections();

    platformResponse.subscribe({
      next: (sections: any) => {
        this.sections$ = of(sections.data as EditPlatformSection[]);
        this.isSectionsLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isSectionsLoading = false;
      },
    });
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.platformFaqService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const { name, observations } = this.form.value as FAQForm;
    const updateFAQ: FAQUpdatePayload = {
      id: this.data.id,
      name,
      observations,
    };

    this.platformFaqService
      .updateFAQ(updateFAQ)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            helper.dialogConfig.headers.errorAtentie,
            msg,
            this.errorIcon
          );
        },
      });
  }

  onHttpError(res: any) {
    if (res.errors && res.errors.message && res.errors.message.length > 0) {
      res.errors.message.forEach((msg: string) => {
        this.notificationService.warningSwal(
          this.errorTitle,
          msg,
          this.errorIcon
        );
      });
    } else {
      let msg;
      try {
        // try to catch the message from server
        msg = res.error.errors.message[0];
      } catch (error) {
        // controll output message
        msg = helper.dialogConfig.generalMessages.error;
      }
      this.notificationService.warningSwal(
        this.errorTitle,
        msg,
        this.errorIcon
      );
    }
  }

  ngOnInit(): void {
    this.editorConfig = initializeEditor();
  }
}
