import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigAdminAddPayload } from './config-admin.interface';

@Injectable({
	providedIn: 'root'
})
export class ConfigAdminService {
	private basePath = environment.interop.basePath;
	private getConfigsAdminDT = this.basePath + environment.interop.platform.app.settings.configurations.getConfigurationsDT;
	private token!: string;
	private httpOptions: any;
	private storeConfigAdminURL = this.basePath + environment.interop.platform.app.settings.configurations.storeConfiguration;
	private deleteConfigAdminURL = this.basePath + environment.interop.platform.app.settings.configurations.deleteConfiguration;
	private getConfigAdminURL = this.basePath + environment.interop.platform.app.settings.configurations.getConfiguration;
	private getSlugConfigAdminUrl = this.basePath + environment.interop.platform.app.settings.configurations.getSlugConfiguration;
	private putConfigAdminURL = this.basePath + environment.interop.platform.app.settings.configurations.putConfiguration;

	constructor(private http: HttpClient) { }

	getConfigAdminDT() {
		return this.http.get(this.getConfigsAdminDT, this.httpOptions);
	}

	addConfigAdmin(configAdmin: ConfigAdminAddPayload) {
		return this.http.post(this.storeConfigAdminURL, configAdmin, this.httpOptions);
	}

	updateConfiguration(data: ConfigAdminAddPayload) {
		return this.http.put(this.putConfigAdminURL, data, this.httpOptions);
	}

	deleteConfiguration(ConfigId: number) {
		return this.http.delete(this.deleteConfigAdminURL + ConfigId, this.httpOptions);
	}

	getConfigurationById(ConfigId: number) {
		return this.http.get(this.getConfigAdminURL + ConfigId, this.httpOptions);
	}

	getConfigurationBySlug(ConfigSlug: string) {
		return this.http.get(this.getSlugConfigAdminUrl + ConfigSlug, this.httpOptions);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}
}
