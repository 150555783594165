<div class="add-container">
	<h1 class="add-title">Adaugă Tip Document</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Denumire</mat-label>
					<input type="text" matInput formControlName="name" />
					<mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Slug</mat-label>
					<input type="text" matInput formControlName="slug" />
					<mat-error *ngIf="!form.controls['slug'].valid && form.controls['slug'].touched && !form.get('slug')?.hasError('invalidCharacter')">
						Câmp obligatoriu
					</mat-error>
                    <mat-error *ngIf="form.get('slug')?.hasError('invalidCharacter')">
						Caracterele spațiu și punct nu sunt permise
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
					<mat-label>Nr. zile până la arhivare</mat-label>
					<input type="number" matInput formControlName="archive_auto_days" />
				</mat-form-field>
            </div>
        </div>

		<div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>