<div class="section position-relative">
	<div class="section-top">
		<h3 class="section-title">Dimensiuni Tip Imagini</h3>
		<div class="section-top-actions">
			<button type="button" class="btn btn-sm btn-info pull-right ms-1" (click)="rerenderDT(false)">
				<i class="fa-solid fa-arrows-rotate"></i>
			</button>
			<button type="button" 
                *ngIf="permissionsService.hasPermission('administrative.image-types.add')"
                class="btn btn-sm btn-primary pull-right ms-1" (click)="openAddDialog()">
				<i class="fa-solid fa-plus"></i>
				Adaugă
			</button>
		</div>
	</div>
	<div class="table-container position-relative">
		<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover w-100"></table>
	</div>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">Vă rugăm așteptați!</h3>
		</div>
	</div>
</div>