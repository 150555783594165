import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { Uat, UatEditPayload } from '../uat.interface';
import { UatService } from '../uat.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, finalize } from 'rxjs';
import { JudeteService } from '../../settings-sidenav/judete/judete.service';
import { notDefaultValidator } from 'src/app/shared/validators/not-default';
import { TipuriBeneficiarService } from '../../settings-sidenav/tipuri-beneficiar/tipuri-beneficiar.service';

@Component({
    selector: 'app-edit-uat',
    templateUrl: './edit-uat.component.html',
    styleUrls: ['./edit-uat.component.scss']
})
export class EditUatComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    judete$!: Observable<any[]>;
    beneficiari$!: Observable<any[]>;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl(this.data.name, [Validators.required]),
        judet_id: new FormControl(this.data.judet_id, [Validators.required, notDefaultValidator('-1')]),
        siruta: new FormControl(this.data.siruta, [Validators.required]),
        tip_beneficiar: new FormControl(this.data.tip_beneficiar, [Validators.required, notDefaultValidator('-1')]),
        observations: new FormControl(this.data.observations),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UatEditPayload,
        private dialogRef: MatDialogRef<EditUatComponent>,
        private uatService: UatService,
        private tipuriBeneficiarService: TipuriBeneficiarService,
        private judeteService: JudeteService,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
        this.processToken();
    }

    ngOnInit() {
        this.loadJudete();
        this.loadBeneficiaries();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.uatService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, judet_id, siruta, tip_beneficiar, observations } = this.form
            .value as Uat;

        const updateUat: UatEditPayload = {
            id: this.data.id,
            name,
            judet_id,
            siruta,
            tip_beneficiar,
            observations,
        };

        this.uatService
            .updateUat(updateUat)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let msg;
            try {
                // try to catch the message from server
                msg = res.error.errors.message[0];
            } catch (error) {
                // controll output message
                msg = helper.dialogConfig.generalMessages.error;
            }
            this.notificationService.warningSwal(
                this.errorTitle,
                msg,
                this.errorIcon
            );
        }
    }

    loadJudete() {
        this.isLoading = true;
        this.judete$ = this.judeteService
            .getJudeteActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    loadBeneficiaries() {
        this.isLoading = true;
        this.beneficiari$ = this.tipuriBeneficiarService
            .getTipuriBeneficiar()
            .pipe(finalize(() => (this.isLoading = false)));
    }
}
