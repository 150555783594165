import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class AppFilesService {
    downloadStarted: EventEmitter<void> = new EventEmitter<void>();
    downloadFinished: EventEmitter<void> = new EventEmitter<void>();

    constructor(private http: HttpClient, private notificationService: NotificationService) { }

    // ex filePath = '/assets/path-to-our-pdf-file.pdf';
    // ex fileName = 'our-pdf-file.pdf';
    downloadStaticFile(filePath: string, fileName: string): void {
        const link = document.createElement('a');
        link.href = filePath;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    }

    // fullPath ex: https://development.dgitl-core.webproductionlabs.com/cetatean/dgitl/document/{docId}
    async downloadFile(fullPath: string) {
        try {
            this.downloadStarted.emit();
            const config = { responseType: 'blob' };
            console.log(fullPath);
            this.http.get(fullPath, { responseType: 'blob' as 'json' })
                .subscribe((response: any) => {

                    let dataType = response.type;

                    // Check if the response is an image
                    if (dataType.startsWith('image')) {
                        // Open a new tab with the image
                        const imageUrl = window.URL.createObjectURL(response);
                        const newTab = window.open('', '_blank');

                        // Create a container element to hold the header and the image
                        const container = newTab?.document.createElement('div');
                        if (!container || !newTab?.document.body) return;

                        // Apply CSS styles to center the image
                        container.style.display = 'flex';
                        container.style.flexDirection = 'column';
                        container.style.alignItems = 'center';
                        container.style.justifyContent = 'center';
                        container.style.gap = '50px';

                        // Create the custom header HTML
                        const headerHTML = `
                            <div style="display: flex; justify-content: flex-start; align-items: center; gap: 15px; margin-top: 20px;">
                                <button id="downloadButton">Descarcă</button>
                                <button id="rotateButton">Rotire</button>
                            </div>

                            <style>
                                button {
                                    color: #0044cc;
                                    font-weight: 500;
                                    background-color: #fff;
                                    border: 1px solid #0044cc;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    font-size: 24px;
                                    padding: 10px 20px;
                                }

                                button:hover {
                                    background-color: #e6e6e6;
                                }
                            </style>
                        `;

                        const imageContainer = newTab?.document.createElement('div');
                        imageContainer.style.display = 'flex';
                        imageContainer.style.justifyContent = 'center';
                        imageContainer.style.alignItems = 'center';
                        imageContainer.style.maxWidth = '100%';

                        // Create an image element
                        const imageElement = newTab?.document.createElement('img');
                        if (!imageElement) return;
                        imageElement.src = imageUrl;
                        imageElement.style.maxWidth = '100%';

                        imageContainer.appendChild(imageElement);

                        // Append the header and the image to the container
                        const headerFragment = newTab.document.createRange().createContextualFragment(headerHTML);
                        container.appendChild(headerFragment);
                        container.appendChild(imageContainer);

                        // Append the container to the new tab's body
                        newTab.document.body.appendChild(container);

                        // Attach event listeners to the buttons
                        const downloadButton = newTab.document.getElementById('downloadButton');
                        const rotateButton = newTab.document.getElementById('rotateButton');
                        if (downloadButton && rotateButton) {
                            downloadButton.addEventListener('click', () => {
                                if (imageUrl) {
                                    const fileNameMatch = imageUrl.match(/\/([^\/]+)$/);
                                    if (fileNameMatch && fileNameMatch.length > 1) {
                                        const fileExtension = dataType.split('/')[1];
                                        const fileName = `${fileNameMatch[1]}.${fileExtension}`;

                                        const link = document.createElement('a');
                                        link.href = imageElement.src;
                                        link.download = fileName;

                                        // Simulate click on the link
                                        link.click();
                                    } else {
                                        console.error('Failed to extract file name from image URL');
                                    }
                                } else {
                                    console.error('Image URL is not valid');
                                }
                            });

                            rotateButton.addEventListener('click', () => this.rotateImage(imageElement));
                            this.downloadFinished.emit();
                        }
                    } else {
                        // For non-image file types, download the file
                        let binaryData = [];
                        binaryData.push(response);
                        let downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

                        // Open the file in a new tab
                        downloadLink.setAttribute('target', '_blank');
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        this.downloadFinished.emit();
                    }
                });
        } catch (err) {
            // Handle errors
            console.log(err, ' download error here ');
            this.downloadFinished.emit();
        }
    }

    // Function to rotate the image
    rotateImage(imageElement: HTMLImageElement) {
        console.log('Rotating image...');

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const img = new Image();
        img.crossOrigin = 'anonymous'; // Ensure cross-origin access
        img.onload = () => {
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(Math.PI / 2);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);

            // Convert canvas to image
            const rotatedImageUrl = canvas.toDataURL('image/png').startsWith('data:image/png') ? canvas.toDataURL('image/png') :
                (canvas.toDataURL('image/jpeg').startsWith('data:image/jpeg') ? canvas.toDataURL('image/jpeg') :
                    (canvas.toDataURL('image/jpg').startsWith('data:image/jpg') ? canvas.toDataURL('image/jpg') : canvas.toDataURL()));

            // Display the rotated image
            imageElement.src = rotatedImageUrl;
        };
        img.src = imageElement.src;
    }

    async downloadToClient(fullPath: string, filename: string) {
        try {
            this.http.get(fullPath, { responseType: 'blob' })
                .subscribe({
                    next: (data: Blob) => {
                        this.handleFileDownload(data, filename);
                    },
                    error: (result: any) => {
                        this.notificationService.handleHttpError(result.error);
                    }
                });
        } catch (err) {
            // response error pls
            console.log(err, ' download error here ')
        }
    }

    private handleFileDownload(data: Blob, filename: string) {
        const blob = new Blob([data], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // Specify the file name you want the file to be downloaded as
        link.download = filename;

        // Trigger the download
        link.click();
    }
}
