<div class="section position-relative">
    <div class="section-top">
        <h3 class="section-title">Management permisiuni instituție</h3>
    </div>

    <div class="row p-3">
        <form class="add-card__form" [formGroup]="form" (submit)="submit()">
            <div class="row">
                <div class="col add-field">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Utilizator</mat-label>
                        <mat-select formControlName="user_id" (selectionChange)="onUserChange($event.value)">
                            <mat-option *ngFor="let clientUser of clientUsersActive$ | async" [value]="clientUser.id">
                                {{ clientUser.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="add-action-btns pull-right">
                <button 
                    *ngIf="permissionsService.hasPermission('clients.permissions.update')"
                    class="buttons" type="submit" mat-flat-button color="primary">
                    {{ helper.dialogConfig.buttonLabels.save }}
                </button>
            </div>
        </form>
    </div>

    <div class="row p-3">
        <div class="add-field" *ngIf="groupPermissionsLoaded && allGroupsWithPermissions.length && !selectedPermissions.length">
            <p>Vă rugăm să selectați permisiunile</p>
        </div>
        <div *ngIf="groupPermissionsLoaded && !allGroupsWithPermissions.length; else permissionsContent">
            <p>Nu exista permisiuni pentru modulul selectat.</p>
        </div>

        <div class="add-field">
            <ng-template #permissionsContent>
                <div class="row pb-3" *ngFor="let group of allGroupsWithPermissions">
                    <mat-expansion-panel *ngIf="groupHasAnyPermissions(group)" expanded="true">
                        <mat-expansion-panel-header class="group-header">
                            <mat-panel-title>
                                <mat-checkbox color="primary" [(ngModel)]="group.checked" (click)="updateCheckedGroup($event, group)">
                                    {{group.name}}
                                </mat-checkbox>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <!-- subgroups -->
                        <div *ngIf="group.subgroups && group.subgroups.length">
                            <div *ngFor="let subgroup of group.subgroups" class="checkbox ps-5">
                                <mat-checkbox color="primary" [(ngModel)]="subgroup.checked" (click)="updateCheckedSubgroup($event, group, subgroup)">
                                    {{subgroup.name}}
                                </mat-checkbox>
                                <div *ngFor="let sgPermission of subgroup.permissions" class="checkbox ps-5">
                                    <mat-checkbox color="primary" [(ngModel)]="sgPermission.checked" (change)="updateCheckedList(sgPermission, group, subgroup)">
                                        {{sgPermission.name}}
                                    </mat-checkbox>
                                </div>
                                <hr>
                            </div>
                        </div>

                        <div *ngIf="group.permissions && group.permissions.length">
                            <div *ngFor="let permission of group.permissions" class="checkbox ps-5">
                                <mat-checkbox color="primary" [(ngModel)]="permission.checked" (change)="updateCheckedList(permission, group, null)">
                                    {{permission.name}}
                                </mat-checkbox>
                            </div>
                            <hr>
                        </div>
                    </mat-expansion-panel>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="section-spinner position-absolute" *ngIf="isLoading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
        </div>
    </div>

</div>