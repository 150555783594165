<div class="section position-relative">
	<div class="section-top">
		<h3 class="section-title">Configurări admin</h3>
		<div class="section-top-actions">
			<button type="button" class="btn btn-sm btn-info pull-right ms-1" (click)="rerenderDT(false)">
				<i class="fa-solid fa-arrows-rotate"></i>
			</button>
			<button type="button" class="btn btn-sm btn-primary pull-right ms-1" (click)="openAddDialog()"
                *ngIf="permissionsService.hasPermission('administrative.configurations.add')"
            >
				<i class="fa-solid fa-plus"></i>
				Adaugă
			</button>
		</div>
	</div>
	<!-- <div class="card-container" *ngIf="showCard"> -->
	<!-- <div class="card">
			<label>Activ</label>
			<select [(ngModel)]="activeValue" (change)="rerenderDT(true)">
				<option *ngFor="let param of parameters" [value]="param.value">
					{{ param.key }}
				</option>
			</select>
		</div> -->
	<!-- <div class="card">
			<label>Public</label>
			<select [(ngModel)]="publicValue" (change)="rerenderDT(true)">
				<option *ngFor="let param of parameters" [value]="param.value">
					{{ param.key }}
				</option>
			</select>
		</div> -->
	<!-- </div> -->
	<!-- <div class="col-sm-12 ps-3">
		<button type="button" class="btn btn-sm btn-default" (click)="toggleCard()" [class.active]="showCard">
			<i class="fa-solid fa-filter"></i> Filtre
		</button>
	</div> -->
	<div class="table-container position-relative">
		<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover w-100"></table>
	</div>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>