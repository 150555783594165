import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUatComponent } from './add-uat/add-uat.component';
import { EditUatComponent } from './edit-uat/edit-uat.component';
import { UatComponent } from './uat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    declarations: [
        UatComponent,
        AddUatComponent,
        EditUatComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    exports: [UatComponent]
})
export class UatModule { }
