import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesLayoutComponent } from './pages-layout.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'pages/login',
        component: PagesLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../../auth-module/login/login.module').then((m) => m.LoginModule)
            }
        ]
    }
]

@NgModule({
    declarations: [PagesLayoutComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ]
})
export class PagesLayoutModule { }
