import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformContentService } from '../platform-content.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { EditPlatformContentPayload } from '../platform-content.interface';
import { finalize, Observable } from 'rxjs';

import { helper } from 'src/environments/helper';
import { EditPlatformSection } from '../../platform-section/platform-section.interface';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';

@Component({
  selector: 'app-edit-platform-content',
  templateUrl: './edit-platform-content.component.html',
  styleUrls: ['./edit-platform-content.component.scss'],
})
export class EditPlatformContentComponent implements OnInit {
  // general error
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;
  editorConfig!: AngularEditorConfig;

  token!: string;
  isLoading = false;
  isSectionsLoading = true;
  sections$!: Observable<EditPlatformSection[]>;

  form: FormGroup = new FormGroup({
    title: new FormControl(this.data.title, Validators.required),
    slug: new FormControl(this.data.slug, Validators.required),
    content: new FormControl(this.data.content, Validators.required),
    observations: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<EditPlatformContentComponent>,
    private platformContentService: PlatformContentService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.platformContentService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const updatedPlatformContent: EditPlatformContentPayload = {
      title: this.form.get('title')?.value,
      content: this.form.get('content')?.value,
      observations: this.form.get('observations')?.value,
      id: this.data.id,
    };

    this.platformContentService
      .updatePlatformContent(updatedPlatformContent)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        },
      });
  }

  ngOnInit(): void {
    this.editorConfig = initializeEditor();
  }
}
