import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  openToast(object: any) {
    const { type, message, title } = object;

    let options = this.toastr.toastrConfig;
    options.progressBar = true;
    options.preventDuplicates = true;
    options.closeButton = true;

    const inserted = this.toastr.show(
      message,
      title,
      options,
      options.iconClasses[type]
    );
  }
}
