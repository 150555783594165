import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-request-history',
  templateUrl: './request-history.component.html',
  styleUrls: ['./request-history.component.scss'],
})
export class RequestHistoryComponent {
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  helper = helper;

  isLoading = false;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance!: DataTables.Api;

  dtLink =
    environment.interop.basePath +
    environment.interop.platform.nomenclatoare.cereriHistory
      .getCereriHistoryListDT;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.renderDT();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
  }

  renderDT() {
    this.isLoading = true;
    this.dtOptions = {
      serverSide: true,
      searching: false,
      pageLength: 25,
      ajax: (dataTablesParameters: any, callback: any) => {
        this.isLoading = true;
        this.http
          .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
          .subscribe((resp: any) => {
            this.isLoading = false;
            callback({
              recordsTotal: resp.data.recordsTotal,
              recordsFiltered: resp.data.recordsFiltered,
              data: resp.data.data,
            });
          });
      },
      language: environment.config.romanianLanguageDataTable,
      columns: [
        {
          title: 'ID',
          data: 'id',
        },
        {
          title: 'Acțiuni',
          className: 'all',
          orderable: false,
          render: (data: any, type: any, row: any, met: any) => {
            return `
            <div class="d-flex align-center justify-content-center">
							<button class="action-btn edit">
								<img class="btn-img" src="/assets/icons/edit.svg" alt="edit"/>
							</button>
              <button class="action-btn delete">
              <img class="btn-img" src="../../../../assets/icons/delete.svg" alt="delete"/>
              </button>
              </div>
						`;
          },
        },
      ],
      rowCallback: (row: Node, data: any[] | any) => {
        const lastTd = (row as HTMLElement).querySelector('td:last-child');
        const editIcon = lastTd?.querySelector('.edit');
        const deleteIcon = lastTd?.querySelector('.delete');

        // if (editIcon) {
        //   editIcon.addEventListener('click', () => {
        //     this.openEditDialog(data);
        //   });
        // }

        // if (deleteIcon) {
        //   deleteIcon.addEventListener('click', () => {
        //     const { id_client } = data;
        //     this.deleteClient(id_client);
        //   });
        // }

        return row;
      },
    };
  }

  rerenderDT(paginate: boolean = false): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(paginate);
    });
  }

  openAddDialog() {
    console.log('Not implemented yet');
  }
}
