import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { JudeteService } from '../judete.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';
import { helper } from 'src/environments/helper';
@Component({
    selector: 'app-edit-judet',
    templateUrl: './edit-judet.component.html',
    styleUrls: ['./edit-judet.component.scss'],
})
export class EditJudetComponent {
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;

    form: FormGroup = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        region: new FormControl(this.data.region, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private dialogRef: MatDialogRef<EditJudetComponent>,
        private judetService: JudeteService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.judetService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const updatedJudetData: any = {
            name: this.form.get('name')?.value,
            region: this.form.get('region')?.value,
            id: this.data.id,
        };

        this.judetService
            .updateJudet(updatedJudetData)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                },
            });
    }
}
