<div class="add-container">
	<h1 class="add-title">Adaugă compartiment</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">

        <!-- <div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>ID Direcție</mat-label>
				<input type="number" matInput formControlName="directie_id" />
				<mat-error *ngIf="!form.controls['directie_id'].valid && form.controls['directie_id'].touched">
					Câmp obligatoriu
				</mat-error>
			</mat-form-field>
		</div> -->

		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Direcție/Instituție</mat-label>
					<mat-select formControlName="directie_id">
						<mat-option *ngFor="let directie of directiiActive$ | async" [value]="directie.id">
							{{ directie.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Denumire compartiment</mat-label>
					<input type="text" matInput formControlName="name" />
					<mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Observații</mat-label>
					<input type="text" matInput formControlName="description" />
					<mat-error *ngIf="
				!form.controls['description'].valid &&
				form.controls['description'].touched
			  ">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>