<div class="edit-container">
    <h1 class="edit-title">Actualizare parolă</h1>
    <form class="edit-card__form" [formGroup]="form" (submit)="submitForm()">

        <div class="edit-field">
            <mat-form-field appearance="fill">
                <mat-label>Parolă curentă</mat-label>
                <input type="text" matInput required [type]="hideTP ? 'password' : 'text'" placeholder="Ex. kmbsd88$98"
                    formControlName="temporary_password" />
                <button mat-icon-button type="button" matSuffix (click)="hideTP = !hideTP"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideTP">
                    <mat-icon>{{ hideTP ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
                <mat-hint>Introduceți parola</mat-hint>
                <mat-error *ngIf="
              !form.controls['temporary_password'].valid &&
              form.controls['temporary_password'].touched
            ">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>
        </div>

        <div class="edit-field">
            <mat-form-field appearance="fill">
                <mat-label>Parolă nouă</mat-label>
                <input type="text" matInput required [type]="hideNP ? 'password' : 'text'" placeholder="Ex. kmbsd88$98"
                    formControlName="new_password" />
                <button mat-icon-button type="button" matSuffix (click)="hideNP = !hideNP"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNP">
                    <mat-icon>{{ hideNP ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
                <mat-hint>Introduceți parola</mat-hint>
                <mat-error *ngIf="
              !form.controls['new_password'].valid &&
              form.controls['new_password'].touched
            ">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>
        </div>

        <div class="edit-field">
            <mat-form-field appearance="fill">
                <mat-label>Confirmare parolă nouă</mat-label>
                <input type="text" matInput required [type]="hideCP ? 'password' : 'text'" placeholder="Ex. kmbsd88$98"
                    formControlName="confirm_new_password" />
                <button mat-icon-button type="button" matSuffix (click)="hideCP = !hideCP"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideCP">
                    <mat-icon>{{ hideCP ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
                <mat-hint>Introduceți parola</mat-hint>
                <mat-error *ngIf="
              !form.controls['confirm_new_password'].valid &&
              form.controls['confirm_new_password'].touched
            ">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>
        </div>

        <div class="edit-field">
            <br/>
            <hr/>
            <br/>
            <p class="red-text"><small>Parola necesită actualizare în cazul unui cont nou înregistrat sau schimbari de parolă de către un admin.</small></p>
            <p><small>Noua Parolă trebuie sa fie diferită față de Parola Curentă, cât și de cele anterioare </small></p>
            <p><small>Criterii de validare parolă:</small></p>
            <p><small>- trebuie să fie între 8 și 50 de caractere</small></p>
            <p><small>- trebuie să conțină cel putin un caracter special, o cifră și o literă mare</small></p>
        </div>

        <div class="edit-action-btns">
            <button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
                Închide
            </button>
            <button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
                Salvează
            </button>
        </div>
    </form>
    <div class="section-spinner position-absolute" *ngIf="isLoading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">Vă rugăm așteptați!</h3>
        </div>
    </div>
</div>