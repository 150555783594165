import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { helper } from '../../../environments/helper';

export interface DialogData {
  title: string;
  msg: string;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  dialogRef: MatDialogRef<any> | undefined;
  helperData: any = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.helperData = helper;
  }

  closeDialog() {
    this.dialogRef?.close();
  }
}
