import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
    selector: 'app-subsemnatul',
    templateUrl: './subsemnatul.component.html',
    styleUrls: ['./subsemnatul.component.scss'],
})
export class SubsemnatulComponent implements OnChanges {

    @Input() cerere: any;
    @Input() cerereCetatean: any;
    isPersoanaFizica = true;
    isPersoanaJuridica = true;
    selectedPFStatus = 'info';
    selectedPJStatus = 'info';
    selectedPJFiles = 'info';
    //   cerere!: Cerere;

    ngOnChanges(changes: SimpleChanges): void {
        console.log(this.cerere, 'CERERE');
        console.log(this.cerereCetatean, 'CERERE Cetatean');
    }

    mockUserPF = {
        nume: 'Stanciu',
        prenume: 'Sergiu',
        email: 'sstanciu@webproductionlabs.com',
        telefon: '+07632001352',
        adresa: {
            strada: 'Cucuietii din deal',
            numar: '69',
            bloc: 'E69',
            scara: 'E',
            apartament: '69',
        },
    };

    mockUserPJ = {
        nume: 'Greener Town SRL',
        cui: '452325',
        nrInmatriculare: 'J40/19801/2017',
        reprezentant: 'Împuternicit',
        sediu: {
            strada: 'Cucuietii din deal',
            numar: '69',
            bloc: 'E69',
            scara: 'E',
            apartament: '69',
        },
        punctDeLucru: {
            strada: 'TESTTEST',
            numar: '22',
            bloc: 'TEST22',
            scara: 'E',
            apartament: '69',
        },
        suplimentare: {
            caen: '9529, 9601',
            observatii: 'N/A',
        },
        orar: {
            orar1: 'TEST',
            orar2: 'TEST2',
        },
    };

    onPFInfoChange(event: MatButtonToggleChange) {
        console.log(event.value);
        this.selectedPFStatus = event.value;
    }

    onPJInfoChange(event: MatButtonToggleChange) {
        this.selectedPJStatus = event.value;
    }

    onPJInfoFilesChange(event: MatButtonToggleChange) {
        console.log(event.value);
        this.selectedPJFiles = event.value;
    }
}
