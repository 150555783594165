import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformSectionComponent } from './platform-section.component';
import { MaterialModule } from 'src/app/material.module';
import { AddPlatformSectionComponent } from './add-platform-section/add-platform-section.component';
import { EditPlatformSectionComponent } from './edit-platform-section/edit-platform-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PlatformSectionComponent, AddPlatformSectionComponent, EditPlatformSectionComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [PlatformSectionComponent]
})
export class PlatformSectionModule { }
