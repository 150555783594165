<div class="settings-page">
	<div class="settings-page__content">
		<div class="settings-card">
			<div class="settings-card__header">
				<img src="/assets/images/Group 24.svg" alt="" />
				<div class="settings-card__header-headline">
					<h2>Bine ați venit!</h2>
					<h4>Setări Generale - Portal Operator {{env.config.appName}}</h4>
				</div>
			</div>
		</div>
	</div>

	<div class="content-box">
		<div class="settings-page-container">
			<mat-button-toggle-group [value]="selectedComponent" (change)="onChange($event)" class="settings-page_menu">
				<mat-button-toggle [value]="option" class="settings-page_menu-item"
					*ngFor="let option of filteredComponents">
					<div style="text-align: left;">
						{{ option }}
					  </div>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div class="settings-page__content">
            <ng-container *ngIf="selectedComponent === SidebarSettings.UTILIZATORI">
                <app-users-settings> </app-users-settings>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.INSTITUTII">
                <app-institutii-directii></app-institutii-directii>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.FUNCTII">
                <app-functii></app-functii>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.COMPARTIMENTE">
                <app-compartimente></app-compartimente>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.PLATFORM_SECTION">
                <app-platform-section></app-platform-section>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.PLATFORM_FAQ">
                <app-platform-faq></app-platform-faq>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.PLATFORM_CONTENT">
                <app-platform-content></app-platform-content>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.PLATFORM_ANNOUNCES">
                <app-platform-announces></app-platform-announces>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.JUDETE">
                <app-judete></app-judete>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.SOLICITARE_TYPES">
                <app-cerere-type></app-cerere-type>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.SOLICITARE_STATUS">
                <app-cerere-status></app-cerere-status>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.SOLICITARE_PRIORITY">
                <app-cerere-priority></app-cerere-priority>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.SOLICITARE_SERIES">
                <app-cerere-series></app-cerere-series>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.TIPURI_DOCUMENTE">
                <app-tipuri-documente></app-tipuri-documente>
            </ng-container>
            <ng-container *ngIf="selectedComponent === SidebarSettings.TIPURI_BENEFICIAR">
                <app-tipuri-beneficiar></app-tipuri-beneficiar>
            </ng-container>

			<div class="row" *ngIf="selectedComponent == null">
				<div class="col-sm-12 py-5">
					<div class="row h-100 justify-content-center align-items-center">
						<div class="col-12 text-center">
							<div class="py-3">
								<p class="mb-0">Selectați o secțiune</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>