<div class="settings-card">
    <div class="settings-card__header">
        <img src="/assets/images/Group 24.svg" alt="" />
        <div class="settings-card__header-headline">
            <h2 class="m-0 p-0">Șabloane Solicitări</h2>
        </div>
    </div>
</div>

<div class="settings-page">
	<div class="content-box">
		<div class="row">
			<div class="col-sm-12">
				<div class="section">
					<div class="section-top">
						<h3 class="section-title">Șabloane Solicitări</h3>
						<div class="section-top-actions">
							<button type="button" class="btn btn-sm btn-info pull-right ms-1"
								(click)="rerenderDT(false)">
								<i class="fa-solid fa-arrows-rotate"></i>
							</button>
							<button type="button"
                                *ngIf="permissionsService.hasPermission('sabloane-cerere.add')"
                                class="btn btn-sm btn-primary pull-right ms-1"
								(click)="openAddDialog()">
								<i class="fa-solid fa-plus"></i>
								Adaugă
							</button>
						</div>
					</div>
					<div class="card-container p-2" *ngIf="showCard">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-label>Activ</mat-label>
                                    <mat-select [(ngModel)]="activeValue" (change)="rerenderDT()">
                                        <mat-option *ngFor="let param of parameters" [value]="param.value" (change)="rerenderDT()">
                                            {{ param.key }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                      
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-label>Public</mat-label>
                                    <mat-select [(ngModel)]="publicValue" (change)="rerenderDT()">
                                        <mat-option *ngFor="let param of parameters" [value]="param.value">
                                            {{ param.key }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="col d-flex justify-content-start">
                                <button (click)="toggleCard()" type="button" class="btn btn-sm btn-warning closeBtn">Închide</button>
                            </div>
                            <div class="col buttons-submit">
                                <button (click)="resetForm()" type="button" class="btn btn-sm btn-secondary">
                                    Resetează
                                </button>
                                <button (click)="applyFilters()" type="submit" class="btn btn-sm btn-primary ms-2">
                                    Aplică
                                </button>
                            </div>
                        </div>
                    </div>    

                    <div class="p-2">
                        <div class="d-flex justify-content-start">
                            <button class="action-button d-flex align-items-center" (click)="toggleCard()" [class.active]="showCard">
                                <img src="assets/images/icons8-filter-edit-50.png" alt="" />
                                <span>Filtre</span>
                            </button>
                        </div>
                    </div>            
                    
					<div class="table-container">
						<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover w-100">
						</table>
					</div>
					<div class="section-spinner position-absolute" *ngIf="isLoading">
						<div class="spinner position-absolute top-50 start-50 translate-middle">
						    <mat-spinner></mat-spinner>
						    <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>