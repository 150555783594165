<div class="add-container">
    <h1 class="add-title">Adaugă operator</h1>
    <form class="add-card__form" [formGroup]="form" (submit)="submitForm()">

        <!-- <div class="row">
            <div class="col add-field">
                <mat-form-field appearance="fill">
                    <mat-label>Modul</mat-label>
                    <mat-select formControlName="module_id">
                        <mat-option *ngFor="let clientModule of clientModulesActive$ | async" [value]="clientModule.id">
                            {{ clientModule.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
        <!-- 
        <div class="row">
            <div class="col add-field">
                <mat-form-field appearance="fill">
                    <mat-label>Utilizator</mat-label>
                    <mat-select formControlName="user_id">
                        <mat-option *ngFor="let user of usersActive$ | async" [value]="user.id_user">
                            {{ user.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
 -->

        <div class="row">
            <mat-form-field class="w-100">
                <mat-label>Adaugă utilizator</mat-label>

                <mat-chip-grid #chipGrid aria-label="Selectare utilizator">
                    <mat-chip-row *ngFor="let user of selectedUsers" (removed)="removeUser(user)">
                        {{user.name}}
                        <button matChipRemove [attr.aria-label]="'remove ' + user.name">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>

                <input placeholder="Utilizator..." #userInput [formControl]="usersCtrl" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addUser($event)" />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUserSelected($event)">
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id_user">
                        {{user.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>



        <div class="add-action-btns">
            <button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
                {{ helper.dialogConfig.buttonLabels.close }}
            </button>
            <button class="buttons" type="submit" mat-flat-button color="primary">
                {{ helper.dialogConfig.buttonLabels.save }}
            </button>
        </div>
    </form>

    <div class="section-spinner position-absolute" *ngIf="isLoading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
        </div>
    </div>
</div>