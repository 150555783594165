import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/utils/services/toast.service';
import { helper } from 'src/environments/helper';
import { GalleryService } from '../../gallery.service';
import { Image } from '../../picture';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-add-picture',
  templateUrl: './add-picture.component.html',
  styleUrls: ['./add-picture.component.scss'],
})
export class AddPictureComponent {
  // general error
  errorTitle: string = environment.config.customNotifications.headers.error;
  errorIcon: string = environment.config.customNotifications.icons.error;
  errorType: string = environment.config.customNotifications.icons.error;
  // general success alert
  successTitle: string = environment.config.customNotifications.headers.success;
  successIcon: string = environment.config.customNotifications.icons.success;
  successType: string = environment.config.customNotifications.icons.success;
  helper = helper;

  isLoading = false;
  uploading = false;

  images: Image[] = [];

  fileForm!: FormGroup;

  constructor(
    private notificationService: NotificationService,
    public toastr: ToastrService,
    public dialog: MatDialog,
    private toastService: ToastService,
    private galleryService: GalleryService,
    private dialogRef: MatDialogRef<AddPictureComponent>,
    private fb: FormBuilder
  ) {
    this.fileForm = this.fb.group({
      files: [null, Validators.required],
    });
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  removeFile() {
    this.fileForm.get('file')?.reset();
    const fileInput = document.getElementById(
      'upload-file-input-0'
    ) as HTMLInputElement;

    if (fileInput) {
      fileInput.value = '';
    }
  }

  addFile(event: any) {
    event.preventDefault();
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files || inputElement.files.length === 0) {
      return;
    }

    const file = inputElement.files[0];
    this.uploading = true;

    const formData = new FormData();
    formData.append('images', file);

    this.galleryService
      .fileUpload(formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.dialogRef?.close({
            success: true,
          });
        })
      )
      .subscribe({
        next: (result: any) => {
          if (result && result.status_code === 200 && result.data) {
            this.fileForm.get('files')?.setValue(result.data);

            this.uploading = false;
            this.toastService.openToast({
              title: this.successTitle,
              message: 'Fișierul dvs. a fost adăugat cu succes.',
              type: this.successIcon,
            });
          } else {
            let errorMessage =
              environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(
              this.errorTitle,
              errorMessage,
              this.errorIcon
            );
            this.uploading = false;
          }
        },
        error: (error: any) => {
          this.isLoading = false;
          this.uploading = false;
          this.onHttpError(error);
        },
      });
  }

  onHttpError(res: any, showErrorsInToast: boolean = false) {
    if (res.errors && res.errors.message && res.errors.message.length > 0) {
      res.errors.message.forEach((msg: string) => {
        if (showErrorsInToast) {
          this.toastService.openToast({
            title: this.errorTitle,
            message: msg,
            type: this.errorType,
          });
        } else {
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        }
      });
    } else {
      let errorMessage =
        environment.config.customNotifications.generalMessages.error;
      this.notificationService.warningSwal(
        this.errorTitle,
        errorMessage,
        this.errorIcon
      );
    }
  }
}
