<div class="container-fluid p-4">
    <div class="row justify-content-between">
        <h2 class="w-auto m-0">Nume instituție: {{data.name}}</h2>

        <button type="button" class="action-btn w-auto" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="content-box">

        <div class="row">

            <div class="col-sm-12">
                <div class="row">
                    <div class="col-md-3">
                        <div class="d-flex flex-column">
                            <mat-button-toggle-group [value]="selectedComponent" (change)="onChange($event)" class="menu m-0">
                                <mat-button-toggle [value]="option" class="menu-item" *ngFor="let option of componentKeys">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="settings-page__content">

                            <ng-container *ngIf="selectedComponent === InfoClientSidebar.GENERAL">
                                <app-client-general-info [client]="data"></app-client-general-info>
                            </ng-container>
                            <ng-container *ngIf="selectedComponent === InfoClientSidebar.CONTACTS">
                                <app-client-contacts [client]="data"></app-client-contacts>
                            </ng-container>
                            <ng-container *ngIf="selectedComponent === InfoClientSidebar.OPERATORS">
                                <app-client-operators [client]="data"></app-client-operators>
                            </ng-container>
                            <ng-container *ngIf="selectedComponent === InfoClientSidebar.PERMISSIONS">
                                <app-client-permissions></app-client-permissions>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>