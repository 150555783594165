import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipuriDocumenteComponent } from './tipuri-documente.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddTipuriDocumenteComponent } from './add-tipuri-documente/add-tipuri-documente.component';
import { EditTipuriDocumenteComponent } from './edit-tipuri-documente/edit-tipuri-documente.component';

@NgModule({
    declarations: [
        TipuriDocumenteComponent,
        AddTipuriDocumenteComponent,
        EditTipuriDocumenteComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [TipuriDocumenteComponent]
})
export class TipuriDocumenteModule { }
