import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { Uat, UatEditPayload } from './uat.interface';

@Injectable({
    providedIn: 'root'
})
export class UatService {
    private token!: string;
    private httpOptions: any;
    private basePath = environment.interop.basePath;

    private getUatsUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.getUats;
    private getUatUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.getUat;
    private storeUatUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.storeUat;
    private deleteUatUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.deleteUat;
    private updateUatUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.putUat;
    private getUatsActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.getUatsActive;
    private changeStatusActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.changeStatusUatActive;
    private changeStatusPublicUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.changeStatusUatPublic;
    private searchUatUrl = this.basePath + environment.interop.platform.nomenclatoare.uat.searchUat;

    constructor(private http: HttpClient) { }

    getUats() {
        return this.http.get(this.getUatsUrl, this.httpOptions);
    }

    addUat(data: Uat) {
        return this.http.post(this.storeUatUrl, data, this.httpOptions);
    }

    getUatsActive() {
        return this.http.get(this.getUatsActiveUrl, this.httpOptions);
    }

    updateUat(data: UatEditPayload) {
        return this.http.put(this.updateUatUrl, data, this.httpOptions);
    }

    deleteUat(id: number) {
        return this.http.delete(this.deleteUatUrl + id);
    }

    changeStatuseActive(id: number) {
        return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
    }

    changeStatusePublic(id: number) {
        return this.http.put(this.changeStatusPublicUrl + id, {}, this.httpOptions);
    }

    searchUats(payload: string) {
        return this.http
            .post<{ errors: boolean; data: any[]; status_code: number }>(this.searchUatUrl, { search_val: payload })
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get uats:', error);
                    return throwError(error);
                })
            );
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }
}
