import { Component, OnDestroy, OnInit } from '@angular/core';
import { helper } from 'src/environments/helper';
import { RapoarteService } from './rapoarte.service';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-rapoarte',
    templateUrl: './rapoarte.component.html',
    styleUrls: ['./rapoarte.component.scss']
})
export class RapoarteComponent implements OnInit, OnDestroy {
    helper = helper;

    isLoading = false;
    isAutoRefreshEnabled = true;

    beneficiaries!: any;
    general!: any;
    regions!: any;

    intervalId: any;
    countdownIntervalId: any;
    remainingTime: number = 120;

    colors4: string[] = ['#2A9D8F', '#E9C46A', '#BF9DEA', '#FFA38B'];
    colors6: string[] = ['#264653', '#2A9D8F', '#E9C46A', '#BF9DEA', '#7BA3F3', '#FFA38B'];

    constructor(private rapoarteService: RapoarteService) { }

    ngOnInit(): void {
        this.getRapoarte();

        if (this.isAutoRefreshEnabled) {
            this.startAutoRefresh();
            this.startCountdown();
        }
    }

    ngOnDestroy(): void {
        this.stopAutoRefresh();
        this.stopCountdown();
    }

    startAutoRefresh(): void {
        this.intervalId = setInterval(() => {
            if (this.isAutoRefreshEnabled) {
                this.getRapoarte();
            }
        }, 120000);
    }

    stopAutoRefresh(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    startCountdown(): void {
        this.countdownIntervalId = setInterval(() => {
            if (this.remainingTime > 0) {
                this.remainingTime--;
            } else {
                this.remainingTime = 120;
            }
        }, 1000); // Update every second
    }

    stopCountdown(): void {
        if (this.countdownIntervalId) {
            clearInterval(this.countdownIntervalId);
            this.countdownIntervalId = null;
        }
    }

    toggleAutoRefresh(): void {
        this.isAutoRefreshEnabled = !this.isAutoRefreshEnabled;
        this.remainingTime = 120;

        if (this.isAutoRefreshEnabled) {
            this.startAutoRefresh();
            this.startCountdown();
        } else {
            this.stopAutoRefresh();
            this.stopCountdown()
        }
    }

    get formattedRemainingTime(): string {
        const minutes = Math.floor(this.remainingTime / 60);
        const seconds = this.remainingTime % 60;
        return `${minutes == 0 ? seconds + ' secunde' : minutes + ':' + `${seconds < 10 ? '0' : ''}${seconds}` + ' minute'}`;
    }

    getRapoarte() {
        this.isLoading = true;
        this.remainingTime = 120;
        this.rapoarteService.getRapoarte()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response: any) => {
                    this.general = response.data.general;
                    this.regions = response.data.regions;
                    this.beneficiaries = response.data.beneficiaries;
                },
                error: (error) => {
                    console.log('Error in get rapoarte: ', error);
                }
            })
    }

    getLabel(key: string): string {
        const labels: any = {
            numar_solicitari_depuse: "Solicitări - total (licitație)",
            numar_solicitari_documentatie_incarcata: "Solicitări - total (documentație încărcată)",
            numar_solicitari_aprobate: "Solicitări aprobate/eligibile",
            numar_solicitari_respinse: "Solicitări respinse/neeligibile"
        }

        return labels[key] || key;
    }

    beneficiariesKeys(): string[] {
        return Object.keys(this.beneficiaries || {});
    }

    generalKeys(): string[] {
        return Object.keys(this.general || {});
    }

    regionKeys(): string[] {
        return Object.keys(this.regions || {});
    }

    // If needed, get keys for specific region
    regionSubKeys(regionKey: string): string[] {
        return Object.keys(this.regions[regionKey] || {});
    }

    calculateGeneralPercentage(value: number): number {
        const total = this.general.numar_solicitari_depuse;

        return total ? (value / total) * 100 : 0;
    }

    calculateRegionData(regionKey: string, valueKey?: string, index: number = 0): number {
        const region = this.regions[regionKey];
        const generalTotal = this.general.numar_solicitari_depuse;

        if (index === 0) {
            // On first run, return the region's contribution to the general total
            return generalTotal ? (region.numar_solicitari_depuse / generalTotal) * 100 : 0;
        } else if (valueKey) {
            // On subsequent runs, return the percentage for a specific value within the region
            const total = region.numar_solicitari_depuse;
            return total ? (region[valueKey] / total) * 100 : 0;
        }

        return 0;
    }
}
