import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddUserTypePayload, EditUserTypePayload } from './user-type.interface';

@Injectable({
	providedIn: 'root',
})
export class UserTypeService {
	private token!: string;
	private httpOptions: any;

	private basePath = environment.interop.basePath;
	private getUserTypesDTUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.userType.getUserTypesDT;
	private storeUserTypesURL =
		this.basePath +
		environment.interop.platform.nomenclatoare.userType.storeUserTypes;
	private updateUserTypesUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.userType.updateUserType;
	private getUserTypeActiveURL =
		this.basePath +
		environment.interop.platform.nomenclatoare.userType.getUserTypeActive;
	private getUserTypeByIdURL =
		this.basePath +
		environment.interop.platform.nomenclatoare.userType.getUserType;
	private changeStatusUserTypeActiveURL =
		this.basePath +
		environment.interop.platform.nomenclatoare.userType
			.changeStatusUserTypeActive;

	constructor(private http: HttpClient) { }

	getUserTypesDT() {
		return this.http.get(this.getUserTypesDTUrl, this.httpOptions);
	}

	addUserType(userType: AddUserTypePayload) {
		return this.http.post(this.storeUserTypesURL, userType, this.httpOptions);
	}

	updateUserType(userType: EditUserTypePayload) {
		return this.http.put(this.updateUserTypesUrl, userType, this.httpOptions);
	}

	getActiveUserTypes() {
		return this.http.get(this.getUserTypeActiveURL, this.httpOptions);
	}

	getUserTypeById(id: number) {
		return this.http.get(this.getUserTypeByIdURL + id, this.httpOptions);
	}

	changeStatusActive(id: number) {
		return this.http.get(
			this.changeStatusUserTypeActiveURL + id,
			this.httpOptions
		);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}
}
