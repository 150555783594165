import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-module',
  templateUrl: './auth-module.component.html',
  styleUrls: ['./auth-module.component.scss']
})
export class AuthModuleComponent {

}
