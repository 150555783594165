import { Component, OnInit } from '@angular/core';
import { Observable, finalize, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EditPlatformSection } from '../../platform-section/platform-section.interface';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PlatformAnnouncesService } from '../platform-announces.service';
import { PlatformSectionService } from '../../platform-section/platform-section.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';
import { helper } from 'src/environments/helper';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-add-platform-announces',
    templateUrl: './add-platform-announces.component.html',
    styleUrls: ['./add-platform-announces.component.scss'],
})
export class AddPlatformAnnouncesComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    editorConfig!: AngularEditorConfig;

    token!: string;
    isLoading = false;
    isSectionsLoading = true;
    platformSections$!: Observable<EditPlatformSection[]>;

    form: FormGroup = new FormGroup({
        title: new FormControl('', Validators.required),
        content: new FormControl('', Validators.required),
        date_start: new FormControl('', Validators.required),
        date_end: new FormControl('', [this.validateDateRange]),
        section_id: new FormControl('', Validators.required),
    });

    constructor(
        private dialogRef: MatDialogRef<AddPlatformAnnouncesComponent>,
        private platformAnnouncesService: PlatformAnnouncesService,
        private platformSectionsService: PlatformSectionService,
        private notificationService: NotificationService,
        private authService: AuthService,
        private datePipe: DatePipe
    ) {
        this.processToken();
    }

    ngOnInit(): void {
        this.getSectionsList();
        this.form.get('date_end')?.updateValueAndValidity();
        this.editorConfig = initializeEditor();
    }

    validateDateRange(control: AbstractControl) {
        const startDate = control.parent?.get('date_start')?.value;
        const endDate = control.value;

        if (startDate && endDate && startDate > endDate) {
            return { dateRangeError: true };
        }

        return null;
    }

    getSectionsList() {
        this.platformSections$ = this.platformSectionsService.getPlatformSections();
        this.platformSections$
            .pipe(finalize(() => (this.isSectionsLoading = false)))
            .subscribe({
                next: (platformSections) => {
                    this.platformSections$ = of(platformSections);
                    this.isSectionsLoading = false;
                },
                error: () => {
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                    this.isSectionsLoading = false;
                },
            });
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token: any) => {
            this.token = token;
            this.platformAnnouncesService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const platformAnnounce: any = {
            title: this.form.get('title')?.value,
            content: this.form.get('content')?.value,
            date_start: this.transformDate(this.form.get('date_start')?.value),
            date_end: this.transformDate(this.form.get('date_end')?.value),
            section_id: this.form.get('section_id')?.value,
        };

        this.platformAnnouncesService
            .addPlatformAnnounce(platformAnnounce)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: () => {
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                },
            });
    }

    transformDate(fullDateTime: Date): string {
        return this.datePipe.transform(fullDateTime, 'yyyy-MM-dd') || '';
    }
}
