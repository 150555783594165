import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject, Subscription, finalize, map } from 'rxjs';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { environment } from 'src/environments/environment';
import { UsersService } from './users.service';
import Swal from 'sweetalert2';
import { helper } from 'src/environments/helper';
import { EditUserComponent } from './edit-user/edit-user.component';
import { AddUserComponent } from './add-user/add-user.component';
import { Generic } from 'src/app/interfaces/generic.interface';
import { DirectiiService } from '../directii/directii.service';
import { CompartimenteService } from '../compartimente/compartimente.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-users-settings',
    templateUrl: './users-settings.component.html',
    styleUrls: ['./users-settings.component.scss'],
})
export class UsersSettingsComponent {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;

    helper = helper;

    isLoading = false;
    showCard = false;
    activeValue: string = '-1';
    publicValue: string = '-1';
    directieValue: number | string = '-1';
    compartimentValue: number | string = '-1';
    userValue: number | string = '-1';
    nameSearch: string | null = '';
    emailSearch: string | null = '';
    phoneSearch: string | null = '';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.user.getUsersDT;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    defaultParameter: { name: string; id: string } = {
        name: '--Alegeți--',
        id: '-1',
    };

    directiiActive$!: Observable<Generic[]>;
    compartimenteActive$!: Observable<Generic[]>;
    userTypes$!: Observable<Generic[]>;

    toggleCard() {
        this.showCard = !this.showCard;
    }
    constructor(
        private dialogService: DialogService,
        public directiiService: DirectiiService,
        public compartimenteService: CompartimenteService,
        private http: HttpClient,
        private dialog: MatDialog,
        private usersService: UsersService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
        this.loadDirectiiActive();
        // this.loadCompartimenteActive(); // we load the compartimente active only when we select a directie
        this.loadUserTypes();
    }

    loadUserTypes() {
        this.isLoading = true;
        this.userTypes$ = this.usersService.getUserTypeActive().pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => {
                this.isLoading = false;
            })
        );
    }

    onDirectieChange(directieId: number): void {
        if (directieId) {
            this.loadCompartimenteActive(directieId);
        } else {
            this.compartimenteActive$ = new Observable<Generic[]>((observer) => {
                observer.next([{ name: '--Alegeți--', id: -1 }]);
                observer.complete();
            });

            console.log(this.compartimenteActive$, 'check here pls');
        }
    }

    loadDirectiiActive() {
        this.isLoading = true;
        this.directiiActive$ = this.directiiService.getDirectiiActive().pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => {
                this.isLoading = false;
            })
        );
    }

    loadCompartimenteActive(directieId?: number) {
        console.log(directieId, 'directieId');
        this.isLoading = true;
        let compartimenteObservable$ = directieId
            ? this.compartimenteService.getCompartimenteByDirectieId(directieId)
            : this.compartimenteService.getCompartimenteActive();

        this.compartimenteActive$ = compartimenteObservable$.pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => {
                this.isLoading = false;
            })
        );
    }

    resetForm() {
        this.activeValue = '-1';
        this.publicValue = '-1';
        this.directieValue = '-1';
        this.compartimentValue = '-1';
        this.userValue = '-1';
        this.nameSearch = '';
        this.emailSearch = '';
        this.phoneSearch = '';

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            responsive: true,
            searching: false,
            pageLength: 25,

            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.active = this.activeValue;
                dataTablesParameters.public_view = this.publicValue;
                dataTablesParameters.id_sesizare_departament = this.directieValue;
                dataTablesParameters.id_sesizare_compartiment = this.compartimentValue;
                dataTablesParameters.user_type_id = this.userValue;
                dataTablesParameters.name = this.nameSearch;
                dataTablesParameters.email = this.emailSearch;
                dataTablesParameters.phone = this.phoneSearch;

                this.isLoading = true;
                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID Utilizator',
                    data: 'id_user',
                },
                {
                    title: 'Denumire',
                    data: 'name',
                },
                {
                    title: 'Direcție',
                    data: 'directie',
                },
                {
                    title: 'Compartiment',
                    data: 'compartiment',
                },
                {
                    title: 'Tip Utilizator',
                    data: 'user_type',
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    className: 'all',
                    orderable: false,
                    render: (data: any, type: any, row: any, met: any) => {
                        return (
                            `
              <div class="d-flex align-center justify-content-left">
							<button class="action-btn edit ms-1">
								<img class="btn-img" src="/assets/icons/edit.svg" alt="edit"/>
							</button>
							<button type="button" class="action-btn update_status_active ms-1 ` +
                            (row.active == true ? 'action-success' : 'action-danger') +
                            `">
								<img class="btn-img" src="` +
                            (row.active == true
                                ? '/assets/icons/check-white.svg'
                                : '/assets/icons/close-white.svg') +
                            `" alt="update_status_active"/>
							</button>
							<button type="button" class="action-btn update_status_public ms-1 ` +
                            (row.public_view == true ? 'action-success' : 'action-danger') +
                            `">
								<img class="btn-img" src="` +
                            (row.public_view == true
                                ? '/assets/icons/public_white.svg'
                                : '/assets/icons/public_not_white.svg') +
                            `" alt="update_status_public"/>
							</button>
							<button class="action-btn action-info reset-pass ms-1">
								<img class="btn-img" src="/assets/icons/reset-password.svg" alt="reset-pass"/>
							</button>
              </div>
						`
                        );
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const updateStatusActiveIcon = lastTd?.querySelector(
                    '.update_status_active'
                ) as HTMLElement;
                const updateStatusPublicIcon = lastTd?.querySelector(
                    '.update_status_public'
                ) as HTMLElement;

                const resetPasswordIcon = lastTd?.querySelector(
                    '.reset-pass'
                ) as HTMLElement;

                if (editIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.users.update'
                        )
                    ) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (updateStatusActiveIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.users.update-status'
                        )
                    ) {
                        updateStatusActiveIcon.style.display = 'none';
                    }
                    updateStatusActiveIcon.addEventListener('click', () => {
                        const { id_user } = data;
                        this.changeStatusActive(id_user);
                    });
                }

                if (updateStatusPublicIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.users.update-public-visible'
                        )
                    ) {
                        updateStatusPublicIcon.style.display = 'none';
                    }
                    updateStatusPublicIcon.addEventListener('click', () => {
                        const { id_user } = data;
                        this.changeStatusPublic(id_user);
                    });
                }

                if (resetPasswordIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.users.reset-password'
                        )
                    ) {
                        resetPasswordIcon.style.display = 'none';
                    }
                    resetPasswordIcon.addEventListener('click', () => {
                        const { id_user } = data;
                        this.resetPasswordUser(id_user);
                    });
                }

                return row;
            },
        };
    }

    changeStatusActive(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul utilizatorului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusActiveAction(id);
            }
        });
    }

    changeStatusActiveAction(id: number) {
        this.usersService.changeStatuseActive(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Utilizator actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err: any) => {
                let msg;
                try {
                    msg = err.error.errors.message[0];
                } catch (error) {
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusActiveAction(id);
                    }
                });
            }
        );
    }

    changeStatusPublic(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul utilizatorului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusPublicAction(id);
            }
        });
    }

    changeStatusPublicAction(id: number) {
        this.usersService.changeStatusePublic(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Utilizator actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err: any) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusPublicAction(id);
                    }
                });
            }
        );
    }

    resetPasswordUser(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să generați o nouă parolă utilizatorului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.resetPasswordUserAction(id);
            }
        });
    }

    resetPasswordUserAction(id: number) {
        this.usersService.resetPassword(id).subscribe(
            (res: any) => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    // text: 'Parolă operator actualizată cu succes.. Noua parolă configurată: ',
                    text: res.data,
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err: any) => {
                let msg;
                try {
                    msg = err.error.errors.message[0];
                } catch (error) {
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.resetPasswordUserAction(id);
                    }
                });
            }
        );
    }

    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddUserComponent, {
            ...this.dialogService.getDialogConfig(),
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Utilizator adăugat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: any) {
        const dialogRef = this.dialog.open(EditUserComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Utilizator actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }
}
