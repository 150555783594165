import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { helper } from '../../environments/helper';
import { environment } from 'src/environments/environment';
import { MonoTypeOperatorFunction, Subject, ReplaySubject, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    pipe(arg0: MonoTypeOperatorFunction<unknown>) {
        throw new Error('Method not implemented.');
    }
    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    private token!: string;
    private httpOptions: any;

    // use ReplaySubject in order to filter requests when we navigate from a different component to requestComponent by pressing a link from notifications (bell)
    private notificationSource = new ReplaySubject<{
        id: string;
        name: string;
    }>();
    currentNotification = this.notificationSource.asObservable();

    private basePath = environment.interop.basePath;
    private notificationsUrl =
        this.basePath + environment.interop.notifications.authNotifications;

    constructor(private http: HttpClient) { }

    async warningSwal(title: any, text: any, icon: any, onClose?: () => void) {
        return await Swal.fire({
            icon: icon,
            title: title,
            text: text,

            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: helper.dialogConfig.buttonLabels.close,
            allowOutsideClick: false,
        });
    }

    handleHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.warningSwal(this.errorTitle, msg, this.errorIcon);
            });
        } else {
            let errorMessage =
                environment.config.customNotifications.generalMessages.error;
            this.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
        }
    }

    changeNotification(notification: { id: string; name: string }) {
        this.notificationSource.next(notification);
    }

    getNotifications() {
        return this.http
            .get(this.notificationsUrl, this.httpOptions)
            .pipe(map((response: any) => response.data));
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }
}
