import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersSettingsComponent } from './users-settings.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [UsersSettingsComponent, AddUserComponent, EditUserComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [UsersSettingsComponent],
})
export class UserSettingsModule { }
