import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { CompartimenteService } from '../compartimente.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompartimentAddPayload } from '../compartiment.interface';
import { Observable, Subject, finalize, map } from 'rxjs';
import { Generic } from 'src/app/interfaces/generic.interface';
import { DirectiiService } from '../../directii/directii.service';

@Component({
	selector: 'app-add-compartiment',
	templateUrl: './add-compartiment.component.html',
	styleUrls: ['./add-compartiment.component.scss'],
})
export class AddCompartimentComponent implements OnInit {
	errorTitle: string = helper.dialogConfig.headers.errorAtentie;
	errorIcon: string = helper.dialogConfig.icons.error;
	errorType: string = helper.dialogConfig.icons.error;
	helper = helper;

	token!: string;
	isLoading = false;
	form: FormGroup = new FormGroup({
		directie_id: new FormControl('', [Validators.required]),
		name: new FormControl('', [Validators.required]),
		description: new FormControl(''),
	});

	directiiActive$!: Observable<Generic[]>;

	defaultParameter: { name: string; id: string } = {
		name: '--Alegeți--',
		id: '-1',
	};

	constructor(
		private dialogRef: MatDialogRef<AddCompartimentComponent>,
		private compartimenteService: CompartimenteService,
		private notificationService: NotificationService,
		public directiiService: DirectiiService,
		private authService: AuthService
	) {
		this.processToken();
	}

	closeDialog() {
		this.dialogRef?.close({
			success: false,
		});
	}
	ngOnInit() {
		this.loadDirectiiActive();
	}

	loadDirectiiActive() {
		this.isLoading = true;
		this.directiiActive$ = this.directiiService
			.getDirectiiActive()
			.pipe(finalize(() => (this.isLoading = false)));
	}

	processToken() {
		const tokenObj = this.authService.returnTokenUser();
		tokenObj.then((token) => {
			this.token = token;
			this.compartimenteService.setToken(token);
		});
	}

	submitForm() {
		this.isLoading = true;

		const { directie_id, name, description } = this.form
			.value as CompartimentAddPayload;
		const slug = name
			.split(' ')
			.map((item) => item.toLowerCase())
			.join('_');
		const newCompartment: CompartimentAddPayload = {
			directie_id,
			name,
			description,
		};

		this.compartimenteService
			.addCompartiment(newCompartment)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe({
				next: (res) => {
					this.dialogRef.close({ success: true });
				},
				error: (err) => {
					let msg;
					try {
						// try to catch the message from server
						msg = err.error.errors.message[0];
					} catch (error) {
						// controll output message
						msg = helper.dialogConfig.generalMessages.error;
					}
					this.notificationService.warningSwal(
						this.errorTitle,
						msg,
						this.errorIcon
					);
				},
			});
	}
}
