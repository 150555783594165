import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions.service';
import { SidebarSettings } from 'src/app/utils/enum/sidebar-settings';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-settings-sidenav',
    templateUrl: './settings-sidenav.component.html',
    styleUrls: ['./settings-sidenav.component.scss'],
})
export class SettingsSidenavComponent {
    readonly SidebarSettings = SidebarSettings;
    selectedComponent!: SidebarSettings;
    env = environment;
    filteredComponents: string[] = [];

    constructor(private permissionsService: PermissionsService) {
        this.filterComponents();
    }

    filterComponents() {
        this.filteredComponents = [...this.componentKeys];

        if (!this.permissionsService.hasPermission('settings.users.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.UTILIZATORI)
        }
        if (!this.permissionsService.hasPermission('settings.directii.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.INSTITUTII)
        }
        if (!this.permissionsService.hasPermission('settings.compartimente.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.COMPARTIMENTE)
        }
        if (!this.permissionsService.hasPermission('settings.functii.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.FUNCTII)
        }
        if (!this.permissionsService.hasPermission('settings.platform-section.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.PLATFORM_SECTION)
        }
        if (!this.permissionsService.hasPermission('settings.platform-faq.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.PLATFORM_FAQ)
        }
        if (!this.permissionsService.hasPermission('settings.platform-content.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.PLATFORM_CONTENT)
        }
        if (!this.permissionsService.hasPermission('settings.platform-announces.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.PLATFORM_ANNOUNCES)
        }
        if (!this.permissionsService.hasPermission('settings.judet.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.JUDETE)
        }
        if (!this.permissionsService.hasPermission('settings.cerere-type.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.SOLICITARE_TYPES)
        }
        if (!this.permissionsService.hasPermission('settings.cerere-status.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.SOLICITARE_STATUS)
        }
        if (!this.permissionsService.hasPermission('settings.cerere-priority.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.SOLICITARE_PRIORITY)
        }
        if (!this.permissionsService.hasPermission('settings.cerere-series.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.SOLICITARE_SERIES)
        }
        if (!this.permissionsService.hasPermission('settings.document-types.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.TIPURI_DOCUMENTE)
        }
        if (!this.permissionsService.hasPermission('settings.beneficiary-types.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== SidebarSettings.TIPURI_BENEFICIAR)
        }
    }

    componentMapping: Record<SidebarSettings, string> = {
        [SidebarSettings.UTILIZATORI]: 'app-users-setting',
        [SidebarSettings.INSTITUTII]: 'app-directii',
        [SidebarSettings.COMPARTIMENTE]: 'app-compartimente',
        [SidebarSettings.FUNCTII]: 'app-functii',
        [SidebarSettings.PLATFORM_SECTION]: 'app-platform-section',
        [SidebarSettings.PLATFORM_FAQ]: 'app-platform-faq',
        [SidebarSettings.PLATFORM_CONTENT]: 'app-platform-content',
        [SidebarSettings.PLATFORM_ANNOUNCES]: 'app-platform-announces',
        [SidebarSettings.JUDETE]: 'app-judete',
        [SidebarSettings.SOLICITARE_TYPES]: 'app-cerere-type',
        [SidebarSettings.SOLICITARE_STATUS]: 'app-cerere-status',
        [SidebarSettings.SOLICITARE_PRIORITY]: 'app-cerere-priority',
        [SidebarSettings.SOLICITARE_SERIES]: 'app-cerere-series',
        [SidebarSettings.TIPURI_DOCUMENTE]: 'app-tipuri-documente',
        [SidebarSettings.TIPURI_BENEFICIAR]: 'app-tipuri-beneficiar',
    };
    componentKeys: string[] = Object.keys(this.componentMapping);

    onChange($event: { value: any }) {
        this.selectedComponent = $event.value;
    }

    selectedSection: any = null;
}
