<div class="add-container">
	<h1 class="add-title">Adaugă anunț platformă</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Titlu</mat-label>
					<input type="text" matInput formControlName="title" />
				</mat-form-field>
				<mat-error *ngIf="!form.controls['title'].valid && form.controls['title'].touched">
					Câmp obligatoriu
				</mat-error>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<div class="fw-bold">Conținut</div>
				<angular-editor formControlName="content" [config]="editorConfig"></angular-editor>
				<mat-error *ngIf="!form.controls['content'].valid && form.controls['content'].touched">
					Câmp obligatoriu
				</mat-error>
			</div>
		</div>
        <br>
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100">
					<mat-label>Alege data de început</mat-label>
					<input matInput formControlName="date_start" [matDatepicker]="startPicker" readonly
						(focus)="startPicker.open()" />
					<mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
					<mat-datepicker #startPicker></mat-datepicker>
                    <mat-error *ngIf="!form.controls['date_start'].valid && form.controls['date_start'].touched">
                        Câmp obligatoriu
                    </mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100">
					<mat-label>Alege data de sfârșit</mat-label>
					<input matInput formControlName="date_end" [matDatepicker]="endPicker" readonly
						(focus)="endPicker.open()" />
					<mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
					<mat-datepicker #endPicker></mat-datepicker>
                    <mat-error *ngIf="!form.controls['date_end'].valid && form.controls['date_end'].touched">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="form.get('date_end')?.hasError('dateRangeError')">
                        Data de sfârșit trebuie să fie mai mare decât data de început.
                    </mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="spinner">
					<mat-spinner color="primary" *ngIf="isSectionsLoading"></mat-spinner>
				</div>
				<mat-form-field class="w-100" *ngIf="!isSectionsLoading" appearance="fill">
					<mat-label>Secțiune</mat-label>
					<mat-select formControlName="section_id">
						<mat-option *ngFor="let section of platformSections$ | async" [value]="section.id">
							{{ section.name }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="!form.controls['section_id'].valid && form.controls['section_id'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" mat-flat-button type="button" (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" mat-flat-button type="submit" color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>