import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CerereTypeComponent } from './cerere-type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { AddCerereTypeComponent } from './add-cerere-type/add-cerere-type.component';
import { EditCerereTypeComponent } from './edit-cerere-type/edit-cerere-type.component';

@NgModule({
  declarations: [CerereTypeComponent, AddCerereTypeComponent, EditCerereTypeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [CerereTypeComponent],
})
export class CerereTypeModule {}
