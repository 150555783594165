import { Component, Input } from '@angular/core';
import { Client } from '../../client.interface';
import { ClientsService } from '../../clients.service';

@Component({
  selector: 'app-client-general-info',
  templateUrl: './client-general-info.component.html',
  styleUrls: ['./client-general-info.component.scss']
})
export class ClientGeneralInfoComponent {
  @Input() client!: Client;

  constructor() { }
}
