
<div class="d-flex flex-column justify-content-between h-100">
  <div class="col d-flex justify-content-end">
    <button type="button" class="action-btn" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
<mat-dialog-content>
  <img [src]="data.original_filename" alt="Preview" style="width: 100%;">
</mat-dialog-content>
</div>