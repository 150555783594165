import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigAdminComponent } from './config-admin.component';
import { AddConfigAdminComponent } from './add-config-admin/add-config-admin.component';
import { EditConfigAdminComponent } from './edit-config-admin/edit-config-admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
	declarations: [ConfigAdminComponent, AddConfigAdminComponent, EditConfigAdminComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		MaterialModule
	],
	exports: [ConfigAdminComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConfigAdminModule { }
