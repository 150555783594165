<div class="add-container">
	<h1 class="add-title">Adaugă tip solicitare</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Nume</mat-label>
					<input type="text" matInput formControlName="name" />
					<mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Slug</mat-label>
					<input type="text" matInput formControlName="slug" />
					<mat-error *ngIf="!form.controls['slug'].valid && form.controls['slug'].touched && !form.get('slug')?.hasError('invalidCharacter')">
						Câmp obligatoriu
					</mat-error>
                    <mat-error *ngIf="form.get('slug')?.hasError('invalidCharacter')">
						Caracterele spațiu și punct nu sunt permise
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Prioritate</mat-label>
					<mat-select formControlName="priority_id">
						<mat-option *ngFor="let row of prioritiesActive$ | async" [value]="row.id">
							{{ row.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class=" col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Status</mat-label>
					<mat-select formControlName="status_id">
						<mat-option *ngFor="let row of statusesActive$ | async" [value]="row.id">
							{{ row.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Direcție/Instituție</mat-label>
					<mat-select formControlName="directie_id" (selectionChange)="onDirectieChange($event.value)">
						<mat-option *ngFor="let directie of directiiActive$ | async" [value]="directie.id">
							{{ directie.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Compartiment/Birou/Serviciu</mat-label>
					<mat-select formControlName="compartiment_id"
						(selectionChange)="onDirectieCompartimentChange($event.value)">
						<mat-option *ngFor="let compartiment of compartimenteActive$ | async" [value]="compartiment.id">
							{{ compartiment.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Operator</mat-label>
					<mat-select formControlName="user_id">
						<mat-option *ngFor="let user of usersActive$ | async" [value]="user.id">
							{{ user.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100" appearance="fill">
					<mat-label>Serie Cerere</mat-label>
					<mat-select formControlName="serie_id">
						<mat-option *ngFor="let serie of series$ | async" [value]="serie.id">
							{{ serie.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
            </div>
        </div>

		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Observații</mat-label>
					<input type="text" matInput formControlName="observations" />
					<mat-error *ngIf="
				!form.controls['observations'].valid &&
				form.controls['observations'].touched
			  ">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>