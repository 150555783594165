import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfigAdminService } from '../config-admin.service';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-add-config-admin',
  templateUrl: './add-config-admin.component.html',
  styleUrls: ['./add-config-admin.component.scss'],
})
export class AddConfigAdminComponent {
  // general error
  errorTitle: string = environment.config.customNotifications.headers.error;
  errorIcon: string = environment.config.customNotifications.icons.error;
  errorType: string = environment.config.customNotifications.icons.error;
  helper = helper;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    value: new FormControl('', [Validators.required]),
  });

  constructor(
    private dialogRef: MatDialogRef<AddConfigAdminComponent>,
    private configAdminService: ConfigAdminService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.configAdminService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const configAdmin = {
      name: this.form.get('name')?.value,
      value: this.form.get('value')?.value,
    };

    this.configAdminService
      .addConfigAdmin(configAdmin)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        },
      });
  }
}
