import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrationComponent } from './administration.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { MaterialModule } from 'src/app/material.module';
import { TypeImageAdminModule } from './admin-settings/type-image-admin/type-image-admin.module';
import { ConfigAdminModule } from './admin-settings/config-admin/config-admin.module';
import { UserTypeModule } from './admin-settings/user-type/user-type.module';
import { CronsModule } from './admin-settings/crons/crons.module';
@NgModule({
    declarations: [AdministrationComponent, AdminSettingsComponent],
    imports: [
        CommonModule,
        MaterialModule,
        TypeImageAdminModule,
        ConfigAdminModule,
        UserTypeModule,
        CronsModule,
    ],

    exports: [AdministrationComponent, AdminSettingsComponent],
})
export class AdministrationModule { }
