import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SablonAddPayload, SablonEditPayload } from './sabloane.interface';

@Injectable({
  providedIn: 'root',
})
export class CerereSabloaneService {
  private token!: string;
  private httpOptions: any;

  private basePath = environment.interop.basePath;
  private getSabloaneUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.cerereSabloane.getCerereSabloane;
  private getSabloaneDTUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.cerereSabloane
      .getCerereSabloaneDT;
  private storeSablonUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.cerereSabloane.storeCerereSablon;
  private updateSablonUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.cerereSabloane.putCerereSablon;
  private deleteSablonUrl =
    this.basePath +
    environment.interop.platform.nomenclatoare.cerereSabloane
      .deleteCerereSablon;

  constructor(private http: HttpClient) {}

  getSabloaneDT() {
    return this.http.get(this.getSabloaneDTUrl, this.httpOptions);
  }

  getSabloane() {
    return this.http.get(this.getSabloaneUrl, this.httpOptions);
  }

  addSablon(sablon: SablonAddPayload) {
    return this.http.post(this.storeSablonUrl, sablon, this.httpOptions);
  }

  updateSablon(sablon: SablonEditPayload) {
    return this.http.put(this.updateSablonUrl, sablon, this.httpOptions);
  }

  deleteSablon(sablonId: number) {
    return this.http.delete(this.deleteSablonUrl + sablonId);
  }

  setToken(token: string) {
    this.token = token;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      }),
    };
  }
}
