import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PlatformSectionService } from '../platform-section.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';

import { helper } from 'src/environments/helper';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-add-platform-section',
    templateUrl: './add-platform-section.component.html',
    styleUrls: ['./add-platform-section.component.scss'],
})
export class AddPlatformSectionComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        slug: new FormControl('', [Validators.required, noSpacesOrDotsValidator()]),
        observations: new FormControl(''),
        active_message: new FormControl(''),
    });

    constructor(
        private dialogRef: MatDialogRef<AddPlatformSectionComponent>,
        private platformSectionService: PlatformSectionService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.platformSectionService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const platformSection = {
            name: this.form.get('name')?.value,
            slug: this.form.get('slug')?.value,
            observations: this.form.get('observations')?.value,
            active_message: this.form.get('active_message')?.value,
        };

        this.platformSectionService
            .addPlatformSection(platformSection)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
