import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCerereSeriesComponent } from './add-cerere-series/add-cerere-series.component';
import { EditCerereSeriesComponent } from './edit-cerere-series/edit-cerere-series.component';
import { CerereSeriesComponent } from './cerere-series.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { EditNrCurentComponent } from './edit-nr-curent/edit-nr-curent.component';

@NgModule({
    declarations: [
        CerereSeriesComponent,
        AddCerereSeriesComponent,
        EditCerereSeriesComponent,
        EditNrCurentComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    exports: [CerereSeriesComponent],
})
export class CerereSeriesModule { }
