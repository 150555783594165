<div class="title p-3">
    <div class="d-flex justify-content-between align-items-center">
        <h1 class="m-0">Adaugă imagine</h1>
        <mat-icon class="action-btn" (click)="closeDialog()">close</mat-icon>
    </div>
</div>

<mat-divider class="m-2"></mat-divider>

<div class="row">
  <div class="col">
    <div class="solicitare-card__upload">
      <form [formGroup]="fileForm">
        <div class="solicitare-card__upload-header">
          <p class="upload-text">Fotografii</p>
          <button class="upload-btn" (click)="fileInput.click()" type="button">
            <mat-icon class="upload-icon">cloud_upload</mat-icon>
            ÎNCARCĂ
          </button>
        </div>
    
        <div class="solicitare-card__upload-files" *ngIf="fileForm.get('files')?.value && !uploading">
          <div class="solicitare-card__upload-file">
            <span class="solicitare-card__upload-file-name">
              {{ fileForm.get('files')?.value.original_name}}
            </span>
            <div>
              <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat"
                (click)="removeFile()">
                <img class="btn-img" src="assets/icons/delete.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
    
        <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading"></mat-spinner>
    
        <input type="file" accept="image/*" id="upload-file-input-0" #fileInput name="fileInput" (change)="addFile($event)"
          style="display: none">
      </form>
    </div>
  </div>
</div>
