import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CerereSabloaneService } from '../cerere-sabloane.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';
import { helper } from 'src/environments/helper';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';

@Component({
  selector: 'app-edit-cerere-sabloane',
  templateUrl: './edit-cerere-sabloane.component.html',
  styleUrls: ['./edit-cerere-sabloane.component.scss'],
})
export class EditCerereSabloaneComponent implements OnInit {
  // general error
  errorTitle: string = environment.config.customNotifications.headers.error;
  errorIcon: string = environment.config.customNotifications.icons.error;
  errorType: string = environment.config.customNotifications.icons.error;
  helper = helper;

  editorConfig!: AngularEditorConfig;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl(this.data.name),
    content: new FormControl(this.data.content),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<EditCerereSabloaneComponent>,
    private cerereSabloaneService: CerereSabloaneService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }
  ngOnInit(): void {
    this.editorConfig = initializeEditor();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.cerereSabloaneService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const updatedSablonData = {
      name: this.form.get('name')?.value,
      content: this.form.get('content')?.value,
      id: this.data.id,
    };

    this.cerereSabloaneService
      .updateSablon(updatedSablonData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let errorMessage =
            environment.config.customNotifications.generalMessages.error;
          this.notificationService.warningSwal(
            this.errorTitle,
            errorMessage,
            this.errorIcon
          );
        },
      });
  }

  onHttpError(res: any) {
    if (res.errors && res.errors.message && res.errors.message.length > 0) {
      res.errors.message.forEach((msg: string) => {
        this.notificationService.warningSwal(
          this.errorTitle,
          msg,
          this.errorIcon
        );
      });
    } else {
      let errorMessage =
        environment.config.customNotifications.generalMessages.error;
      this.notificationService.warningSwal(
        this.errorTitle,
        errorMessage,
        this.errorIcon
      );
    }
  }
}
