import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { ClientOperatorsComponent } from './client-operators.component';
import { AddClientOperatorComponent } from './add-client-operator/add-client-operator.component';

@NgModule({
  declarations: [
    ClientOperatorsComponent,
    AddClientOperatorComponent
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  exports: [ClientOperatorsComponent],
})
export class ClientOperatorsModule { }
