<div class="add-container">
	<h1 class="add-title">Adaugă șablon mesaj</h1>

	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Nume</mat-label>
				<input type="text" matInput formControlName="name" />
			</mat-form-field>
			<mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
				Câmp obligatoriu
			</mat-error>
		</div>

		<div class="add-field">
			<h4>Conținut</h4>
			<angular-editor formControlName="content" [config]="editorConfig"></angular-editor>
		</div>


		<div class="add-action-btns">
			<button type="button" class="buttons" mat-flat-button (click)="closeDialog()">
                {{ helper.dialogConfig.buttonLabels.close }}
			</button>
			<button class="buttons" mat-flat-button color="primary" type="submit" [disabled]="form.invalid">
                {{ helper.dialogConfig.buttonLabels.save }}
			</button>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>