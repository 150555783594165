import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

import { helper } from 'src/environments/helper';
import { ClientContactsService } from '../client-contacts.service';
import { FunctiiService } from '../../../../settings-sidenav/functii/functii.service';
import { Generic } from 'src/app/interfaces/generic.interface';
import { EditClientContactPayload } from '../client-contacts.interface';
import { UsersService } from '../../../../settings-sidenav/users-settings/users.service';

@Component({
    selector: 'app-edit-client-contact',
    templateUrl: './edit-client-contact.component.html',
    styleUrls: ['./edit-client-contact.component.scss'],
})
export class EditClientContactComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    functiiActive$!: Observable<Generic[]>;

    isLoading = false;

    form: FormGroup = new FormGroup({
        id: new FormControl(this.data.id, [Validators.required]),
        client_id: new FormControl(this.data.id_client, [Validators.required]),
        user_id: new FormControl('', []),
        last_name: new FormControl('', [Validators.required]),
        first_name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        phone: new FormControl('', []),
        observations: new FormControl('', []),
        functie_id: new FormControl('', [Validators.required]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<EditClientContactComponent>,
        public contactsService: ClientContactsService,
        public usersService: UsersService,
        public functiiService: FunctiiService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.loadFunctiiActive();
        this.getContactDetails();
    }

    getContactDetails() {
        this.isLoading = true;

        this.contactsService.getContactById(this.data.id).pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
            .subscribe((res: any) => {
                if (res && res.data) {
                    this.loadFormWithData(res.data);
                } else {
                    // Handle error or unexpected response format
                }
            });
    }

    loadFormWithData(data: any) {
        console.log(data, 'the loaded contact')
        this.form.patchValue({ ...data });
    }

    loadFunctiiActive() {
        this.isLoading = true;
        this.functiiActive$ = this.functiiService
            .getFunctiiActive()
            .pipe(finalize(() => (this.isLoading = false)));
    }

    closeDialog() {
        this.dialogRef ?.close({ success: false });
    }

    submitForm() {
        this.isLoading = true;

        const payload = this.form.value as EditClientContactPayload;

        this.contactsService.updateClientContact(payload)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err: any) => {
                    let msg;
                    try {
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(helper.dialogConfig.headers.errorAtentie, msg, this.errorIcon);
                },
            });
    }
}
