import { Component, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/utils/services/toast.service';
import { GalleryService } from './gallery.service';
import { AddPictureComponent } from './add-picture/add-picture/add-picture.component';
import { DialogService } from 'src/app/utils/services/dialog.service';
import Swal from 'sweetalert2';
import { Image } from './picture';
import { OpenImageComponent } from './openImage/open-image.component';
import { helper } from 'src/environments/helper';
import { Subscription, finalize } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnChanges {
    images: Image[] = [];
    isLoading = false;
    helper = helper;

    constructor(
        private notificationService: NotificationService,
        public toastr: ToastrService,
        public dialog: MatDialog,
        private toastService: ToastService,
        private galleryService: GalleryService,
        private dialogService: DialogService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.getGallery();
    }

    ngOnChanges() {
        this.getGallery();
    }

    getGallery() {
        this.isLoading = true;
        this.galleryService
            .getImages()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((images) => {
                this.images = images;
            });
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddPictureComponent, {
            minHeight: '40vh',
            maxHeight: '30vh',
            minWidth: '75vw',
            maxWidth: '75vw',
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                this.getGallery();
            }
        });
    }

    deleteImage(id: any) {
        Swal.fire({
            title: 'Sunteți sigur?',
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm!',
            cancelButtonText: 'Anulare',
            reverseButtons: true,
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.galleryService
                    .deleteImageGallery(id)
                    .pipe(
                        finalize(() => {
                            this.isLoading = false;
                            this.getGallery();
                        })
                    )
                    .subscribe(() => {
                        Swal.fire({
                            title: 'Șters!',
                            text: 'Imagine ștearsă cu succes!',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        });
                    });
            }
        });
    }

    extractIdFromFilename(filename: string): string {
        const urlParts = filename.split('/');
        return urlParts[urlParts.length - 1];
    }

    buildImagePath(image: any): string {
        return image.original_filename;
    }

    openImgDialog(image: Image): void {
        const dialogRef = this.dialog.open(OpenImageComponent, {
            width: '550px',
            height: '500px',
            data: image,
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
            }
        });
    }
}
