import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Cerere } from 'src/app/layout/pages-layout/nomenclatoare/cetatean/requests/request.interface';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'actiuni-cerere-card',
    templateUrl: './actiuni-cerere-card.component.html',
    styleUrls: ['./actiuni-cerere-card.component.scss'],
})
export class ActiuniCerereCardComponent {
    @Input() title!: string;
    @Input() description!: string;
    @Input() iconName!: string;
    @Input() btnColor: string = 'warn';
    @Input() actionText!: string;
    @Input() actionType!: string;
    @Input() iconColor?: string = 'red';
    @Input() isSelected?: boolean = false;
    @Input() cerere: Cerere | null = null;
    @Output() buttonClicked = new EventEmitter<string>();
    @Output() buttonResetClicked = new EventEmitter<string>();

    onButtonClick() {
        this.buttonClicked.emit(this.actionText);
    }

    onButtonResetClick() {
        this.buttonResetClicked.emit(this.actionText);
    }

    toggleSelected() {
        this.isSelected = !this.isSelected;
    }

    constructor(public permissionsService: PermissionsService) { }

    selectedClass() {
        switch (this.actionType) {
            case 'validate':
                return (this.cerere?.validated_date !== null ? true : false);
            case 'invalidate':
            case 'anulare':
                return (this.cerere?.cancel_date !== null ? true : false);
            case 'finalizare':
                return (this.cerere?.finalize_date !== null ? true : false);
            default:
                return false
        }
    }
}
