import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypeImageService } from '../type-image.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs';

import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-edit-type-image-admin',
  templateUrl: './edit-type-image-admin.component.html',
  styleUrls: ['./edit-type-image-admin.component.scss'],
})
export class EditTypeImageAdminComponent {
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl(this.data.name, Validators.required),
    width: new FormControl(this.data.width, Validators.required),
    height: new FormControl(this.data.height, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<EditTypeImageAdminComponent>,
    private typeImageService: TypeImageService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.typeImageService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const updatedImageType = {
      name: this.form.get('name')?.value,
      width: this.form.get('width')?.value,
      height: this.form.get('height')?.value,
      id: this.data.id,
    };

    this.typeImageService
      .updateImageType(updatedImageType)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        },
      });
  }
}
