import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

import { UserOrganigramaSearchPayload } from '../interfaces/common.interface'

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private token!: string;
    private basePath = environment.interop.basePath;
    private httpOptions: any;
    private getDirectiiUrl = this.basePath + environment.interop.platform.nomenclatoare.directie.getDirectii;
    private getDirectiiActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.directie.getDirectiiActive;
    private getCompartimenteUrl = this.basePath + environment.interop.platform.nomenclatoare.compartiment.getCompartimente;
    private getCompartimenteActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.compartiment.getCompartimenteActive;
    private getCompartimenteByDirectieUrl = this.basePath + environment.interop.platform.nomenclatoare.compartiment.getCompartimenteByDirectie;

    private getUsersByOrganigramaUrl = this.basePath + environment.interop.platform.nomenclatoare.user.getActiveUsersByOrganigrama;

    private getActiveUsersUrl = this.basePath + environment.interop.platform.nomenclatoare.user.getUsersActive;

    constructor(private http: HttpClient) { }


    getDirectii() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getDirectiiUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_directie,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get directii:', error);
                    return throwError(error);
                })
            );
    }

    getDirectiiActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getDirectiiActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_directie,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get directii active:', error);
                    return throwError(error);
                })
            );
    }

    getCompartimenteActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getCompartimenteActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_compartiment,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get compartimente active:', error);
                    return throwError(error);
                })
            );
    }

    getCompartimenteByDirectieId(id: number) {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getCompartimenteByDirectieUrl + id
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_compartiment,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in getCompartimenteByDirectieId:', error);
                    return throwError(error);
                })
            );
    }

    getCompartimente() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getCompartimenteUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_compartiment,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get compartimente:', error);
                    return throwError(error);
                })
            );
    }

    getUsersByOrganigrama(data: UserOrganigramaSearchPayload) {
        return this.http
            .post<{ errors: boolean; data: any[]; status_code: number }>(this.getUsersByOrganigramaUrl, data, this.httpOptions)
            .pipe(
                map((response: any) =>
                    response.data.map((item: any) => ({ id: item.id, name: item.name }))
                ),
                catchError((error) => {
                    console.error('Error in getUserTypeActive:', error);
                    return throwError(error);
                })
            );
    }

    getActiveUsers() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getActiveUsersUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_user,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get acitve users:', error);
                    return throwError(error);
                })
            );
    }

}
