import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';
import { CronsService } from '../crons.service';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-edit-cron',
    templateUrl: './edit-cron.component.html',
    styleUrls: ['./edit-cron.component.scss'],
})
export class EditCronComponent {
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;

    form: FormGroup = new FormGroup({
        id: new FormControl(this.data.id, Validators.required),
        name: new FormControl(this.data.name, Validators.required),
        path: new FormControl(this.data.path, Validators.required),
        slug: new FormControl(this.data.slug, [Validators.required, noSpacesOrDotsValidator()]),
        interval: new FormControl(this.data.interval, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private dialogRef: MatDialogRef<EditCronComponent>,
        private cronsService: CronsService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
        console.log(data);
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.cronsService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const crons = {
            id: this.form.get('id')?.value,
            name: this.form.get('name')?.value,
            path: this.form.get('path')?.value,
            slug: this.form.get('slug')?.value,
            interval: this.form.get('interval')?.value,
        };
        this.cronsService
            .updateCrons(crons)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: () => {
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let errorMessage =
                environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(
                this.errorTitle,
                errorMessage,
                this.errorIcon
            );
        }
    }
}
