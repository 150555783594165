import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CentralizatorService {
    private token!: string;
    private httpOptions: any;

    private basePath = environment.interop.basePath;
    private changeStatusPublicUrl = this.basePath + environment.interop.platform.nomenclatoare.centralizator.changeStatusPublic;

    constructor(private http: HttpClient) { }

    changeStatusPublic() {
        return this.http.put(this.changeStatusPublicUrl, this.httpOptions);
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }
}
