<div class="add-container">
	<h1 class="add-title">Adaugă UAT</h1>
	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Denumire</mat-label>
					<input type="text" matInput formControlName="name" />
					<mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col add-field">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Județ</mat-label>
					<mat-select formControlName="judet_id">
                        <mat-option value="-1">-- Alegeți --</mat-option>
						<mat-option *ngFor="let judet of judete$ | async" [value]="judet.id">
							{{ judet.name }}
						</mat-option>
					</mat-select>
                    <mat-error *ngIf="!form.controls['judet_id'].valid && form.controls['judet_id'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>
        <div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Cod SIRUTA</mat-label>
					<input type="number" matInput formControlName="siruta" />
					<mat-error *ngIf="!form.controls['siruta'].valid && form.controls['siruta'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col add-field">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Tip Beneficiar</mat-label>
					<mat-select formControlName="tip_beneficiar">
                        <mat-option value="-1">-- Alegeți --</mat-option>
						<mat-option *ngFor="let beneficiar of beneficiari$ | async" [value]="beneficiar.id">
							{{ beneficiar.name }}
						</mat-option>
					</mat-select>
                    <mat-error *ngIf="!form.controls['tip_beneficiar'].valid && form.controls['tip_beneficiar'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Observații</mat-label>
					<input type="text" matInput formControlName="observations" />
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>