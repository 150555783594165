import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { TipDocumente, TipDocumenteEditPayload } from './tipuri-documente.interface';

@Injectable({
    providedIn: 'root'
})
export class TipuriDocumenteService {
    private token!: string;
    private httpOptions: any;
    private basePath = environment.interop.basePath;

    private getTipuriDocumenteUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriDocumente.getTipuriDocumente;
    private getTipuriDocumentDTUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriDocumente.getTipuriDocumenteDT;
    private storeTipDocumenteUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriDocumente.storeTipDocumente;
    private deleteTipDocumenteUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriDocumente.deleteTipDocumente;
    private getTipuriDocumenteActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriDocumente.getTipuriDocumenteActive;
    private updateTipDocumenteUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriDocumente.putTipDocumente;
    private changeStatusActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.tipuriDocumente.changeStatusTipDocumenteActive;

    constructor(private http: HttpClient) { }

    addTipDocument(data: TipDocumente) {
        return this.http.post(this.storeTipDocumenteUrl, data, this.httpOptions);
    }

    updateTipDocumente(updateTip: TipDocumenteEditPayload) {
        return this.http.put(this.updateTipDocumenteUrl, updateTip, this.httpOptions);
    }

    deleteTipDocumente(id: number) {
        return this.http.delete(this.deleteTipDocumenteUrl + id);
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }

    getTipuriDocumente() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getTipuriDocumenteUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get tipuri documente:', error);
                    return throwError(error);
                })
            );
    }

    getTipuriDocumenteActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getTipuriDocumenteActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get tipuri documente active:', error);
                    return throwError(error);
                })
            );
    }

    changeStatuseActive(id: number) {
        return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
    }
}
