import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { CerereStatusService } from '../cerere-status.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { Status } from '../cerere-status.interface';
import { Observable, Subject, finalize, map } from 'rxjs';
import { noSpacesOrDotsValidator } from 'src/app/utils/common/validators/no-special-chars';

@Component({
    selector: 'app-add-cerere-status',
    templateUrl: './add-cerere-status.component.html',
    styleUrls: ['./add-cerere-status.component.scss'],
})
export class AddCerereStatusComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        bg_color: new FormControl('', [Validators.required]),
        slug: new FormControl('', [Validators.required, noSpacesOrDotsValidator()]),
        // rank: new FormControl('', [Validators.required]),
        observations: new FormControl(''),
    });

    ranks$!: Observable<any[]>;

    constructor(
        private dialogRef: MatDialogRef<AddCerereStatusComponent>,
        private cerereStatusService: CerereStatusService,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.cerereStatusService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { name, bg_color, slug, observations } = this.form
            .value as Status;
        const newStatus: Status = {
            name,
            bg_color,
            slug,
            // rank,
            observations,
        };

        this.cerereStatusService
            .addStatus(newStatus)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }
}
