import { Component, ViewChild, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { helper } from 'src/environments/helper';
import { DataTableDirective } from 'angular-datatables';
import { finalize, map, Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DialogService } from '../../../../../../utils/services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Generic } from '../../../../../../interfaces/generic.interface';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { Client } from '../../client.interface';
import { ClientOperatorsService } from './client-operators.service';
import { AddClientOperatorComponent } from './add-client-operator/add-client-operator.component';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-client-operators',
    templateUrl: './client-operators.component.html',
    styleUrls: ['./client-operators.component.scss']
})
export class ClientOperatorsComponent {
    @Input() client!: Client;
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    showCard = false;
    activeValue: string = '-1';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    defaultParameter: { name: string; id: string | null } = {
        name: '--Alegeți--',
        id: null,
    };

    clientModulesActive$!: Observable<Generic[]>;

    constructor(
        private dialogService: DialogService,
        public clientOperatorsService: ClientOperatorsService,
        private http: HttpClient,
        private dialog: MatDialog,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        // this.loadClientModulesActive();

        this.renderDT();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }

    resetForm() {
        this.activeValue = '-1';

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    // loadClientModulesActive() {
    //     this.isLoading = true;
    //     this.clientModulesActive$ = this.clientModulesService.getClientModulesByClient(this.client.id_client)
    //         .pipe(
    //             map((results: any) => {
    //                 const extractedData = results.data.map((item: any) => ({
    //                     id: item.module_id,
    //                     name: item.module_name,
    //                 }));

    //                 return [this.defaultParameter, ...extractedData];
    //             }),
    //             finalize(() => {
    //                 this.isLoading = false;
    //             })
    //         );
    // }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            searching: false,
            responsive: true,
            pageLength: 25,

            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.client_id = this.client.id_client;
                dataTablesParameters.active = this.activeValue;

                this.isLoading = true;

                this.clientOperatorsService
                    .getClientOperatorsDT(dataTablesParameters)
                    .pipe(
                        finalize(() => {
                            this.isLoading = false;
                        })
                    )
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'id',
                },
                // {
                //     title: 'ID Modul',
                //     data: 'module_id',
                //     visible: false,
                // },
                // {
                //     title: 'Nume Modul',
                //     data: 'module_name',
                // },
                {
                    title: 'ID User',
                    data: 'user_id',
                    visible: false,
                },
                {
                    title: 'Nume Utilizator',
                    data: 'user_name',
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    className: 'all',
                    orderable: false,
                    render: (data: any, type: any, row: any, met: any) => {
                        return ` 
                <div class="d-flex align-center justify-content-left">           
                          <button type="button" class="action-btn delete ms-1">
                              <img class="btn-img" src="/assets/icons/delete.svg" alt="delete"/>
                          </button>
                          </div>
                        `;
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;

                if (deleteIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'clients.operators.delete'
                        )
                    ) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.deleteClientModule(id);
                    });
                }

                return row;
            },
        };
    }

    deleteClientModule(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteAction(id);
            }
        });
    }

    deleteAction(id: number) {
        this.clientOperatorsService.deleteClientOperator(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Operator șters cu succes!',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err: any) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }

                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteAction(id);
                    }
                });
            }
        );
    }


    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddClientOperatorComponent, {
            ...this.dialogService.getDialogConfig(),
            data: this.client,
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Operator adăugat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }
}
