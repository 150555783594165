import { Component } from '@angular/core';

@Component({
  selector: 'app-cetatean',
  templateUrl: './cetatean.component.html',
  styleUrls: ['./cetatean.component.scss']
})
export class CetateanComponent {

}
