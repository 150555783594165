import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FAQAddPayload, FAQUpdatePayload } from './platform-faq.interface';

@Injectable({
	providedIn: 'root'
})
export class PlatformFaqService {
	private token!: string;
	private basePath = environment.interop.basePath;
	private httpOptions: any;
	private getFAQsDTUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.getPlatformFAQsDT;
	private getFAQsUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.getPlatformFAQs;
	private storeFAQUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.storePlatformFAQ;
	private updateFAQUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.putPlatformFAQ;
	private deleteFAQUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.deletePlatformFAQ;
	private getFAQsActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.getPlatformFAQsActive;

	private changeStatusActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.changeStatusPlatformFAQActive;

	private updateOrderUrl = this.basePath + environment.interop.platform.nomenclatoare.platformFAQ.updateOrder

	constructor(private http: HttpClient) { }

	getFAQsDT() {
		return this.http.get(this.getFAQsDTUrl, this.httpOptions);
	}

	getFAQs(platformSectionID: number) {
		return this.http.get(this.getFAQsUrl + `?section_id={${platformSectionID}}`, this.httpOptions);
	}

	addFAQ(data: FAQAddPayload) {
		return this.http.post(this.storeFAQUrl, data, this.httpOptions);
	}

	updateFAQ(data: FAQUpdatePayload) {
		return this.http.put(this.updateFAQUrl, data, this.httpOptions);
	}

	deleteFAQ(requestFAQId: number) {
		return this.http.delete(this.deleteFAQUrl + requestFAQId);
	}

	getActiveFAQs(platformSectionID: number) {
		return this.http.get(this.getFAQsActiveUrl + `?section_id={${platformSectionID}}`, this.httpOptions);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${this.token}`
			})
		};
	}

	changeStatusActive(id: number) {
		return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
	}

	updateOrder(id: number, data: any) {
		return this.http.put(this.updateOrderUrl + id, data, this.httpOptions);
	}
}
