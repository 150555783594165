import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTipuriBeneficiarComponent } from './add-tipuri-beneficiar/add-tipuri-beneficiar.component';
import { EditTipuriBeneficiarComponent } from './edit-tipuri-beneficiar/edit-tipuri-beneficiar.component';
import { TipuriBeneficiarComponent } from './tipuri-beneficiar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    declarations: [
        TipuriBeneficiarComponent,
        AddTipuriBeneficiarComponent,
        EditTipuriBeneficiarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    exports: [TipuriBeneficiarComponent]
})
export class TipuriBeneficiarModule { }
