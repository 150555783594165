import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Cerere, IInchidereLucrare } from '../../request.interface';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';
import { RequestsService } from '../../requests.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-modal-inchidere-lucrare',
    templateUrl: './modal-inchidere-lucrare.component.html',
    styleUrls: ['./modal-inchidere-lucrare.component.scss']
})
export class ModalInchidereLucrareComponent {
    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    token!: string;
    isLoading: boolean = false;
    helper = helper;

    defaultParameter: { name: string; id: string } = {
        name: '-- Alegeți --',
        id: '-1',
    };

    tipuriRezolutii: { name: string; id: string }[] = [
        {
            name: '--Alegeți--',
            id: '-1',
        },
        {
            id: '1',
            name: 'Favorabil',
        },
        {
            id: '2',
            name: 'Invalid',
        },
        {
            id: '3',
            name: 'Nefavorabil',
        },
    ];

    form: FormGroup = new FormGroup({
        finalizare_type: new FormControl('-1', Validators.compose([
            Validators.required, Validators.min(1)
        ])),
        observatii: new FormControl(''),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Cerere,
        private dialogRef: MatDialogRef<ModalInchidereLucrareComponent>,
        private authService: AuthService,
        private requestsService: RequestsService,
        private notificationService: NotificationService,
    ) {
        this.processToken();
    }

    closeDialog(): void {
        this.dialogRef?.close({
            success: false,
        });
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.requestsService.setToken(token);
        });
    }

    submitForm() {
        this.isLoading = true;

        const { finalizare_type, observatii } = this.form.value as IInchidereLucrare;

        const finalizareCerereData = {
            finalizare_type: Number(finalizare_type),
            observatii
        };

        this.requestsService
            .finalizareCerere(this.data.id, finalizareCerereData)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let msg;
            try {
                // try to catch the message from server
                msg = res.error.errors.message[0];
            } catch (error) {
                // controll output message
                msg = helper.dialogConfig.generalMessages.error;
            }
            this.notificationService.warningSwal(
                this.errorTitle,
                msg,
                this.errorIcon
            );
        }
    }
}
