import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EditPlatformContentPayload, PlatformContent } from './platform-content.interface';

@Injectable({
	providedIn: 'root'
})
export class PlatformContentService {
	private token!: string;
	private httpOptions: any;


	private basePath = environment.interop.basePath;
	private getPlatformContentsDTURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.getPlatformContentsDT;
	private getPlatformContentsURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.getPlatformContents;
	private getPlatformContentURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.getPlatformContent;
	private getPlatformContentActiveURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.getPlatformContentsActive;

	//Updates
	private updatePlatformContentURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.putPlatformContent;
	private addPlatformContentURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.storePlatformContent;
	private updateStatusPlatformContentActiveURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.changeStatusPlatformContentActive;

	private deletePlatformContentURL = this.basePath + environment.interop.platform.nomenclatoare.platformContent.deletePlatformContent;

	private changeStatusActiveUrl = this.basePath + environment.interop.platform.nomenclatoare.platformContent.changeStatusPlatformContentActive;

	private updateOrderUrl = this.basePath + environment.interop.platform.nomenclatoare.platformContent.updateOrder

	constructor(private http: HttpClient) { }

	getPlatformContentDT() {
		return this.http.get(this.getPlatformContentsDTURL, this.httpOptions);
	}

	getPlatformContents() {
		return this.http.get(this.getPlatformContentsURL, this.httpOptions);
	}

	getPlatformContent(platformContentId: number) {
		return this.http.get(this.getPlatformContentURL + platformContentId, this.httpOptions);
	}

	addPlatformContent(platformContent: PlatformContent) {
		return this.http.post(this.addPlatformContentURL, platformContent, this.httpOptions);
	}

	updatePlatformContent(platformContent: EditPlatformContentPayload) {
		return this.http.put(this.updatePlatformContentURL, platformContent, this.httpOptions);
	}

	updatePlatformContentStatus(status: any) {
		return this.http.put(this.updateStatusPlatformContentActiveURL, status, this.httpOptions);
	}

	deletePlatformContent(platformContentId: number) {
		return this.http.delete(this.deletePlatformContentURL + platformContentId);
	}

	getPlatformContentActive() {
		return this.http.get(this.getPlatformContentActiveURL, this.httpOptions);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}

	changeStatusActive(id: number) {
		return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
	}

	updateOrder(id: number, data: any) {
		return this.http.put(this.updateOrderUrl + id, data, this.httpOptions);
	}
}
