<!-- <mat-card> -->
<!-- Persoana fizica -->
<div class="g-0" *ngIf="isPersoanaFizica">
    <div class="row">
        <div class="col-sm-12 col-md-12">
            <div class="box-wrapper box-cetatean">
                <div class="box-content-header">
                    <div class="row p-1">
                        <div class="col">
                            <mat-card-title>
                                <i class="fa-regular fa-user"></i>
                                <span>Subsemnatul</span>
                            </mat-card-title>
                        </div>
                        <!-- <div class="col d-flex justify-content-end">
                            <div>
                                <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                    (change)="onPFInfoChange($event)" value="info">
                                    <mat-button-toggle value="info">Info</mat-button-toggle>
                                    <mat-button-toggle value="adresa">Adresa</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="box-content-body">
                    <table class="table table-condensed table-wrapper-box" *ngIf="selectedPFStatus === 'info'">
                        <tbody>
                            <tr>
                                <td width="40%" class="text-center"><strong>Nume</strong></td>
                                <td class="text-left">{{ cerereCetatean.nume }}</td>
                            </tr>
                            <tr>
                                <td class="text-center"><strong>Prenume</strong></td>
                                <td class="text-left">{{ cerereCetatean.prenume }}</td>
                            </tr>
                            <tr>
                                <td class="text-center"><strong>Email</strong></td>
                                <td class="text-left">{{ cerereCetatean.email }}</td>
                            </tr>
                            <tr>
                                <td class="text-center"><strong>Telefon</strong></td>
                                <td class="text-left">{{ cerereCetatean.telefon }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <table class="table table-condensed table-wrapper-box" *ngIf="selectedPFStatus === 'adresa'">
                        <tbody>
                            <tr>
                                <td width="40%" class="text-center"><strong>Judet</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.judet }}</td>
                            </tr>
                            <tr>
                                <td class="text-center"><strong>Oras/Municipiu/Comuna/Sat</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.oras }}</td>
                            </tr>
                            <tr *ngIf="cerere.homeAddress.sector !== null">
                                <td class="text-center"><strong>Sector</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.sector }}</td>
                            </tr>
                            <tr>
                                <td class="text-center"><strong>Strada</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.strada }}</td>
                            </tr>
                            <tr>
                                <td class="text-center"><strong>Numar</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.numar }}</td>
                            </tr>
                            <tr *ngIf="cerere.homeAddress.bloc !== null">
                                <td class="text-center"><strong>Bloc</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.bloc }}</td>
                            </tr>
                            <tr *ngIf="cerere.homeAddress.scara !== null">
                                <td class="text-center"><strong>Scara</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.scara }}</td>
                            </tr>
                            <tr *ngIf="cerere.homeAddress.etaj !== null">
                                <td class="text-center"><strong>Etaj</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.etaj }}</td>
                            </tr>
                            <tr *ngIf="cerere.homeAddress.apartament !== null">
                                <td class="text-center"><strong>Apartament</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.apartament }}</td>
                            </tr>
                            <tr *ngIf="cerere.homeAddress.cod_postal !== null">
                                <td class="text-center"><strong>Cod Postal</strong></td>
                                <td class="text-left">{{ cerere.homeAddress.cod_postal }}</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-12 col-md-6">
            <div class="box-wrapper box-property">
                <div class="box-content-header">
                    <div class="row p-1">
                        <div class="col">
                            <mat-card-title>
                                <i class="fa-solid fa-location-crosshairs"></i>
                                <span>Adresă Proprietate</span>
                            </mat-card-title>
                        </div>
                    </div>
                </div>
                <div class="box-content-body">
                    <table class="table table-condensed table-wrapper-box">
                        <tbody>
                            <tr>
                                <td width="40%" class="text-center"><strong>Strada</strong></td>
                                <td class="text-left">{{ cerere.propertyAddress.strada }}</td>
                            </tr>
                            <tr>
                                <td class="text-center"><strong>Numar</strong></td>
                                <td class="text-left">{{ cerere.propertyAddress.numar }}</td>
                            </tr>
                            <tr *ngIf="cerere.propertyAddress.bloc !== null">
                                <td class="text-center"><strong>Bloc</strong></td>
                                <td class="text-left">{{ cerere.propertyAddress.bloc }}</td>
                            </tr>
                            <tr *ngIf="cerere.propertyAddress.scara !== null">
                                <td class="text-center"><strong>Scara</strong></td>
                                <td class="text-left">{{ cerere.propertyAddress.scara }}</td>
                            </tr>
                            <tr *ngIf="cerere.propertyAddress.etaj !== null">
                                <td class="text-center"><strong>Etaj</strong></td>
                                <td class="text-left">{{ cerere.propertyAddress.etaj }}</td>
                            </tr>
                            <tr *ngIf="cerere.propertyAddress.apartament !== null">
                                <td class="text-center"><strong>Apartament</strong></td>
                                <td class="text-left">{{ cerere.propertyAddress.apartament }}</td>
                            </tr>
                            <tr *ngIf="cerere.propertyAddress.cod_postal !== null">
                                <td class="text-center"><strong>Cod Postal</strong></td>
                                <td class="text-left">{{ cerere.propertyAddress.cod_postal }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </div>
</div>

<!-- Persoana juridica -->
<!-- <div class="g-0">

    <div class="box-wrapper box-property">
        <div class="box-content-header">
            <div class="row p-1">
                <div class="col">
                    <mat-card-title>
                        <i class="fa-solid fa-clipboard-list"></i>
                        <span>Informații adiționale</span>
                    </mat-card-title>
                </div>
            </div>
        </div>
        <div class="box-content-body">

        </div>
    </div>

    <div class="row p-3">
        <div class="col">
            <mat-card-title>
                <img src="assets/icons/societate-files-icon.svg" alt="Societate">
                Informații adiționale
            </mat-card-title>
        </div>
        <div class="col d-flex justify-content-end">
            <div>
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onPJInfoFilesChange($event)"
                    value="info">
                    <mat-button-toggle value="info">Info</mat-button-toggle>
                    <mat-button-toggle value="orar">Orar</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="row p-3" *ngIf="selectedPJFiles === 'info'">
            <div class="col">
                <div>Activități CAEN</div>
                <div class="fw-bold">{{ mockUserPJ.suplimentare.caen }}</div>
            </div>
            <div class="col">
                <div>Observații</div>
                <div class="fw-bold">{{ mockUserPJ.suplimentare.observatii }}</div>
            </div>
        </div>
        <div class="row p-3" *ngIf="selectedPJFiles === 'orar'">
            <div class="col">
                <div>Test</div>
                <div class="fw-bold">{{ mockUserPJ.orar.orar1 }}</div>
            </div>
            <div class="col">
                <div>Observații</div>
                <div class="fw-bold">{{ mockUserPJ.orar.orar2 }}</div>
            </div>
        </div>
    </div>

</div> -->
<!-- </mat-card> -->