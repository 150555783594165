import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsComponent } from './clients.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { AddClientComponent } from './add-client/add-client.component';
import { InfoClientComponent } from './info-client/info-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { ClientGeneralInfoComponent } from './info-client/client-general-info/client-general-info.component';
import { ClientContactsComponent } from './info-client/client-contacts/client-contacts.component';
import { ClientOperatorsComponent } from './info-client/client-operators/client-operators.component';
import { ClientPermissionsComponent } from './info-client/client-permissions/client-permissions.component';
import { ClientContactsModule } from './info-client/client-contacts/client-contacts.module';
import { ClientOperatorsModule } from './info-client/client-operators/client-operators.module';

@NgModule({
    declarations: [
        ClientsComponent,
        AddClientComponent,
        InfoClientComponent,
        EditClientComponent,
        ClientGeneralInfoComponent,
        ClientPermissionsComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ClientContactsModule,
        ClientOperatorsModule
    ],
    exports: [ClientsComponent, ClientContactsModule, ClientOperatorsModule],
})
export class ClientsModule { }
