import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { DirectiiService } from '../../../settings-sidenav/directii/directii.service';
import { Observable, finalize, map } from 'rxjs';
import { Generic } from 'src/app/interfaces/generic.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../requests.service';
import { UsersService } from '../../../settings-sidenav/users-settings/users.service';
import { UpdateMassAssignPayload } from '../request.interface';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-assign-request',
  templateUrl: './assign-request.component.html',
  styleUrls: ['./assign-request.component.scss'],
})
export class AssignRequestComponent implements OnInit {
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;

  isLoading = false;

  defaultParameter: { name: string; id: string } = {
    name: 'Toate',
    id: '-1',
  };

  directii$!: Observable<Generic[]>;
  compartimenteActive$!: Observable<Generic[]>;
  users$!: Observable<Generic[]>;

  form: FormGroup = new FormGroup({
    directie_id: new FormControl('', [Validators.required]),
    compartiment_id: new FormControl(''),
    operator: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: number[],
    private dialogRef: MatDialogRef<AssignRequestComponent>,
    private directiiService: DirectiiService,
    private requestsService: RequestsService,
    private usersService: UsersService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadRequestStatuses();
    this.loadOperatori();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  onDirectieChange(directieId: number): void {
    if (directieId) {
      this.loadCompartimenteActive(directieId);
    } else {
      this.compartimenteActive$ = new Observable<Generic[]>((observer) => {
        // observer.next([{ name: '--Alegeți--', id: -1 }]);
        observer.complete();
      });
    }
  }

  /*
    Commented the default parameter because the BE cannot accept negative values
  */

  loadCompartimenteActive(directieId?: number) {
    this.isLoading = true;
    let compartimenteObservable$ = directieId
      ? this.requestsService.getCompartimenteByDirectieId(directieId)
      : this.requestsService.getCompartimenteActive();

    this.compartimenteActive$ = compartimenteObservable$.pipe(
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  loadRequestStatuses() {
    this.isLoading = true;
    this.directii$ = this.directiiService.getDirectii().pipe(
      // map((results: any) => [...results, this.defaultParameter]),
      finalize(() => (this.isLoading = false))
    );
  }

  loadOperatori() {
    this.isLoading = true;
    this.users$ = this.usersService.getUsers().pipe(
      // map((results: any) => [...results, this.defaultParameter]),
      finalize(() => (this.isLoading = false))
    );
  }

  submitForm() {
    this.isLoading = true;

    const ids_cerere = this.data;
    const directie_id = this.form.get('directie_id')?.value;
    const compartiment_id = this.form.get('compartiment_id')?.value;
    const user_id = this.form.get('operator')?.value;

    const newAssign: UpdateMassAssignPayload = {
      ids_cerere,
      directie_id: directie_id,
      compartiment_id: compartiment_id,
      user_id: user_id,
    };

    this.requestsService
      .updateMassAssign(newAssign)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        },
      });
  }
}
