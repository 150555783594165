import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ConfigAdminService } from '../config-admin.service';

import { ConfigAdmin, ConfigAdminEditPayload } from '../config-admin.interface';
import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-edit-config-admin',
  templateUrl: './edit-config-admin.component.html',
  styleUrls: ['./edit-config-admin.component.scss'],
})
export class EditConfigAdminComponent {
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;
  helper = helper;

  token!: string;
  isLoading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl(this.data.name, [Validators.required]),
    value: new FormControl(this.data.value, []),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfigAdmin,
    private dialogRef: MatDialogRef<EditConfigAdminComponent>,
    private configService: ConfigAdminService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.processToken();
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  processToken() {
    const tokenObj = this.authService.returnTokenUser();
    tokenObj.then((token) => {
      this.token = token;
      this.configService.setToken(token);
    });
  }

  submitForm() {
    this.isLoading = true;

    const { name, value } = this.form.value as ConfigAdmin;
    const payload: ConfigAdminEditPayload = {
      id_configuration: this.data.id_configuration,
      name,
      value,
    };

    this.configService
      .updateConfiguration(payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: (err: any) => {
          let msg;
          try {
            // try to catch the message from server
            msg = err.error.errors.message[0];
          } catch (error) {
            // controll output message
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            helper.dialogConfig.headers.errorAtentie,
            msg,
            this.errorIcon
          );
        },
      });
  }

  onHttpError(res: any) {
    if (res.errors && res.errors.message && res.errors.message.length > 0) {
      res.errors.message.forEach((msg: string) => {
        this.notificationService.warningSwal(
          this.errorTitle,
          msg,
          this.errorIcon
        );
      });
    } else {
      let msg;
      try {
        // try to catch the message from server
        msg = res.error.errors.message[0];
      } catch (error) {
        // controll output message
        msg = helper.dialogConfig.generalMessages.error;
      }
      this.notificationService.warningSwal(
        this.errorTitle,
        msg,
        this.errorIcon
      );
    }
  }
}
