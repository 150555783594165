import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable, of, Subscription } from 'rxjs';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { environment } from 'src/environments/environment';
import { PlatformContentService } from './platform-content.service';
import Swal from 'sweetalert2';
import { AddPlatformContentComponent } from './add-platform-content/add-platform-content.component';
import { EditPlatformContentComponent } from './edit-platform-content/edit-platform-content.component';

import { helper } from 'src/environments/helper';
import { EditPlatformSection } from '../platform-section/platform-section.interface';
import { PlatformSectionService } from '../platform-section/platform-section.service';
import { PermissionsService } from 'src/app/services/permissions.service';
@Component({
    selector: 'app-platform-content',
    templateUrl: './platform-content.component.html',
    styleUrls: ['./platform-content.component.scss'],
})
export class PlatformContentComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    showCard = false;
    activeValue: string = '-1';
    sectionId: string = '-1';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.platformContent
            .getPlatformContentsDT;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    isSectionsLoading = true;
    sections$!: Observable<EditPlatformSection[]>;

    toggleCard() {
        this.showCard = !this.showCard;
    }

    resetForm() {
        this.activeValue = '-1';
        this.sectionId = '-1';

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private platformContentService: PlatformContentService,
        private platformSectionService: PlatformSectionService,
        public permissionsService: PermissionsService,
    ) {
        this.getSectionsList();
    }

    ngOnInit(): void {
        this.renderDT();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    getSectionsList() {
        let platformResponse = this.platformSectionService.getPlatformSections();

        platformResponse.subscribe({
            next: (sections: any) => {
                sections.unshift({ id: '-1', name: '--Alegeți--' });
                this.sections$ = of(sections as EditPlatformSection[]);
                this.isSectionsLoading = false;
            },
            error: (error) => {
                console.log(error);
                this.isSectionsLoading = false;
            },
        });
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            responsive: true,
            searching: false,
            pageLength: 25,

            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.active = this.activeValue;
                dataTablesParameters.section_id = this.sectionId;
                this.isLoading = true;

                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'id',
                },
                {
                    title: 'Titlu',
                    data: 'title',
                    width: '400px',
                },
                {
                    title: 'Secțiune',
                    data: 'section',
                },
                {
                    title: 'Order',
                    data: null,
                    sortable: false,
                    render: (data: any, type: any, row: any, met: any) => {
                        return (
                            `<div class="order-section-dt">` +
                            (this.sectionId != '-1'
                                ? `
							<button type="button" class="action-btn down-order btn btn-sm btn-primary" ${row.order == row.section_max_order ? 'disabled="true"' : ''
                                } (click)="updateContentOrderInSection(${row.id}, 1)">
								<img class="btn-img" src="/assets/icons/down-white.svg" alt="down-order"/>
							</button>
						`
                                : '') +
                            `<span class="order">${row.order}</span>` +
                            (this.sectionId != '-1'
                                ? `
							<button type="button" class="action-btn up-order btn btn-sm btn-primary" ${row.order == 1 ? 'disabled="true"' : ''
                                } (click)="updateContentOrderInSection(${row.id}, -1)">
								<img class="btn-img" src="/assets/icons/up-white.svg" alt="up-order"/>
							</button>
						`
                                : '') +
                            `</div>`
                        );
                    },
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    className: 'all',
                    orderable: false,
                    render: (data: any, type: any, row: any, met: any) => {
                        return (
                            `
              <div class="d-flex align-center justify-content-left">
							<button class="action-btn edit ms-1">
								<img class="btn-img" src="/assets/icons/edit.svg" alt="edit"/>
							</button>
							<button class="action-btn delete ms-1">
								<img class="btn-img" src="/assets/icons/delete.svg" alt="delete"/>
							</button>
							<button type="button" class="action-btn update_status_active ms-1 ` +
                            (row.active == 1 ? 'action-success' : 'action-danger') +
                            `">
								<img class="btn-img" src="` +
                            (row.active == 1
                                ? '/assets/icons/check-white.svg'
                                : '/assets/icons/close-white.svg') +
                            `" alt="update_status_active"/>
							</button>
              </div>
            			`
                        );
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;
                const updateStatusActiveIcon = lastTd?.querySelector(
                    '.update_status_active'
                ) as HTMLElement;

                const orderTD = (row as HTMLElement).querySelector('td:nth-child(4)');
                const upOrderInSection = orderTD?.querySelector('.up-order') as HTMLElement;
                const downOrderInSection = orderTD?.querySelector('.down-order') as HTMLElement;

                if (editIcon) {
                    if (!this.permissionsService.hasPermission('settings.platform-content.update')) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (deleteIcon) {
                    if (!this.permissionsService.hasPermission('settings.platform-content.delete')) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.deletePlatformSection(id);
                    });
                }

                if (updateStatusActiveIcon) {
                    if (!this.permissionsService.hasPermission('settings.platform-content.update-status')) {
                        updateStatusActiveIcon.style.display = 'none';
                    }
                    updateStatusActiveIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusActive(id);
                    });
                }

                if (upOrderInSection) {
                    if (!this.permissionsService.hasPermission('settings.platform-content.update')) {
                        upOrderInSection.style.display = 'none';
                    }
                    upOrderInSection.addEventListener('click', () => {
                        const { id } = data;
                        this.updateContentOrderInSection(id, -1);
                    });
                }

                if (downOrderInSection) {
                    if (!this.permissionsService.hasPermission('settings.platform-content.update')) {
                        downOrderInSection.style.display = 'none';
                    }
                    downOrderInSection.addEventListener('click', () => {
                        const { id } = data;
                        this.updateContentOrderInSection(id, 1);
                    });
                }

                return row;
            },
        };
    }

    updateContentOrderInSection(id: number, index: number) {
        console.log(id, index);

        this.isLoading = true;
        this.platformContentService.updateOrder(id, { index }).subscribe(
            () => {
                this.rerenderDT();
                // will need a toast here :) for good times :)
            },
            (err) => {
                this.rerenderDT();
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }

                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                });
            }
        );
    }

    changeStatusActive(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul conținutului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusActiveAction(id);
            }
        });
    }

    changeStatusActiveAction(id: number) {
        this.platformContentService.changeStatusActive(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Conținut actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusActiveAction(id);
                    }
                });
            }
        );
    }

    rerenderDT(paging: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paging);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddPlatformContentComponent, {
            ...this.dialogService.getDialogConfig(),
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Conținut adăugat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: any) {
        const dialogRef = this.dialog.open(EditPlatformContentComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Conținut actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    deletePlatformSection(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // we do this because we need to have some control on activity
                this.deletePlatformSectionAction(id);
            }
        });
    }

    deletePlatformSectionAction(id: number) {
        this.platformContentService.deletePlatformContent(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Conținut șters cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }

                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deletePlatformSectionAction(id);
                    }
                });
            }
        );
    }
}
