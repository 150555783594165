import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
	CompartimentAddPayload,
	CompartimentUpdatePayload,
} from './compartiment.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CompartimenteService {
	private token!: string;
	private httpOptions: any;

	private basePath = environment.interop.basePath;
	private getCompartimenteUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment.getCompartimente;

	private getCompartimenteByDirectieUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment.getCompartimenteByDirectie;

		
	private getCompartimenteDTUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment.getCompartimenteDT;
	private storeCompartimenteUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment.storeCompartiment;
	private updateCompartimentUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment.putCompartiment;
	private deleteCompartimentUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment.deleteCompartiment;
	private getCompartimenteActiveUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment
			.getCompartimenteActive;
	private updateCompartimentActiveStatusUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment
			.changeStatusCompartimentActive;
	private updateCompartimentPublicStatusUrl =
		this.basePath +
		environment.interop.platform.nomenclatoare.compartiment
			.changeStatusCompartimentPublic;

	constructor(private http: HttpClient) { }

	getCompartimenteDT() {
		return this.http.get(this.getCompartimenteDTUrl, this.httpOptions);
	}

	addCompartiment(data: CompartimentAddPayload) {
		return this.http.post(this.storeCompartimenteUrl, data, this.httpOptions);
	}

	updateCompartiment(data: CompartimentUpdatePayload) {
		return this.http.put(this.updateCompartimentUrl, data, this.httpOptions);
	}

	deleteCompartiment(compartimentId: number) {
		return this.http.delete(this.deleteCompartimentUrl + compartimentId);
	}

	getCompartimenteActive() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getCompartimenteActiveUrl
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({
						id: item.id_compartiment,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get compartimente active:', error);
					return throwError(error);
				})
			);
	}

	getCompartimenteByDirectieId(id: number) {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getCompartimenteByDirectieUrl + id
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({
						id: item.id_compartiment,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in getCompartimenteByDirectieId:', error);
					return throwError(error);
				})
			);
	}

	getCompartimente() {
		return this.http
			.get<{ errors: boolean; data: any[]; status_code: number }>(
				this.getCompartimenteUrl
			)
			.pipe(
				map((response) =>
					response.data.map((item) => ({
						id: item.id_compartiment,
						name: item.name,
					}))
				),
				catchError((error) => {
					console.error('Error in get compartimente:', error);
					return throwError(error);
				})
			);
	}

	changeStatuseActive(id: number) {
		return this.http.put(
			this.updateCompartimentActiveStatusUrl + id,
			{},
			this.httpOptions
		);
	}

	setToken(token: string) {
		this.token = token;
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			}),
		};
	}
}
