<div class="alert-container">
    <div class="alert-icon">
        <mat-icon color="warn" class="alert-icon__cancel">cancel</mat-icon>
    </div>
    <div class="alert-description">
        <div class="alert-description__title">{{data.title}}</div>
        <div class="alert-description__text">{{data.msg}}</div>
    </div>
    <div class="alert-action">
        <button (click)="closeDialog()" mat-raised-button color="primary" class="alert-action__close-btn">{{helperData.dialogConfig.buttonLabels.close}}</button>
    </div>
</div>