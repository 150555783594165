import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CronsComponent } from './crons.component';
import { AddCronComponent } from './add-cron/add-cron.component';
import { EditCronComponent } from './edit-cron/edit-cron.component';



@NgModule({
  declarations: [CronsComponent, AddCronComponent, EditCronComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [CronsComponent],
})
export class CronsModule { }
