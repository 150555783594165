<div class="add-container">
	<h1 class="add-title">Adaugă client</h1>

	<form class="add-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Nume</mat-label>
				<input type="text" matInput formControlName="name" />
			</mat-form-field>
			<mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
				Câmp obligatoriu
			</mat-error>
		</div>

        <div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Nume Afișaj</mat-label>
				<input type="text" matInput formControlName="display_name" />
			</mat-form-field>
			<mat-error *ngIf="!form.controls['display_name'].valid && form.controls['display_name'].touched">
				Câmp obligatoriu
			</mat-error>
		</div>

        <div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Observații</mat-label>
				<input type="text" matInput formControlName="observations" />
			</mat-form-field>
			<mat-error *ngIf="!form.controls['observations'].valid && form.controls['observations'].touched">
				Câmp obligatoriu
			</mat-error>
		</div>

        <div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Logo dimeniune mică</mat-label>
				<input type="text" matInput formControlName="logo_small" />
			</mat-form-field>
			<mat-error *ngIf="!form.controls['logo_small'].valid && form.controls['logo_small'].touched">
				Câmp obligatoriu
			</mat-error>
		</div>

        <div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Logo dimeniune mare</mat-label>
				<input type="text" matInput formControlName="logo_large" />
			</mat-form-field>
			<mat-error *ngIf="!form.controls['logo_large'].valid && form.controls['logo_large'].touched">
				Câmp obligatoriu
			</mat-error>
		</div>

        <div class="add-field">
			<mat-form-field appearance="fill">
				<mat-label>Culoare</mat-label>
				<input input type="color" matInput formControlName="bg_color" />
			</mat-form-field>
			<mat-error *ngIf="!form.controls['bg_color'].valid && form.controls['bg_color'].touched">
				Câmp obligatoriu
			</mat-error>
		</div>

		<div class="d-flex align-center justify-content-between">
			<button type="button" mat-flat-button (click)="closeDialog()">
                {{ helper.dialogConfig.buttonLabels.close }}
			</button>
			<button mat-flat-button color="primary" type="submit" [disabled]="form.invalid">
                {{ helper.dialogConfig.buttonLabels.save }}
			</button>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>