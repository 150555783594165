import { Component, ViewChild, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { helper } from 'src/environments/helper';
import { DataTableDirective } from 'angular-datatables';
import { finalize, map, Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DialogService } from '../../../../../../utils/services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Generic } from '../../../../../../interfaces/generic.interface';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { Client } from '../../client.interface';
import { FunctiiService } from '../../../settings-sidenav/functii/functii.service';
import { AddClientContactComponent } from './add-client-contact/add-client-contact.component';
import { EditClientContactComponent } from './edit-client-contact/edit-client-contact.component';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ClientContactsService } from './client-contacts.service';

@Component({
    selector: 'app-client-contacts',
    templateUrl: './client-contacts.component.html',
    styleUrls: ['./client-contacts.component.scss']
})
export class ClientContactsComponent {
    @Input() client!: Client;
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    showCard = false;
    activeValue: string = '-1';
    publicValue: string = '-1';
    functieValue: number | string = '-1';
    //directieValue: number | string = '-1';
    //compartimentValue: number | string = '-1';
    //userValue: number | string = '-1';

    firstNameSearch: string | null = '';
    lastNameSearch: string | null = '';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink = environment.interop.basePath + environment.interop.clients.contacts.getContactsDT;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    defaultParameter: { name: string; id_directie: string } = {
        name: '--Alegeți--',
        id_directie: '-1',
    };

    functiiActive$!: Observable<Generic[]>;
    //directiiActive$!: Observable<Generic[]>;
    //compartimenteActive$!: Observable<Generic[]>;
    //userTypes$!: Observable<Generic[]>;

    toggleCard() {
        this.showCard = !this.showCard;
    }
    constructor(
        private dialogService: DialogService,
        public functiiService: FunctiiService,
        //public directiiService: DirectiiService,
        //public compartimenteService: CompartimenteService,
        private http: HttpClient,
        private dialog: MatDialog,
        public permissionsService: PermissionsService,
        private clientContactService: ClientContactsService,
        //private usersService: UsersService
    ) { }

    ngOnInit(): void {
        this.renderDT();
        this.loadFunctiiActive();
        //this.loadDirectiiActive();
        // this.loadCompartimenteActive(); // we load the compartimente active only when we select a directie
        //this.loadUserTypes();
    }

    loadFunctiiActive() {
        this.isLoading = true;
        this.functiiActive$ = this.functiiService.getFunctiiActive().pipe(
            map((results: any) => [this.defaultParameter, ...results]),
            finalize(() => {
                this.isLoading = false;
            })
        );
    }

    /*
    loadUserTypes() {
      this.isLoading = true;
      this.userTypes$ = this.usersService.getUserTypeActive().pipe(
        map((results: any) => [this.defaultParameter, ...results]),
        finalize(() => {
          this.isLoading = false;
        })
      );
    }
  
    onDirectieChange(directieId: number): void {
      if (directieId) {
        this.loadCompartimenteActive(directieId);
      } else {
        this.compartimenteActive$ = new Observable<Generic[]>((observer) => {
          observer.next([{ name: '--Alegeți--', id: -1 }]);
          observer.complete();
        });
  
        console.log(this.compartimenteActive$, 'check here pls');
      }
    }
  
    loadDirectiiActive() {
      this.isLoading = true;
      this.directiiActive$ = this.directiiService.getDirectiiActive().pipe(
        map((results: any) => [this.defaultParameter, ...results]),
        finalize(() => {
          this.isLoading = false;
        })
      );
    }
  
    loadCompartimenteActive(directieId?: number) {
      console.log(directieId, 'directieId');
      this.isLoading = true;
      let compartimenteObservable$ = directieId
        ? this.compartimenteService.getCompartimenteByDirectieId(directieId)
        : this.compartimenteService.getCompartimenteActive();
  
      this.compartimenteActive$ = compartimenteObservable$.pipe(
        map((results: any) => [this.defaultParameter, ...results]),
        finalize(() => {
          this.isLoading = false;
        })
      );
    }
  */
    resetForm() {
        this.activeValue = '-1';
        this.publicValue = '-1';
        this.functieValue = '-1';
        //this.directieValue = '-1';
        //this.compartimentValue = '-1';
        //this.userValue = '-1';

        this.firstNameSearch = null;
        this.lastNameSearch = null;

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }


    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            searching: false,
            pageLength: 25,
            responsive: true,

            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.client_id = this.client.id_client;
                dataTablesParameters.active = this.activeValue;
                this.isLoading = true;

                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID Contact',
                    data: 'id',
                },
                {
                    title: 'Nume',
                    data: 'last_name',
                },
                {
                    title: 'Prenume',
                    data: 'first_name',
                },
                {
                    title: 'Email',
                    data: 'email',
                },
                {
                    title: 'Telefon',
                    data: 'phone',
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    className: 'all',
                    orderable: false,
                    render: (data: any, type: any, row: any, met: any) => {
                        return (
                            `
                  <div class="d-flex align-center justify-content-left">
                <button class="action-btn edit ms-1">
                    <img class="btn-img" src="/assets/icons/edit.svg" alt="edit"/>
                </button>
                <button type="button" class="action-btn delete ms-1">
                    <img class="btn-img" src="/assets/icons/delete.svg" alt="delete"/>
                </button>
                <button type="button" class="action-btn update_status_active ms-1 ` +
                            (row.active == true ? 'action-success' : 'action-danger') +
                            `">
					<img class="btn-img" src="` +
                            (row.active == true
                                ? '/assets/icons/check-white.svg'
                                : '/assets/icons/close-white.svg') +
                            `"        alt="update_status_active"/>
                </button>
                <button type="button" class="action-btn update_status_public ms-1 ` +
                            (row.public_visible == true
                                ? 'action-success'
                                : 'action-danger') +
                            `">
					<img class="btn-img" src="` +
                            (row.public_visible == true
                                ? '/assets/icons/public_white.svg'
                                : '/assets/icons/public_not_white.svg') +
                            `" alt="update_status_public"/>
                </button>
                </div>
            `
                        );
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const updateStatusActiveIcon = lastTd?.querySelector(
                    '.update_status_active'
                ) as HTMLElement;
                const updateStatusPublicIcon = lastTd?.querySelector(
                    '.update_status_public'
                ) as HTMLElement;
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;

                if (editIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'clients.contacts.update'
                        )
                    ) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (updateStatusActiveIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'clients.contacts.update-status'
                        )
                    ) {
                        updateStatusActiveIcon.style.display = 'none';
                    }
                    updateStatusActiveIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusActive(id);
                    });
                }

                if (updateStatusPublicIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'clients.contacts.update-status'
                        )
                    ) {
                        updateStatusPublicIcon.style.display = 'none';
                    }
                    updateStatusPublicIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusPublic(id);
                    });
                }

                if (deleteIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'clients.contacts.delete'
                        )
                    ) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.deleteContact(id);
                    });
                }

                return row;
            },
        };
    }

    changeStatusActive(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul contactului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusActiveAction(id);
            }
        });
    }

    changeStatusActiveAction(id: number) {
        this.clientContactService.changeStatuseActive(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Contact actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err: any) => {
                let msg;
                try {
                    msg = err.error.errors.message[0];
                } catch (error) {
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusActiveAction(id);
                    }
                });
            }
        );
    }

    changeStatusPublic(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul contactului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusPublicAction(id);
            }
        });
    }

    changeStatusPublicAction(id: number) {
        this.clientContactService.changeStatusePublic(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Contact actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err: any) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusPublicAction(id);
                    }
                });
            }
        );
    }

    deleteContact(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteAction(id);
            }
        });
    }

    deleteAction(id: number) {
        this.clientContactService.deleteClientContact(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Contact șters cu succes!',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err: any) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }

                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteAction(id);
                    }
                });
            }
        );
    }


    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddClientContactComponent, {
            ...this.dialogService.getDialogConfig(),
            data: this.client,
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Contact adăugat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: any) {
        const dialogRef = this.dialog.open(EditClientContactComponent, {
            ...this.dialogService.getDialogConfig(),
            data: { id: data.id, id_client: data.id_client },
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Contact actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }
}
