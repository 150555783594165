import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CerereStatusComponent } from './cerere-status.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { AddCerereStatusComponent } from './add-cerere-status/add-cerere-status.component';
import { EditCerereStatusComponent } from './edit-cerere-status/edit-cerere-status.component';

@NgModule({
  declarations: [CerereStatusComponent, AddCerereStatusComponent, EditCerereStatusComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [CerereStatusComponent],
})
export class CerereStatusModule {}
