<div class="edit-container">
    <h1 class="edit-title">Actualizare secțiune platformă</h1>
    <form class="edit-card__form" [formGroup]="form" (submit)="submitForm()">
        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Denumire</mat-label>
                    <input type="text" matInput formControlName="name">
                </mat-form-field>
                <mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
                    Câmp obligatoriu
                </mat-error>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Slug</mat-label>
                    <input type="text" matInput formControlName="slug">
                </mat-form-field>
                <mat-error *ngIf="!form.controls['slug'].valid && form.controls['slug'].touched && !form.get('slug')?.hasError('invalidCharacter')">
                    Câmp obligatoriu
                </mat-error>
                <mat-error *ngIf="form.get('slug')?.hasError('invalidCharacter')">
                    Caracterele spațiu și punct nu sunt permise
                </mat-error>

            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Observații</mat-label>
                    <input type="text" matInput formControlName="observations">
                </mat-form-field>
                <mat-error *ngIf="!form.controls['observations'].valid && form.controls['observations'].touched">
                    Câmp obligatoriu
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col d-flex justify-content-between">
                <button type="button" class="buttons" mat-flat-button (click)="closeDialog()">
                    {{ helper.dialogConfig.buttonLabels.close }}
                </button>
                <button class="buttons" mat-flat-button color="primary" type="submit" [disabled]="form.invalid">
                    {{ helper.dialogConfig.buttonLabels.save }}
                </button>
            </div>
        </div>
    </form>

    <div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>
