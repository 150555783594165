import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'

import { JwtHelperService } from '@auth0/angular-jwt';
const helperJWT = new JwtHelperService();

import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { StorageService } from '../utils/storage/storage.service'

const TOKEN_KEY = environment.config.tokenKey;
import { Router } from '@angular/router';

import { IChangePasswordForceUserPayload } from './login/change-password-force.interface'


@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private token: string = ''

	private basePath = environment.interop.basePath;

	private loginUrl = this.basePath + environment.interop.user.loginUrl;
	private logoutUrl = this.basePath + environment.interop.user.logoutUrl;
	private userDetailsUrl = this.basePath + environment.interop.user.getUserDetails;

	private changeUserPassforceUrl = this.basePath + environment.interop.user.changeUserPassforce;

	private httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	};

	constructor(
		private http: HttpClient,
		private localStorage: StorageService,
		private router: Router
	) { }

	setToken(token: string) {
		this.token = token
	}

	public setAuthenticatedNext(bool: boolean): void {
		this.isAuthenticated.next(bool)
	}

	login(credentials: { email: string, password: string }) {
		return this.http.post<{ data: any }>(this.loginUrl, credentials);
	}

	logout() {
		return this.http.post(this.logoutUrl, {});
	}

	getUserDetails() {
		return this.http.get(this.userDetailsUrl);
	}

	updatePasswordForce(data: IChangePasswordForceUserPayload) {
		return this.http.post(this.changeUserPassforceUrl, data, this.httpOptions);
	}
}
