<div class="section position-relative">
    <div class="section-top">
        <h3 class="section-title">Operatori</h3>
        <div class="section-top-actions">
            <button type="button" class="btn btn-sm btn-info pull-right ms-1" (click)="rerenderDT(false)">
                <i class="fa-solid fa-arrows-rotate"></i>
            </button>
            <button type="button" 
                *ngIf="permissionsService.hasPermission('clients.operators.add')"
                class="btn btn-sm btn-primary pull-right ms-1" (click)="openAddDialog()"
            >
                <i class="fa-solid fa-plus"></i>
                Adaugă
            </button>
        </div>
    </div>

    <div class="card-container p-2" *ngIf="showCard">
        <div class="row w-100">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Activ</mat-label>
                    <mat-select [(ngModel)]="activeValue">
                        <mat-option *ngFor="let param of parameters" [value]="param.value">
                            {{ param.key }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row w-100">
            <div class="col d-flex justify-content-start">
                <button (click)="toggleCard()" type="button" class="btn btn-sm btn-warning">Închide</button>
            </div>
            <div class="col d-flex justify-content-end">
                <button (click)="resetForm()" type="button" class="btn btn-sm btn-secondary">
                    Resetează
                </button>
                <button (click)="applyFilters()" type="button" class="btn btn-sm btn-primary ms-2">
                    Aplică
                </button>
            </div>
        </div>
    </div>

    <div class="col-sm-12 ps-3">
        <button type="button" class="btn btn-sm btn-default" (click)="toggleCard()" [class.active]="showCard">
            <i class="fa-solid fa-filter"></i> Filtre
        </button>
    </div>

    <div class="table-container">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover w-100"></table>
    </div>
    <div class="section-spinner position-absolute" *ngIf="isLoading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
        </div>
    </div>
</div>