import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject, Subscription, catchError, debounceTime, distinctUntilChanged, filter, finalize, map, of, skip, switchMap, takeUntil, tap } from 'rxjs';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { environment } from 'src/environments/environment';
import { RequestsService } from './requests.service';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { helper } from 'src/environments/helper';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { CerereStatusService } from '../../settings-sidenav/cerere-status/cerere-status.service';
import { Generic } from 'src/app/interfaces/generic.interface';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { initializeEditor } from 'src/app/utils/angular-editor/editorConfig';
import { AbstractControl, Form, FormControl, FormGroup } from '@angular/forms';
import { CerereTypeService } from '../../settings-sidenav/cerere-type/cerere-type.service';
import { UpdateRequestStatusComponent } from './update-request-status/update-request-status.component';
import { AssignRequestComponent } from './assign-request/assign-request.component';
import { Cerere } from './request.interface';
import { MatCalendar } from '@angular/material/datepicker';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { StorageService } from 'src/app/utils/storage/storage.service';
import { UatService } from '../../uat/uat.service';
import { UpdateRequestPriorityComponent } from './update-request-priority/update-request-priority.component';
import Swal from 'sweetalert2';
import { TipuriBeneficiarService } from '../../settings-sidenav/tipuri-beneficiar/tipuri-beneficiar.service';
@Component({
    selector: 'app-requests',
    templateUrl: './requests.component.html',
    styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();

    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    @ViewChild(PopoverDirective) popover!: PopoverDirective;
    helper = helper;
    editorConfig!: AngularEditorConfig;

    userId: number = -1;
    userDepartament: number = -1;

    isLoading = false;
    isFilterLoading = false;
    showCard = false;
    checkboxSelections = false;
    rowCount = 0;

    uatChangesSubscription = new Subscription();
    searchedUats: Generic[] = [];

    // Popover dates

    @ViewChild('startCalendar') startCalendar!: MatCalendar<Date>;
    @ViewChild('endCalendar') endCalendar!: MatCalendar<Date>;

    today: Date = new Date();
    tomorrow: Date = new Date();
    selectedStartDate: Date = new Date(new Date().getFullYear(), 0, 1);
    private _selectedEndDate: Date = new Date();
    selectedFilteringButton: string = 'current_year';
    directii!: Generic[];
    statusCerere!: Generic[];

    setLastXDays(numberOfDays: number): void {
        const date = new Date();
        date.setDate(this.today.getDate() - numberOfDays);
        this.selectedStartDate = date;
        this.updateCalendarView(this.startCalendar, date);
        this.rerenderDT();
        this.selectedFilteringButton = `last_${numberOfDays}_days`;
    }

    setYear(yearsBack: number): void {
        const year = this.today.getFullYear() - yearsBack;
        this.selectedStartDate = new Date(year, 0, 1); // 1 Ianuarie
        this.updateCalendarView(this.startCalendar, this.selectedStartDate);
        this.rerenderDT();
        this.selectedFilteringButton =
            yearsBack === 0 ? 'current_year' : 'previous_year';
    }

    updateCalendarView(calendar: MatCalendar<Date>, date: Date): void {
        if (calendar) {
            calendar.activeDate = date;
        }
    }

    // Methods used to dinamically select the start date according to the end date

    get selectedEndDate(): Date {
        return this._selectedEndDate;
    }

    set selectedEndDate(date: Date) {
        this._selectedEndDate = date;
        if (this.selectedStartDate > date) {
            this.selectedStartDate = new Date(date);
        }
    }

    formatDate(date: Date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    // Date popover filtering

    dateFilteringOptions: string[] = [
        'Adăugare',
        'Actualizare',
        'Eligibil',
        'NE-Eligibil',
        'Finalizare',
    ];

    dateFilterOption: string = 'Adăugare';

    // Values for status filtering
    defaultParameter: { name: string; id: number } = {
        name: 'Toate',
        id: -1,
    };

    selectedStatusID: any = this.defaultParameter.id;
    // statusParametersValue!: string;

    // Values for category filtering
    categoryToggleOptions = [
        { id: -1, value: 'Toate' },
        { id: 1, value: 'Departamentele mele' },
        { id: 2, value: 'Cererile mele' },
        { id: 3, value: 'Mesaje interne' },
    ];

    selectedCategory: string | number = this.categoryToggleOptions[0].id;

    // Value for notification filtering
    notificationParameter: { id: string; name: string } = { id: '-1', name: '' };

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    requestTypes$!: Observable<Generic[]>;
    compartimenteActive$!: Observable<Generic[]>;

    beneficiari$!: Observable<any[]>;
    // regiuni$!: Observable<any[]>;

    regiuni$: { id: number, name: string }[] = [
        { id: 1, name: 'Transilvania' },
        { id: 2, name: 'Oltenia' },
        { id: 3, name: 'Muntenia' },
        { id: 4, name: 'Moldova' },
        { id: 5, name: 'Maramureş + Bucovina' },
        { id: 6, name: 'Dobrogea' },
        { id: 7, name: 'Crişana' },
        { id: 8, name: 'Banat' },
    ];

    requestStatuses!: any;
    requestTypes!: any;

    parameters: { key: string; value: string }[] = [
        { key: '-- Alegeți --', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    toggleCard() {
        this.showCard = !this.showCard;
    }

    closePopover(): void {
        if (this.popover) {
            this.popover.hide();
        }
    }

    searchParamsForm!: FormGroup;

    //Form Initial Values

    initialFormValues = {
        directieValue: -1,
        compartimentValue: -1,
        tip_cerere: '-1',
        validated: '-1',
        invalidated: '-1',
        finalizat_lucrare: '-1',
        numarCerere: '',
        numarCerereIntern: '',
        siruta: '',
        uat_id: '',
        denumire_uat: '',
        tipBeneficiar: '-1',
        regiune: '-1',
        nume_reprezentant: '',
        email: '',
    };

    cerereStatusControl: AbstractControl = new FormControl('');
    directieControl: AbstractControl = new FormControl('');
    assignedId: AbstractControl = new FormControl('');
    internalMessages: AbstractControl = new FormControl('');

    buildSearchParamsForm() {
        this.searchParamsForm = new FormGroup({
            directieValue: new FormControl(-1),
            compartimentValue: new FormControl(-1),
            tip_cerere: new FormControl('-1'),
            validated: new FormControl('-1'),
            invalidated: new FormControl('-1'),
            finalizat_lucrare: new FormControl('-1'),
            numarCerere: new FormControl(''),
            numarCerereIntern: new FormControl(''),
            siruta: new FormControl(''),
            uat_id: new FormControl(''),
            denumire_uat: new FormControl(''),
            tipBeneficiar: new FormControl('-1'),
            regiune: new FormControl('-1'),
            nume_reprezentant: new FormControl(''),
            email: new FormControl(''),
            user_assigned_id: new FormControl('-1'),
            internal_messages: new FormControl('-1'),
        });

        this.cerereStatusControl = this.searchParamsForm.get(
            'cerereStatus'
        ) as AbstractControl;
        this.directieControl = this.searchParamsForm.get(
            'directieValue'
        ) as AbstractControl;
        this.assignedId = this.searchParamsForm.get(
            'user_assigned_id'
        ) as AbstractControl;
        this.internalMessages = this.searchParamsForm.get(
            'internal_messages'
        ) as AbstractControl;
    }

    resetForm() {
        this.searchParamsForm.reset(this.initialFormValues);

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.showCard = false;
        this.rerenderDT(true);
    }

    // Info for conditional assign and change status from DT

    selectedRowIds: number[] = [];

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.cereri.getCereriListDT;

    notificationServiceBefore: any = {
        currentStatus: -1,
        currentCategory: -1,
    }

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private requestsService: RequestsService,
        private cerereStatusService: CerereStatusService,
        private cerereTypeService: CerereTypeService,
        private notificationService: NotificationService,
        public permissionsService: PermissionsService,
        private storageService: StorageService,
        private uatService: UatService,
        private tipuriBeneficiarService: TipuriBeneficiarService,
    ) {
        this.tomorrow.setDate(this.today.getDate() + 1);
    }

    ngOnInit(): void {
        this.loadRequestTypes();
        this.loadRequestStatuses();
        this.renderDT();
        this.loadDirectiiActive();
        this.editorConfig = initializeEditor();
        this.buildSearchParamsForm();
        this.getUserDetails();
        this.redirectFromNotifications();
        this.filterCategoryOptions();
        this.renderUatSearch();
        this.loadBeneficiaries();
    }

    redirectFromNotifications() {
        this.notificationService.currentNotification
            .pipe(takeUntil(this._destroying$))
            .subscribe(
                (notification: { id: string; name: string }) => {
                    if (notification) {
                        this.notificationParameter = notification;

                        this.notificationServiceBefore.currentStatus = this.selectedStatusID;
                        this.notificationServiceBefore.currentCategory = this.selectedCategory;

                        this.selectedStatusID = '-1';
                        this.selectedCategory = -1;

                        this.rerenderDT();
                    }
                }
            );
    }

    removeChip(): void {
        // Resets the notification parameter which we send to our backend with -1 so the filter for this particular flag is canceled
        this.notificationParameter = { id: '-1', name: '' };
        this.notificationService.changeNotification(this.notificationParameter); // notifications - clear notifications selection from service
        // here we need to update our buttons
        this.selectedStatusID = this.notificationServiceBefore.currentStatus;
        this.selectedCategory = this.notificationServiceBefore.currentCategory;

        this.rerenderDT();
    }

    loadDirectiiActive() {
        this.isFilterLoading = true;
        this.requestsService
            .getDirectii()
            .pipe(
                map((results: any) => [this.defaultParameter, ...results]),
                finalize(() => (this.isFilterLoading = false))
            )
            .subscribe({
                next: (results) => {
                    this.directii = results;
                },
                error: (error) => {
                    console.error('Error loading directii', error);
                    this.isFilterLoading = false;
                },
            });
    }

    loadRequestStatuses() {
        this.isFilterLoading = true;
        this.cerereStatusService
            .getStatuses()
            .pipe(
                map((results: any) => [this.defaultParameter, ...results]),
                finalize(() => (this.isFilterLoading = false))
            )
            .subscribe({
                next: (results) => {
                    this.statusCerere = results;
                },
                error: (error) => {
                    console.error('Error loading cerere status', error);
                    this.isFilterLoading = false;
                },
            });
    }

    loadRequestTypes() {
        this.cerereTypeService.getRequestTypes().subscribe((data) => {
            this.requestTypes = data;
        });
    }

    onDirectieChange(directieId: number): void {
        if (directieId) {
            this.loadCompartimenteActive(directieId);
        } else {
            this.compartimenteActive$ = new Observable<Generic[]>((observer) => {
                observer.complete();
            });
        }
    }

    loadCompartimenteActive(directieId?: number) {
        if (directieId === -1) {
            this.compartimenteActive$ = of([]);
            return;
        }

        this.isFilterLoading = true;
        let compartimenteObservable$ = directieId
            ? this.requestsService.getCompartimenteByDirectieId(directieId)
            : this.requestsService.getCompartimenteActive();

        this.compartimenteActive$ = compartimenteObservable$.pipe(
            map((results: any) => [...results]),
            finalize(() => {
                this.isFilterLoading = false;
            })
        );
    }

    loadBeneficiaries() {
        this.isFilterLoading = true;
        this.beneficiari$ = this.tipuriBeneficiarService
            .getTipuriBeneficiar()
            .pipe(finalize(() => (this.isFilterLoading = false)));
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
        this._destroying$.next(undefined);
        this._destroying$.complete();
        this.notificationService.changeNotification({ id: '-1', name: '' }); // notifications - clear notifications selection from service
    }

    onDateFilterChange(): void {
        this.rerenderDT();
    }

    renderDT() {
        const self = this;
        this.isLoading = true;

        this.dtOptions = {
            order: [[1, 'desc']],
            responsive: true,
            serverSide: true,
            searching: false,
            pageLength: 25,
            ajax: (dataTablesParameters: any, callback: any) => {
                this.isLoading = true;
                dataTablesParameters.status = this.selectedStatusID;
                dataTablesParameters.categoryStatus = this.selectedCategory;

                const filters = { ...this.searchParamsForm.value };
                delete filters['denumire_uat'];
                dataTablesParameters.filters = filters;

                dataTablesParameters.dateFilter = this.dateFilterOption;
                dataTablesParameters.date_from = this.formatDate(
                    this.selectedStartDate
                );
                dataTablesParameters.date_to = this.formatDate(this.selectedEndDate);
                dataTablesParameters.notificationParameter =
                    this.notificationParameter.id;

                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        this.getTableRowCount();
                        this.checkboxesReset();

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columnDefs: [
                { width: '3%', targets: 0 },
                { width: '15%', targets: 1 },
                { width: '20%', targets: 2 },
                { width: '15%', targets: 3 },
                { width: '19%', targets: 4 },
                { width: '11%', targets: 5 },
                { width: '15%', targets: 6 },
            ],

            columns: [
                {
                    title: `<input type="checkbox" class="row-checkbox" style="width: 20px; height: 20px;" id="headerCheckbox"/>`,
                    data: 'id',
                    sortable: false,
                    render: (data: any, type: any, row: any) => {
                        return `<input type="checkbox" class="row-checkbox" style="width: 20px; height: 20px;" value="${row.id}" />`
                    }
                },
                {
                    title: `ID`,
                    data: 'id',
                    sortable: true,
                    render: (data: any, type: any, row: any) => {
                        let flagIcon = '<i class="fa-solid fa-flag" style="color: white;"></i> ';
                        return `
                                <div style="display: flex; align-items: center; gap: 5px;">
                                <span>${row.reg_number ? row.reg_number : 'N/A'}</span>
                                <span>/</span>
                                <span>${row.created}</span>
                                </div>
                                <div> <br/>
                                    <span  style="padding: 3px 6px; background-color: #bdbdbd; border: 0px;border-radius:10px">I: ${row.id}</span>
                                </div>
                                ` + (row.mesajeInterne == 1 ?
                                `<div> <br/><span  style="padding: 4px 6px; color: white; background-color: red; border: 0px;border-radius:10px">${flagIcon} Mesaj intern</span>
                                    </div>`
                                : '') + `
                            `;
                    },
                },
                {
                    title: 'Tip solicitare',
                    data: 'type_name',
                    sortable: false,
                    render: (data: any, type: any, row: any) => {
                        const imageUrl = '/assets/icons/icon-addcard.svg';
                        const imageTreeUrl = '/assets/icons/icon-tree.svg';
                        return `
                				<span><strong>${row.type_name}</strong></span><br/><br/>
                				<span style="color:#bdbdbd; font-size:12px"> Categorie / Tip</span><br/>
                				<span>
                				<img src="${imageUrl}" alt="Image" style="width: 20px; height: 20px;">
                				${row.categorie_directie} <br/>
                				<img src="${imageTreeUrl}" alt="Image" style="width: 20px; height: 20px;">
                				${row.categorie_compartiment}</span><br/><br/>
                			`;
                    },
                },
                {
                    title: 'Identificare',
                    data: 'denumire_uat',
                    sortable: false,
                    render: (data: any, type: any, row: any) => {
                        const imageUrl = '/assets/icons/companii.svg';
                        const depunereProiect = `
                            <div class="d-flex flex-column gap-2">
                                ` + (row.denumire_uat !== null ?
                                `<div class="d-flex align-items-center gap-2">
                                    <img src="${imageUrl}" alt="Image" style="width: 20px; height: 20px;">
                                    <span>${row.denumire_uat}</span><br/>
                                </div>` : '') + `
                                <div>
                                    <span style="color:#bdbdbd; font-size:12px">COD SIRUTA</span><br/>
                                    ${row.cif_uat}
                                </div>
                                <div>
                                    <span style="color:#bdbdbd; font-size:12px">Nume reprezentant</span><br/>
                                    ${row.nume_reprezentant}
                                </div>
                                <div>
                                    <span style="color:#bdbdbd; font-size:12px">Tip beneficiar</span><br/>
                                    ${row.tip_beneficiar}
                                </div>
                            </div>
                        `

                        const suportTehnic = `
                            <div class="d-flex flex-column gap-2">
                                <div>
                                    <span style="color:#bdbdbd; font-size:12px">Nume reprezentant</span><br/>
                                    ${row.nume_reprezentant}
                                </div>
                            </div>
                        `
                        if (row.type_slug === 'depunere_proiect') return depunereProiect;
                        else return suportTehnic;
                    },
                },
                {
                    title: 'Atribuire',
                    data: 'user_assigned',
                    sortable: false,
                    render: (data: any, type: any, row: any) => {
                        const imageUrl = '/assets/icons/icon-addcard.svg';
                        const imageTreeUrl = '/assets/icons/icon-tree.svg';
                        return `
                              <span style="color:#bdbdbd; font-size:12px">Locație Solicitare</span><br/>
                              <span style="color:#bdbdbd; font-size:12px">Instituție</span><br/>
                              <span style="font-weight:600 font-size:16px">${row.directie
                                ? row.directie
                                : 'n/a'
                            }</span><br/>
                              <span style="color:#bdbdbd; font-size:12px">Serviciu/Birou/Departament</span><br/>
                              <span>${row.compartiment
                                ? row.compartiment
                                : 'n/a'
                            }</span><br/>
                              <span style="color:#bdbdbd; font-size:12px">Operator Asignat</span><br/>
                              <span>${row.user_assigned}</span>
                          `;
                    },
                },
                {
                    title: 'Prioritate',
                    data: 'priority_name',
                    sortable: true,
                    render: (data: any, type: any, row: any) => {
                        return `
                            <span style="border-radius:10px; padding:4px 8px; color: ${row.priority_color}; background: ${row.priority_bg_color}">${row.priority_name}</span><br/><br/>
                            <span style="color:#bdbdbd; font-size:12px">SLA</span><br/>
                            <span>${row.sla}</span><br/>
                        `;
                    },
                },
                {
                    title: 'Status',
                    data: 'updated_at',
                    sortable: true,
                    render: (data: any, type: any, row: any) => {
                        let label = '';
                        let flagIcon =
                            '<i class="fa-solid fa-flag" style="color: white;"></i> ';
                        if (row.validated_date && !row.cancel_date) {
                            // Validat
                            label = `<span style="border-radius:10px; padding:4px 8px; color: white; background: green">${flagIcon}Eligibil</span>`;
                        } else if (row.cancel_date && row.cancel_type === 1) {
                            // Invalidat
                            label = `<span style="border-radius:10px; padding:4px 8px; color: white; background: orange">${flagIcon}NE-Eligibil</span>`;
                        } else if (row.cancel_date && row.cancel_type === 2) {
                            // Clasat
                            label = `<span style="border-radius:10px; padding:4px 8px; color: white; background: red">${flagIcon}NE-Eligibil</span>`;
                        } else {
                            // Default case
                            label = ``;
                        }
                        return `
                            <span style="border-radius:10px; padding:4px 8px; color: ${row.status_color}; background: ${row.status_bg_color}">${row.status_name}</span><br/><br/>
                            <span style="color:#bdbdbd; font-size:12px">Ultima actualizare</span><br/>
                            <span>${row.updated}</span><br/>
                            <br/>${label}
                        `;
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                $('td', row).off('click');

                // Handle click event on the CHECKBOXES
                $(row)
                    .find('.row-checkbox')
                    .off('change')
                    .on('change', function () {
                        let idCerere = Number($(this).val());

                        if ($(this).is(':checked')) {
                            self.selectedRowIds.push(idCerere);
                        } else {
                            self.selectedRowIds = self.selectedRowIds.filter(
                                (id) => id !== idCerere
                            );
                        }

                        if (self.rowCount !== self.selectedRowIds.length) {
                            self.checkHeaderCheckBox(false);
                        } else if (self.rowCount === self.selectedRowIds.length) {
                            self.checkHeaderCheckBox(true);
                        }

                        self.checkboxSelections = self.selectedRowIds.length > 0;
                    });

                $('td:not(:first-child)', row).on('click', () => {
                    this.onRowClick(data);
                });

                return row;
            },
            headerCallback: (row: Node, data: any[] | any) => {
                const headerCheckbox = $(row).find('#headerCheckbox');

                // Remove any existing event handlers to avoid duplicating them
                headerCheckbox.off('change click');

                // Handle the change event for selecting/deselecting all checkboxes
                headerCheckbox.on('change', function () {
                    if ($(this).is(':checked')) {
                        self.selectedRowIds.length = 0;
                        data.forEach((item: Cerere) => {
                            self.selectedRowIds.push(item.id);
                        });
                        self.checkMultipleCheckboxesByValue(true);
                    } else {
                        self.selectedRowIds.length = 0;
                        self.checkMultipleCheckboxesByValue(false);
                    }
                    self.checkboxSelections = self.selectedRowIds.length > 0;
                });

                // Prevent sorting when clicking the checkbox
                headerCheckbox.on('click', function (e) {
                    e.stopPropagation();
                });

                return row;
            },
        };
    }

    async getTableRowCount() {
        const table = await this.dtElement.dtInstance;
        this.rowCount = table.rows().count();
    }

    checkHeaderCheckBox(checkValue: boolean) {
        const headerCheckbox = document.getElementById(
            'headerCheckbox'
        ) as HTMLInputElement;
        headerCheckbox.checked = checkValue;
    }

    checkMultipleCheckboxesByValue(checkValue: boolean) {
        let checkboxes = document.querySelectorAll<HTMLInputElement>(
            'input[type="checkbox"]'
        );
        checkboxes.forEach((checkbox) => {
            checkbox.checked = checkValue;
        });
    }

    checkboxesReset() {
        this.selectedRowIds.length = 0;
        this.checkMultipleCheckboxesByValue(false);
        this.checkHeaderCheckBox(false);
        this.checkboxSelections = false;
    }

    onRowClick(rowData: Request) {
        this.closePopover();
        this.openInfoDialog(rowData);
    }

    rerenderDT(paginate = false): void {
        // notifications - skip rerender when dt is not loaded. Rerender is called by notificationService.currentNotification subscription.
        if (!this.dtElement || !this.dtElement.dtInstance) return;

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
    }

    /* Removed this functionality until further notice
      openAddDialog() {
        const dialogRef = this.dialog.open(AddRequestComponent, {
        ...this.dialogService.getDialogConfig(),
        });
      
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
        if (success) {
          this.rerenderDT();
        }
        });
      }
      */
    openChangeStatusComponent() {
        if (this.selectedRowIds.length > 0) {
            const dialogRef = this.dialog.open(UpdateRequestStatusComponent, {
                ...this.dialogService.getDialogConfig(),
                data: this.selectedRowIds,
            });

            dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
                if (success) {
                    Swal.fire({
                        title: helper.dialogConfig.headers.success,
                        text: 'Status actualizat cu succes.',
                        icon: 'success',
                        confirmButtonText: helper.dialogConfig.buttonLabels.close,
                        allowOutsideClick: false,
                    }).then(() => {
                        this.rerenderDT();
                    });
                }
            });
        }
    }

    openReassignComponent() {
        if (this.selectedRowIds.length > 0) {
            const dialogRef = this.dialog.open(AssignRequestComponent, {
                ...this.dialogService.getDialogConfig(),
                data: this.selectedRowIds,
            });

            dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
                if (success) {
                    Swal.fire({
                        title: helper.dialogConfig.headers.success,
                        text: 'Operator asignat cu succes.',
                        icon: 'success',
                        confirmButtonText: helper.dialogConfig.buttonLabels.close,
                        allowOutsideClick: false,
                    }).then(() => {
                        this.rerenderDT();
                    });
                }
            });
        }
    }

    openChangePrioritateComponent() {
        if (this.selectedRowIds.length > 0) {
            const dialogRef = this.dialog.open(UpdateRequestPriorityComponent, {
                ...this.dialogService.getDialogConfig(),
                data: this.selectedRowIds,
            });

            dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
                if (success) {
                    Swal.fire({
                        title: helper.dialogConfig.headers.success,
                        text: 'Prioritate actualizată cu succes.',
                        icon: 'success',
                        confirmButtonText: helper.dialogConfig.buttonLabels.close,
                        allowOutsideClick: false,
                    }).then(() => {
                        this.rerenderDT();
                    });
                }
            });
        }
    }

    openInfoDialog(data: any) {
        const dialogRef = this.dialog.open(RequestDetailsComponent, {
            minHeight: '100vh',
            maxHeight: '100vh',
            minWidth: '100vw',
            maxWidth: '100vw',
            disableClose: true,
            data: {
                cerere: data,
                directii: this.directii,
            }
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            this.rerenderDT();
        });
    }

    onStatusChange(event: MatButtonToggleChange) {
        this.isLoading = true;
        this.selectedStatusID = event.value;
        this.cerereStatusControl?.setValue(this.selectedStatusID);

        // this.statusParametersValue = event.value === 'toate' ? '-1' : '1';
        this.rerenderDT();
    }

    onCategoryChange(event: MatButtonToggleChange) {
        this.isLoading = true;
        this.selectedCategory = event.value;
        this.rerenderDT();
    }

    filterCategoryOptions() {
        const permissionMapping: { [key: string]: string } = {
            "-1": "solicitari.list-all",
            "1": "solicitari.list-my-departments",
            "2": "solicitari.list-my-requests",
            "3": "solicitari.list-intern-messages",
        };

        // Filter categoryToggleOptions based on permissions
        this.categoryToggleOptions = this.categoryToggleOptions.filter(option =>
            this.permissionsService.hasPermission(permissionMapping[option.id.toString()])
        );

        // Initialize selectedCategory after filtering
        this.selectedCategory = this.categoryToggleOptions[0]?.id;
    }

    getUserDetails() {
        const userObj = this.storageService.getObject(environment.config.userKey);
        userObj
            .then((val) => {
                this.userId = val.id_user;
                this.userDepartament = val.id_department;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderUatSearch() {
        this.uatChangesSubscription = this.searchParamsForm
            .controls['denumire_uat'].valueChanges.pipe(
                skip(1), // Ignore the first emitted value
                debounceTime(500),
                distinctUntilChanged(),
                switchMap((citizenValue) => {

                    this.isFilterLoading = true;

                    if (citizenValue.length <= 1) {
                        this.isFilterLoading = false;
                        this.searchedUats = [];
                        return of([]);
                    }

                    return this.uatService.searchUats(citizenValue);
                }),
                tap((data: Generic[]) => {
                    this.isFilterLoading = false;
                    this.searchedUats = data;
                }),
                catchError(() => of([]))
            )
            .subscribe();
    }

    onUatSelected(option: { id: number; name: string }) {
        // Unsubscribe from valueChanges to avoid an unnecessary call to the BE when setting a value
        this.uatChangesSubscription.unsubscribe();
        console.log(option, 'option');
        this.searchParamsForm.controls['uat_id'].setValue(option.id);

        // Resubscribe to valueChanges after setting the value
        this.renderUatSearch();
    }
}
