import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  errorTitle: string = helper.dialogConfig.headers.errorAtentie;
  errorIcon: string = helper.dialogConfig.icons.error;
  errorType: string = helper.dialogConfig.icons.error;

  successTitle: string = helper.dialogConfig.headers.success;
  successIcon: string = helper.dialogConfig.icons.success;
  successType: string = helper.dialogConfig.icons.success;

  form!: FormGroup;
  helper = helper;
  isLoading = false;
  hidePass1 = true;
  hidePass2 = true;
  hideCurrentPass = true;

  constructor(
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    this.form = this.fb.group(
      {
        old_password: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(50),
            Validators.pattern(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-]).{8,50}$/
            ),
          ],
        ],
        password_confirmation: ['', Validators.required],
      },
      { validators: this.passwordMatchValidator }
    );
  }

  closeDialog() {
    this.dialogRef?.close({
      success: false,
    });
  }

  passwordMatchValidator(form: FormGroup): ValidationErrors | null {
    return form.get('password')?.value ===
      form.get('password_confirmation')?.value
      ? null
      : { mismatch: true };
  }

  submit() {
    this.isLoading = true;
    const data = this.form.value;

    this.authService
      .changePassword(data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let msg;
          try {
            msg = err.error.errors.message[0];
          } catch (error) {
            msg = helper.dialogConfig.generalMessages.error;
          }
          this.notificationService.warningSwal(
            this.errorTitle,
            msg,
            this.errorIcon
          );
        },
      });
  }
}
